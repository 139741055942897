



/* font family  */



/*common css  */

*,*::before,*::after{
	padding:0;
	margin:0;
	box-sizing:border-box;
}

html{
	scroll-behavior: smooth;
}

body{
	font-size:1rem;
	font-family: 'Roboto';
}


h1{
    font-family: "Holtwood One SC", serif;
}

ul{
	list-style:none;
}

a,a:hover{
	text-decoration:none;
}

/* img{
	max-width:100%;
	width:100%;
} */

.f-18{
    font-size: 18px;
}

.f-12{
    font-size: 12px;
}

.f-26{
    font-size: 26px;
}
.f-10{
    font-size: 10px;
}

.br-15{
    border-radius: 15px;
}

.br-20{
    border-radius: 20px!important;
}

.border-light{
    border-color:rgba(224, 232, 242, 0.6)!important;
}
:root {
  --main-color: hsl(0deg 100% 17.25%);
  --purple-color: #282041;
  --btn-color:#9b3b2b;
  --bg-black:#000;
  --pane-padding: 5px 42px;
  --blue-light:#F0F9FF;
  --bg-orange:#EE5729;
  --grey:#828282

}

.text-grey{
    color: var(--grey);
}

.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}

.checkbox {
    width: 24px;
    height: 24px;
    appearance: none;
    -webkit-appearance: none;
    border: 2px solid var(--bg-orange); /* Border color */
    border-radius: 4px; /* Optional: rounded corners */
    background-color: white; /* Background color */
    cursor: pointer;
    position: relative; /* Necessary for pseudo-element positioning */
  }
  
  .checkbox:checked {
    background-color: var(--bg-orange);
  }
  
  .checkbox:checked::after {
    content: ""; /* Add the check mark */
    position: absolute;
    top: 2px; /* Adjust to center the tick */
    left: 8px; /* Adjust to center the tick */
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); /* Create the check mark shape */
  }
  
.border-card
{
    border-bottom: 1px solid #E0E0E0;
}

.text-orange{
    color:var(--bg-orange)
}



.pt-20{
	padding-top:20px;
}

.pb-20{
	padding-bottom:20px;
}
.btn-radius{
    border-radius: 8px;
}

.btn-orange{
    background-color: var(--bg-orange);
}

.btn-padding{
    padding:10px 22px;
}

.border-radius-lg{
    border-radius: 8px;
}

/* components  */

/*

1) Header 

2) Sections

3) Footer

*/

/* components  */


/* Header css  */

header{
background-color: var(--purple-color);
}

.header_image-wrapper{
    max-width: 56px;
}

/* End of Header css  */


/* payments */

.payments .bg-lightBlue{
    background-color: var(--blue-light);
}

/* payments */

/* Footer css */

/* End of Footer css */



/* media query  */

.american-football{
    top: -25px;
    left: 14px;
    width: 58px;
}

.border-card:last-child {
    border: none;
}

/* .confirmation{
    position: fixed;
    z-index: 99;
    bottom: 0px;
    background-color: #fff;
    width: 100%;
    border-radius: 20px 20px 0 0 ;
} */

.confimation_image-wrapper{
    width: 70px;
    height: 70px;
}

.wrapper{
    max-width: 500px!important;
    margin: auto;
}

.font-inter{
    font-family:'Inter';
    font-weight: 600;
}

.infoIcon {
    max-width: 15px;
    display: inline-block;
    width: 15px;
}



.confirmation {
    max-width: 500px; /* Ensures box doesn't stretch too wide */
    margin: 0 auto;   /* Centers the section on the page */
    border-radius: 10px; /* Adds rounded corners for a modern look */
    background-color: #fff; /* Optional: White background for contrast */
  }
  
  .confimation_image-wrapper img {
    max-width: 80px; /* Restricts image size for consistency */
    height: auto; /* Maintains aspect ratio */
  }
  
  .text-orange {
    color: #ff6600; /* Replace with your preferred orange shade */
  }
  
  @media (max-width: 576px) {
    .confirmation {
      padding: 1.5rem; /* Adjust padding for smaller screens */
    }
    .row > div {
      font-size: 0.9rem; /* Slightly reduce font size on small devices */
    }
  }

  .text-wrap {
    word-wrap: break-word; /* Forces wrapping at word boundaries */
    word-break: break-all; /* Forces wrapping even in long strings */
    overflow-wrap: break-word; /* Adds better wrapping for modern browsers */
  }