.payment-enroll-details-legue {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-details-view {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.create_button.btn.btn-primary.active {
    width: 100%;
    min-width: 223px;
    max-width: 225px;
    background: #27AE60 !important;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 0px;
}

.top-payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
}

.bottom-payment {
    display: flex;
    justify-content: end;
    align-items: center;
}

.payment-enroll-details {
    border: 1px solid #00000038;
    border-radius: 10px;
    padding: 12px 18px;
    margin-top: 16px;
}
.payment-method-list {
  background: #FAFAFA;
    border-radius: 10px;
    padding: 1px 19px;
}
hr {
    margin-top: 0;
    margin-bottom: 13px;
}

.orange-text p {
    margin-bottom: 0;
    color: #f05829;
    font-weight: 600;
}

.select_payment-method .form-check.form-check-inline {
    display: flex;
    align-items: center;
    margin: 9px 13px 10px 0px;
}
.select_payment-method {
    display: flex;
}
.select_payment-method label.form-check-label {
    font-weight: 400;
    color: #000;
    font-size: 16px;
}

.select_payment-method .form-check-input[type=radio] {
    width: 22px;
    height: 22px;
}

.select_payment-method .form-check-input:checked {
    background-color: #27ae60;
    border-color: #27ae60;
}

/* .payment-divison {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: calc(100vh - 123px);
} */
.payment-divison-view::-webkit-scrollbar {
    display: block;
    transition: all 0.4s ease-in-out;
}

.payment-divison-view::-webkit-scrollbar {
    display: none;
    transition: all 0.4s ease-in-out;
}

.payment-divison-view::-webkit-scrollbar-track
{
	-webkit-box-shadow: transparent;
	background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.payment-divison-view::-webkit-scrollbar
{
	width: 6px;
    height: 80px;
	background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.payment-divison-view::-webkit-scrollbar-thumb
{
	background-color: #D9D9D9;
    transition: all 0.4s ease-in-out;
}
.PaymentSideNav button {
    padding: 7px 20px 2px 23px;
}

.Sucessful {
    display: flex;
    align-items: center;
}

h3.payment-color {
    color: #27ae60;
    font-size: 33px;
    margin-bottom: 0;
    margin-left: 1rem;
}

h3.total-amount {
    /* text-align: center; */
    font-size: 61px;
}
.payment-divison-successful h5 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 2rem;
}
.payment-divison-successful h6 {
    font-size: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 700;
}

.payment-btns .btn-primary-download-btn {
    background: #4285F4;
    color: #fff;
}

button.btn.btn-primary-download-btn:active {
    background-color: #4285f4;
    border-color: #4285f4;
    color: #fff;
}


.transaction span {
    color: #000;
    font-weight: 500;
}
p.receipt-details {
    font-size: 15px;
}

button.pay_button.btn.btn-primary.btn.btn-primary:active {
    background-color: #27ae60 !important;
    border-color: #27ae60 !important;
}

/* .season-popup .btn.btn-primary {
    background: #27AE60;
    border-color: #27AE60;
    border: 0;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 5px 0px 7px !important;
} */

a.secondary-download-btn.ms-2.active {
    color: #fff;
    width: 100%;
    min-width: 107px;
    max-width: 107px;
    background:  #484848 !important;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

span.taem-btn {
    color: #000;
}

.PaymentSideNav-offcanvas button.pay_button.btn.btn.btn-primary {
    background: #27AE60;
    border-color: #27AE60;
    padding: 9px 20px 0px 20px;
}

.PaymentSideNav-offcanvas button.btn-close {
    font-size: 25px;
    margin-top: 5px;
}

.payment-divison-view {
    overflow-y: auto;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
    /* padding-top: 1rem; */
    max-height: 756px;
}


.sidenav_offcanvas.PaymentSideNav-offcanvas.offcanvas.offcanvas-end.show {
    margin-top: 5rem;
    overflow: auto;
}

.PaymentSideNav button.btn.btn-primary.paynowbtn {
    font-size: 15px !important;
    width: 100%;
    background: #F44336 !important;
    border-radius: 6px;
    padding: 2px 16px;
}

.payment-deals p {
    font-size: 13px;
    margin-bottom: 0;
}

.payment-deals {
    display: flex;
    align-items: center;
}

.payment-divison-successful {
    padding: 41px 15px 2px 15px;
}

a.btn.btn-secondary {
    background: #484848;
    border-color: #484848;
}

/* .create-match-wrap.season-popup {
    padding: 4rem 0rem;
} */
.create-match-wrap.season-popup .payement {
    margin-top: 2rem;
}

.sidenav_offcanvas.craete-games-offcanvas.offcanvas.offcanvas-end.show {
    margin-top: 6rem;
    padding: 0px;
}






@media  (max-width: 1400px) { 
    .group-dashboard.nav.nav-pills .no-games .empty-state__message {
        font-size: 1rem;
    }
    
    .group-dashboard.nav.nav-pills .no-games .empty-state__content {
        padding: 0px;
    }


    .sidenav_offcanvas.craete-games-offcanvas .offcanvas-header {
        padding: 3px 0 0;
    }

    /* .sidenav_offcanvas.craete-games-offcanvas  .create-match {
        padding: 4px 0;
        height: calc(100vh - 49px);
    } */

    .help_popup .modal_footer .heading h4 {
        background: #000;
        color: #fff;
        max-width: 275px;
        font-size: 12px;
        padding: 6px;
        border-radius: 6px;
    }

    .help_popup .video_content {
        height: calc(100vh - 506px);
    }


    .video_content .accordion-header button.accordion-button {
        padding: 9px 0px;
    }



}


@media  (min-width:1100px) and (max-width: 1400px) { 
    .footbal-icon {
        max-width: 108px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
        font-family: 'Roboto';
        color: #000;
        min-width: 139px;

    }
    /* input[type=date]::before {
        width: 9px !important;
        height: 1px;
    } */

    .match-outer input[type=date]::after{
        display: none;
    }

    .top_buttons_dropdown {
        gap: 0px !important;
    }

    .top-backgrounds-bnanner{
        padding: 17px 12px !important;

    }
    .top_buttons_dropdown .time_schedule-1 {
        width: 18%;
    }
    .nav.nav-pills {
        justify-content: center;
    }
    .nav-link-2.nav-item a.nav-link {
       
        border-left: 1px solid !important;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-top: 1px solid;
        border-radius: 10px 10px 11px 10px !important;
    }

    .teams_nav_buttons_Active button {
      
        min-width: 70px !important;
    }

    .group-dashboard.nav.nav-pills .no-games .empty-state__message {
        font-size: 1rem;
    }
    
    .group-dashboard.nav.nav-pills .no-games .empty-state__content {
        padding: 0px;
    }

    .payment-divison-view {
        max-height: 492px;
    }
    li.border.text-center p {
        font-size: 12px;
        white-space: nowrap;
    }


 }



 @media  (min-width:1400px) and (max-width: 1920px) { 
    .footbal-icon {
        max-width: 281px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
    }
 }



 
 @media  (min-width:1400px) and (max-width: 1520px) { 
    .top_buttons_dropdown {
        gap: 0px !important;
    }

    .top-backgrounds-bnanner{
        padding: 17px 12px !important;

    }
    .top_buttons_dropdown .time_schedule-1 {
        width: 18%;
    }

    

 }



 @media  (max-width: 1100px) { 
    .footbal-icon {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 108px;
        max-width: 190px;
    }

 }


