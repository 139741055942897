/* Media query for screens smaller than 768px (adjust as needed) */
@media (max-width: 768px) {
    /* Adjust the layout for smaller screens */
    h1 {
      font-size: 24px;
    }
  
    /* Center the form fields vertically on smaller screens */
    Form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    /* Style form fields to fit on smaller screens */
    Form div {
      width: 100%;
      margin-bottom: 10px;
    }
  
    /* Adjust table styles for smaller screens */
    table {
      width: 100%;
      border-collapse: collapse;
    }
  
    th, td {
      padding: 10px;
      text-align: center;
    }
  
    th {
      background-color: #f2f2f2;
    }
  
    /* Center the "Edit" and "Delete" buttons on smaller screens */
    td:last-child {
      text-align: center;
    }
  }
  

  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: white;
  }