.season_dashboard_dropdown_search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.season_dashboard_dropdown_search .time_schedule select {
    min-width: 232px;
    min-height: 32px;

}
.card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
    font-size: 14px;
}
.card-header:first-child span {
    font-size: 14px;
}


/* .seasonDashboardElements {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    -webkit-gap: 20px;
    -moz-gap: 20px;
    -o-gap: 20px;
} */

.time_schedule h5 {
    margin-bottom: 0;
    text-align: center;
}

/* .time_schedule {
    margin-top: -18px;
    margin-right: 14px;
} */

label.season-select {
    font-weight: 500;
    color: #F05829;
    font-size: 15px;
    margin-right: 13px;
    margin-top: 5px;
}

.season-trophy-box {
    background: #ffff;
    border-radius: 10px;
    padding: 14px 25px;
    margin-bottom: 33px;
}



.time_schedule h5 {
    margin-bottom: 0;
    /* margin-right: 8px;
    margin-top: 4px; */
}

.seasonDashboardElements .item {
    background: #fff;
    width: 100%;
    min-width: 100%;
    min-height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 10px 0px 0px;
    border-radius: 10px;
    /* flex-direction: column; */
    align-items: center;
}

.tolal-details {
    background: #298CEA;
    padding: 20px 19px 15px 14px;
    border-radius: 10px 1px 0px 10px;
}


.seasonDashboardElements .item.due_payment button {
    background: #F1372B;
    border-radius: 6px;
    padding: 4px 20px;
}

.seasonDashboardElements .item.due_payment h4 {
    color: #484848;
}

/* .seasonDashboardElements .item .heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: end;
} */

.item-content {
    width: 100%;
}

.seasonDashboardElements .item h6 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 500;
}

.seasonDashboardElements .item h4 {
    font-size: 40px;
    color: #484848;
    margin-bottom: 0;
}

.seasonDashboard_divison_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    -webkit-gap: 20px;
    -moz-gap: 20px;
    -o-gap: 20px;
}

.seasonDashboard_divison_group .divison_group {
    width: 100%;
    max-width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0 10px;
    min-height: 446px;
    max-height: 280px;
}

.seasonDashboard_divison_group .heading h4 {
    color: #F05829;
    font-size: 16px;
    font-weight: 600;
    margin-top: -7px;
}

.seasonDashboard_divison_group .divison_group .table_list table {
    border: none;
    border-collapse: collapse;
    table-layout: fixed;
}

.seasonDashboard_divison_group .divison_group .table_list table thead {
    background: #fff;
}

.seasonDashboard_divison_group .divison_group .table_list table thead tr {
    border-top: none;
    border-bottom: 1px solid #E9E9E9;
}

.seasonDashboard_divison_group .divison_group .table_list table thead tr th {
    color: #484848;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    position: sticky;
    top: 0;
    width: 20%;
    display: inline-block;
    z-index: 1;
    padding: 3px 9px 5px 0px !important;
}

.seasonDashboard_divison_group .divison_group .table_list table tbody tr {
    border-bottom: 1px solid #E9E9E9;
    width: 100%;
    display: inline-block;
    position: relative;
}

.seasonDashboard_divison_group .divison_group .table_list table tbody {
    overflow-y: auto;
    height: 110px;
    display: block;
}

.seasonDashboard_divison_group .divison_group .table_list table tbody:hover::-webkit-scrollbar {
    display: block;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_divison_group .divison_group .table_list table tbody::-webkit-scrollbar {
    /* display: block; */
    transition: all 0.4s ease-in-out;
    opacity: 0;
}

.seasonDashboard_divison_group .divison_group .table_list table tbody::-webkit-scrollbar-track {
    -webkit-box-shadow: transparent;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_divison_group .divison_group .table_list table tbody::-webkit-scrollbar {
    width: 6px;
    height: 80px;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_divison_group .divison_group .table_list table tbody::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_divison_group .divison_group .table_list table tbody tr td {
    color: #484848;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    width: 33.3%;
    margin-bottom: 0;
}

.divison_group .table_list .team_list img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
}

.divison_group .team_list {
    display: flex;
    gap: 10px;
    align-items: center;
}

.divison_group .team_list .team_detail h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}

.divison_group .team_list .team_detail p {
    color: #298CEA;
    font-size: 12px;
    font-weight: 400;
}

.divison_group .team_list .team_detail p span {
    color: #000;
}

.actions_icons button {
    border: none;
    background: transparent;
}

.seasonDashboardCreateGames .gameButton_selectTime .input-right-icon {
    position: relative;
    display: flex;
    gap: 15px;
    -webkit-gap: 15px;
    -moz-gap: 15px;
    -o-gap: 15px;
}

.seasonDashboardCreateGames .gameButton_selectTime {
    display: flex;
    justify-content: space-between;
}

.seasonDashboardCreateGames .gameButton_selectTime button {
    background: #27AE60;
    padding: 8px 24px;
    max-height: 39px;
}

.seasonDashboardCreateGames {
    width: 100%;
    margin: 20px 0 0;
}

.seasonDashboardTab {
    margin-top: 24px;
}

.seasonDashboardTab .tabbing_ui_fixing {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
}

.seasonDashboardCreateGamesListings .vs_text {
    align-items: center;
}

.seasonDashboardCreateGamesListings table tbody tr td {
    vertical-align: middle !important;
}

.seasonDashboardCreateGamesListings table tbody tr td .team_vs_team h6 {
    margin-bottom: 0;
}

.seasonDashboardTab {
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 0 20px;
    min-height: 444px;
}

.seasonDashboardTab.active-seasonDashboar-tab .tab-content {
    padding: 0px 20px;
}



.seasonDashboardTab.active-seasonDashboar-tab {
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 0 0px;
    min-height: 490px;
}

.seasonDashboardTab .tabbing_ui_fixing .nav-item {
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    /* border-right: 1px solid #E6E6E6; */
    width: 33.33%;
    background-color: #f9f9f9;
}

.seasonDashboardCreateGames .tabbing_ui_fixing .nav-link.active {
    background: transparent;
    font-weight: 500;
    min-height: 46px;
    color: #484848;
    font-size: 18px;
    text-align: center;
    border: none;
}

.seasonDashboardCreateGames .tabbing_ui_fixing .nav-link.active:hover {
    color: #484848;
}

.seasonDashboardCreateGames .tabbing_ui_fixing .nav-link {
    background: transparent;
    font-weight: 500;
    min-height: 46px;
    color: #CCC;
    font-size: 18px;
    text-align: center;
    margin: 0 auto;
    border: none;
    background: #f9f9f9;
}

/* .seasonDashboard_divison_group .enrollgroup .table_list table tbody{
    overflow-y: auto;
    height: 336px;
    display: block; 
} */

.seasonDashboard_divison_group .divison_group .table_list table tbody {
    overflow-y: auto;
    height: 330px;
    display: block;
}

.seasonDashboard_card .seasonDashboard_Season_heading .wrap .l-title {
    width: 100%;
}

.seasonDashboard_card .seasonDashboard_Season_heading .wrap .l-title a .card-content {
    display: flex;
}

.seasonDashboard_card .seasonDashboard_Season_heading .wrap .l-title .tournament_card_redesign .card-content h6 {
    width: 192px;
    overflow: hidden;
    text-overflow: ellipsis;

}

/* .seasonDashboard_svg_card_content {
    display: flex;
}

.seasonDashboard_svg_card_content {
    display: flex;
    align-items: center;
    gap: 10px;
    -webkit-gap: 10px;
    -moz-gap: 10px;
    -o-gap: 10px;
} */

.seasonDashboard_date p {
    margin-top: 10px;
    color:#F1372B;
    font-weight: 600;
    margin-bottom: 0;
    text-align: start;
}

.seasonDashboard_date p span {
    margin-top: 10px;
    color: #484848;
    font-weight: 400;
    font-size: 15px;
}

.seasonDashboard_groups_teams_player {
    display: flex;
    width:42%;
    justify-content: space-around;
    align-items: center;
}

.seasonDashboard_date {
    margin-left: 29px;
}

.no-data.no-divicsion-created.d-flex.justify-content-center {
    position: absolute;
    top: 50%;
}


/* .seasonDashboard_date {
    width: 38%;
   text-align: start; 
} */
.seasonDashboard_groups_teams_player h4 {
    font-size: 15px;
    color: #484848;
}

.seasonDashboard_groups_teams_player h4 span {
    color: #298CEA;
    font-weight: 600;
}

.seasonDashboard_Season_heading .common-button {
    width: 100%;
    margin: 63px 0 10px;
    min-height: 44px;
    background-color: #27AE60;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.seasonDashboard_title p {
    color: #484848;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.seasonDashboard_navLink a {
    width: 100%;
    text-align: center;
    display: block;
    color: #298CEA;
    /* text-decoration: underline !important; */
    /* padding-top: 1rem; */
    white-space: nowrap;
    border: 1px solid #298CEA;
    border-radius: 7px;
    padding: 4px 10px;
    margin: 7px 6px;
    font-size: 14px;
}

.seasonDashboard_Season_heading .tournament_card_redesign .card-content {
    margin-left: 0;
}

.seasonDashboard_Season_heading a.tournament_card_redesign.active {
    display: flex;
}

.seasonDashboard_updateScore .live_game {
    border: none;
    margin-top: 24px;
    min-height: 435px;
}

/* 
.seasonDashboard_updateScore .live_game .heading_live_game .form_input_radio h4{
   font-size: 20px;
   font-weight: 600;
   color: #484848;
}

.seasonDashboard_updateScore .live_game .slick-prev {
    top: -40px;
    left: 209px;
}

.seasonDashboard_updateScore .live_game .slick-next {
    top: -40px;
    left: 260px;
}

.seasonDashboard_updateScore .live_game .team_1_see_detail {
    background: #27AE60;
    border: 1px solid #27AE60;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
} */

.seasonDashboardCreateGamesListings.table_create_list .heading h4 {
    font-size: 20px;
    font-weight: 600;
}

.seasonDashboard_games h4 {
    font-size: 14px;
    font-weight: 400;
    color: #484848;
    margin-bottom: 0;
}

.seasonDashboard_games {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #EEF0EF;
    padding: 15px 25px 16px 1px;
    justify-content: space-between;
}

.seasonDashboard_notification_heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 0;
    margin-bottom: 20px;
}

.seasonDashboard_notification_heading h4 {
    font-size: 18px;
    font-weight: 600;
    color: #F05829;
}

.seasonDashboard_notification_heading .dropdown.acts .dropdown-toggle img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.seasonDashboard_notification .seasonDashboardTab ul .nav-link {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    box-shadow: none;
    border: none;
    font-size: 18px;
    color: #CCC;
}

.seasonDashboard_notification .seasonDashboardTab ul .nav-link.active {
    width: 100%;
    max-width: 100%;
    border-radius: 10px 10px 0px 0px;
    background: #484848;
    font-weight: 500;
    min-height: 46px;
    color: #fff;
}

.seasonDashboard_notification .acts ul.dropdown-menu li {
    margin: 0;
    padding: 5px 0;
}

.seasonDashboard_Season_heading .acts ul.dropdown-menu li {
    margin: 0;
    padding: 5px 0;
}

.seasonDashboard_divison_group .acts ul.dropdown-menu li {
    margin: 0;
    padding: 5px 0;
}

.seasonDashboard_notification .acts:hover>.dropdown-menu {
    display: block;
    top: 31px;
    left: -139px;
}

.seasonDashboard_Season_heading .acts:hover>.dropdown-menu {
    top: 30px;
    left: -144px;
}

.seasonDashboard_notification a.dropdown-item.active {
    background: transparent;
    color: #484848;
}

.seasonDashboard_Season_heading a.dropdown-item.active {
    background: transparent;
    color: #484848;
}

.seasonDashboard_divison_group a.dropdown-item.active {
    background: transparent;
    color: #484848;
}

.seasonDashboard_notification a.dropdown-item.active:hover {
    background: #9e9e9e14;

}

.seasonDashboard_Season_heading a.dropdown-item.active:hover {
    background: #9e9e9e14;
    /* color: #fff; */
}

.seasonDashboard_divison_group a.dropdown-item.active:hover {
    background: #9e9e9e14;
    /* color: #fff; */
}


.dropdown.acts .dropdown-item:active {
    background-color: #00000005;
    color: #323131;
}

.gameButton_selectTime_Wrap .seasonDashboardTab .tab-content {
    width: 100%;
    height: 311px;
    overflow-y: auto;
}

.gameButton_selectTime_Wrap .seasonDashboardTab .tab-content:hover::-webkit-scrollbar {
    display: block;
    transition: all 0.4s ease-in-out;
}

.gameButton_selectTime_Wrap .seasonDashboardTab .tab-content::-webkit-scrollbar {
    display: none;
    transition: all 0.4s ease-in-out;
}

.gameButton_selectTime_Wrap .seasonDashboardTab .tab-content::-webkit-scrollbar-track {
    -webkit-box-shadow: transparent;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.gameButton_selectTime_Wrap .seasonDashboardTab .tab-content::-webkit-scrollbar {
    width: 6px;
    height: 80px;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.gameButton_selectTime_Wrap .seasonDashboardTab .tab-content::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_notification .tab-content {
    width: 100%;
    height: 300px;
    overflow-y: auto;
}

.seasonDashboard_notification .tab-content:hover::-webkit-scrollbar {
    display: block;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_notification .tab-content::-webkit-scrollbar {
    display: none;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_notification .tab-content::-webkit-scrollbar-track {
    -webkit-box-shadow: transparent;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_notification .tab-content::-webkit-scrollbar {
    width: 6px;
    height: 80px;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_notification .tab-content::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    transition: all 0.4s ease-in-out;
}

.seasonDashboard_divison_group .actions_icons {
    width: 100%;
    /* text-align: end; */
}

.no-data {
    width: 100%;
    text-align: center;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}



.table_match_date_detail .no-data {
    position: absolute;
    top: 41%;
}


.seasonDashboard_updateScore .heading_live_game {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 655px);
}

/* .seasonDashboard_Season_heading img.img-fluid {
    width: 100%;
    max-width: 48%;
    min-width: 5px;
} */

.season-trophy-box img.img-fluid {
    max-width: 27px;
    height: 23px;
}

/* .seasonDashboardCreateGamesListings.table_create_list .no-data {
    position: absolute;
    top: 50%;
} */

a.creategames-link {
    color: #298cea;
}

.seasonDashboardTab {
    position: relative;
}

.divison_group a.creategames-link {
    font-size: 15px;
}

.seasonDashboard_divison_group .no-data p {
    padding-bottom: 0;
    font-size: 16px;
}

span.diffrence {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.seasonDashboard_divison_group .table_list.enroll-team-table table.table.table-bordered tr td {
    margin-bottom: -10px;
    padding: 6px;
}

td.padding-remove {
    padding: 12px 12px 11px 0px !important;
}

td.padding-remove-1 {
    padding: 5px 3px !important;
}

.no-data {
    font-family: 'Roboto';
}

.seasonDashboard_title P {

    font-size: 14px;
}

.seasonDashboard_Season_heading {
    position: relative;
}

.seasonDashboard_Season_heading .no-data {
    position: absolute;
    top: 20%;
}

/* button.btn.btn-secondary.btn.btn-primary {
    width: 100%;
    margin: 63px 0 10px;
    min-height: 44px;
    background-color: #0000005c;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
}
button.btn.btn-secondary.btn.btn-primary a {
    color: #fff;
} */
.seasonDashboard_Season_heading .common-button a {
    color: #fff;
}

button.btn.btn-primary.common-button.btn.btn-primary:hover {
    background-color: #27ae60;
}


.notify-time {
    display: flex;
    align-items: center;
    gap: 20px;
    /* padding: 5px 0; */
    /* border-bottom: 1px solid #EEF0EF; */
}

.timing-notify span {
    color: #9A9A9A;
}

/* .seasonDashboard_updateScore .slick-slider.slick-initialized .live_game_team1 {
    margin-top: 3px;
}

.seasonDashboard_Season_heading .card-border {
    margin-left:-6px;
}

span.view-btn {
    color: #f44336;
}

span.text-light {
    font-family: 'Roboto';
} */

a.btn.btn-secondary.nav-link.disabled {
    width: 100%;
    margin: 0px 0 10px;
    min-height: 44px;
    background-color: #D3D3D3;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;

}

.seasonDashboard_Season_heading a.btn.btn-primary.common-button.nav-link:hover {
    background: #27ae60;
}

.upadte-score-none {
    margin-top: 4rem;
}

.create-from-btn.submission.ps-3 button.btn.btn-dark.savesubmit.next-orange-btn {
    min-width: 132px;
    max-width: 145px;
}

.cfirst ul.dropdown-menu span img {
    margin-top: 0px;
}

.seasonDashboardCreateGames .tabbing_ui_fixing .nav-link {
    width: 100%;
    max-width: 362px;
    min-width: 217px;
    min-height: 40px;
}

.team_list_navs .team-search {
    margin-bottom: 0px;
}

.team_list_navs .back-btn-dashboard {
    margin-bottom: 0px;
    margin-left: 14px;
}

.season-date {
    margin-left: -1rem;
}

/* .enrollgroup {
    width: 100%;
    max-width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0 10px;
    min-height: 430px;
    max-height: 280px;
} */

.table_list.enroll-team-table table.table.table-bordered {
    border: 0;
}

.table_list.enroll-team-table table.table.table-bordered th {
    color: #484848;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    position: sticky;
    top: 0;
    width: 33%;
    z-index: 1;
    padding: 3px 9px 5px 0px !important;
}

p.mail.blue_text span.text-left {
    color: #484848;
}

.blue_text {
    color: #298CEA;
}

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

a.heading-flex {
    color: #c1c1c1;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    margin-top: -16px;
}


.divison_group .heading h4 {
    color: #F05829;
    font-size: 16px;
    font-weight: 600;
    margin-top: -7px;
}

.live_game th {
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
}

a.heading-flex:hover {
    color: #f1372b;
    font-size: 15px;
}

.seasonDashboard_updateScore .live_game {
    width: 100%;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    margin: 20px 0 0;
    padding: 20px 20px 20px 6px;
    min-height: 557px;
    background: #fff;
    max-height: 439px;
    min-width: 289px;
}



  .seasonDashboard_card {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    min-height: 408px;
    max-height: 417px;
}


.live_game td {
    white-space: nowrap;
}


td.coach-mails.padding-remove-1 p {
    margin-bottom: 0px;
    white-space: nowrap;
    width: 153px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.SeasonDashboard_box-detailS {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #F4F4F4;
    padding: 16px 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 55%;
}

.seasonlogin-btn {
    border: 1px solid #f4f4f4;
    padding: 10px 44px;
    border-radius: 10px;
}

.time_schedule {
    border: 1px solid #f4f4f4;
    padding: 10px 14px 30px 7px;
    border-radius: 10px;
}

.season-items_board {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.seasonDashboard_svg_card_content h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    min-width: 145px;
    max-width: 288px;
    white-space: nowrap;
}

.headlines {
    margin-right: 20px;
}
.table_list.Enquiry-team-table td {
    font-size: 16px;
    color: #484848;
}

.season-tornaments-logs {
    display: flex;
    align-items: center;
}

.season-tornaments-logs .card-content {
    margin-left: 7px;
}

.SeasonDashboard_box-detailS-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #F4F4F4;
    padding: 45px 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 55%;
}

.No-season {
    align-items: center;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 19px 20px;
    width: 100%;
    flex-direction: column;
}

.create-season-btn-colum a.active {
    width: 100%;
    min-width: 223px;
    max-width: 225px;
    background: #27AE60 !important;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 7px 0px;
    margin-top: 1rem;
}
.No-season span{
    color: #00000052;
}


