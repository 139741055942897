/* DeletePopup.css */
.delete-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .delete-popup.open {
    display: flex;
  }
  
  .delete-popup-content {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    padding: 3.5rem 0;
    text-align: center;
    max-width: 530px;
    width: 100%;
  }
  
  .delete-popup-content p {
    margin: 0 0 20px;
    font-size: 16px;
  }
  
  .delete-popup-btn {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
 
  