/* CopyToClipboard.css */

.copy-to-clipboard {
    position: relative;
    display: inline-block; /* Ensures it doesn't take full width */
  }
  
  .copy-to-clipboard  .alert {
    position: absolute;
    top: -50px; /* Adjust as needed */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    z-index: 1000; /* Ensure it's above other content */
    width: max-content;
    padding: 10px 15px;
    background-color: #4caf50; /* Success background color */
    color: #fff;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .alert.show {
    opacity: 1;
    top: -40px; /* Adjust position to give space for the button */
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  .copy-text:hover {
    color: #f1582a;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}