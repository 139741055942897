.file-upload-container {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    background-color: #fff;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .file-upload-container.drag-active {
    background-color: #e0f7fa;
  }
  
  .file-input {
    display: none;
  }
  
  .file-input-label {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
  }
.Uplaoodecsv-file svg {
    width: 45px;
    height: 26px;
   
}