.container-fluid{
    width: 100%;
    margin: 0;
    padding: 0;
}

.dahboard_inner_white_background {
    width: 100%;
    background: #EEEEEE;
    padding: 28px 28px 28px 28px;
}

section.dashboard_organizer {
    width: 100%;
}



.outerDashboard {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    height: 100vh;
}

.sidebar {
    /* max-width: 286px; */
    background: #FBFBFB;
    /* display: flex;
    justify-content: space-between;
    flex-direction: column; */
    /* min-height: 100vh; */
    z-index: 999999;
    transition: all 0.4s ease-in-out;
    /* width: 100%; */
}

.notification-icon label {
    min-width: 20px;
    min-height: 20px;
    background: red;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
}

.sidebar.active {
    width: 50px;
    transition: all 0.4s ease-in-out;
}

.sidebar .logout a{
    display: flex;
    gap: 15px;
    -webkit-gap: 15px;
    -moz-gap: 15px;
    -o-gap: 15px;
}

.sidebar.active .side-nav .dashboard-logo a img {
    max-width: 50px;
    height: 47px;
    object-fit: contain;
    transition: all 0.4s ease-in-out;
}

.sidebar .logout a span:nth-child(2){
    display: inline-block;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #484848;
}

.innerDashboard {
    width: 100%;
    position: relative;
    overflow-y: auto;
}

.sidebar .side-nav {
    display: initial;
    width: auto;
    padding-left: 0;
    padding-top: 0 !important;
    position: unset;
    left: 0 !important;
    top: 80px !important;
    height: calc(100% - 83px);
    background: transparent;
    box-shadow: initial;
    padding-top: 20px;
    z-index: 9999;
    overflow-y: auto;   
}

.sidebar .side-nav:hover::-webkit-scrollbar {
    display: block;
    transition: all 0.4s ease-in-out;
}

.sidebar .side-nav::-webkit-scrollbar {
    display: none;
    transition: all 0.4s ease-in-out;
}

.sidebar .side-nav::-webkit-scrollbar-track
{
	-webkit-box-shadow: transparent;
	background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.sidebar .side-nav::-webkit-scrollbar
{
	width: 6px;
    height: 80px;
	background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.sidebar .side-nav::-webkit-scrollbar-thumb
{
	background-color: #D9D9D9;
    transition: all 0.4s ease-in-out;
}

.dashboard_organizer .accordion-item {
    padding: 15px;
    border: none;
}

.dashboard_organizer .accordion_first_games .accordion .accordion-item {
    padding: 0;
    margin-top: 20px;
    padding-bottom: 0;
}

.innerDashboard .top-nav {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 0px 30px 0px;
    background: #f9f9f9;
    z-index: 99999;
    left: 0;
    padding: 5px;
}



.dashboard_organizer .dashboard-logo {
    width: 100%;
    margin: 0 0 10px;
    display: block;
    float: none;
}

.dashboard_organizer .accordion_second_games ul li a.active {
    padding: 0;
}

.dashboard_organizer .navigation_start_body_ul .navigation_start_body_li {
    border-bottom: 1px dashed #E6E6E6;
}

.dashboard_organizer .dashboard-logo a {
    width: 100%;
    display: block;
}

.dashboard_organizer .navigation-bar ul li a {
    display: flex;
    align-items: flex-start;
}

.dashboard_organizer .navigation-bar ul li a span:nth-child(2) {
    color: #484848;
    font-size: 18px;
    transition: all 0.4s ease-in-out;
    width: 100%;
    text-align: left;
}

.accordion_first_games .accordion_inner_basketball .accordion .accordion-item {
    padding-bottom: 0;
}

.dashboard_organizer .navigation-bar .accordion_second_games ul li a {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
}

.dashboard_organizer .navigation-bar button.accordion-button {
    background: transparent !important;
    box-shadow: none;
    color: #000;
    padding: 0;
}

.dashboard_organizer .navigation-bar .accordion_second_games ul {
    margin: 0;
    padding: 15px;
    padding-bottom: 0;
    position: relative;
    top: 9px;
}

/* .dashboard_organizer .dashboard-inner {
    margin: 0;
} */

.dashboard_organizer .breadcrumb-item a {
    color: #000;
    font-size: 15px;
    font-weight: 400;
}

.dashboard_organizer .navigation-bar .accordion-item .accordion-button h4 {
    display: flex;
    gap: 10px;
    padding-left: 0;
    margin: 0;
}

.dashboard_organizer .navigation-bar .accordion_first_games.accordion-body {
    padding: 0;
}

.dashboard_organizer .navigation-bar .accordion_second_games.accordion-body {
    padding: 0;
}

.navigation-bar .accordion-item .accordion-button h4 {
    display: flex;
    gap: 10px;
}
 
.dropdown_coach .accordion .accordion-item .accordion-collapse .accordion_first_body {
    padding: 0;
}

.dropdown_coach .accordion .accordion-item .accordion-collapse .accordion-button h4 span svg {
    max-width: 24px;
    height: 24px;
    margin-right: 10px;
}

.dropdown_coach .accordion .accordion-item .accordion-collapse .accordion-button h4 {
    display: flex;
    align-items: center;
    padding-left: 4px;
}

.dropdown_coach .accordion .accordion-item .accordion_second_games.accordion-body {
    margin-top: 0;
    padding-top: 0;
}

.dropdown_coach .accordion .accordion-item .accordion_second_games.accordion-body ul{
    margin-top: 6px;
}

.dropdown_coach .accordion .accordion-collapse .accordion-body:first-child {
    padding-left: 3px;
}

.dashboard_organizer .navigation-bar ul li a span:hover {
    color: #F05829;
    transition: all 0.4s ease-in-out;
}

.dashboard_organizer .dropdown_coach .nav-item{
    padding: 0;
    margin: 0;
}

.dashboard_organizer .dropdown_coach .nav-item .nav-link{
    color: #000;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    padding: 0;
}

.dashboard_organizer .dropdown_coach .nav-item .nav-link:hover{
    color: #F05829;
}

.dashboard_organizer .side-nav .navigation-bar li:has(a.active) > a {
    background: transparent !important;
    border-right: none !important;
}

.dropdown_coach .accordion .accordion-item .accordion-header button.accordion-button.collapsed {
    padding: 0;
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(0deg) !important;
}

.accordion-button::after {
    transform: rotate(-90deg) !important;
    position: relative;
    top: -4px;
}

/* Dashboard */
.dashboard_organizer .dashboard-inner {
    margin-top: 0;
    padding: 0;
    overflow-y: auto;
    height: calc(100vh - 56px);
}

.dashboard_organizer .dashboard-inner::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.dashboard_organizer .dashboard-inner {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .breadcrumb_dashboard {
    width: 100%;
    background: #fff;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 11%);
    z-index: 99999;
}

.breadcrumb_dashboard ol.breadcrumb {
    margin-bottom: 0;
}

.total_revenue_line_graph {
    width: 100%;
    /* padding: 10px; */
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
    max-height: 555px;
    min-height: 536px;
}



.dashboard-padding {
    padding: 17px 33px 5px;
}
a.create_button.btn.btn-primary.paynow-due.btn.btn-primary {
    background: #F1372B;
    max-width: 237px;
    width: 100%;
    min-width: 237px;
    margin-top: 17px;
}

.dashboard-total-event-table td {
    padding: 12px !important;
}
.total_revenue_component{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.total_revenue_component .revenue_total_amount h4 {
    display: flex;
    gap: 20px;
    -moz-gap: 20px;
    -o-gap: 20px;
    -webkit-gap: 20px;
}

.total_revenue_component .revenue_total_amount .amount{
    font-size: 28px;
    color: #000;
    font-weight: 700;
}

.total_revenue_component .revenue_total_amount .tagline{
    display: block;
    font-size: 18px;
    color: #484848;
    font-weight: 600;
}

.last_week .games_dropdown_date_range {
    display: flex;
    justify-content: space-between;
}

.total_revenue_component .revenue_total_amount .last_week {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

.total_revenue_component .last_week h4{
    color: #27AE60;
    font-weight: 700;
    font-size: 18px;
}

.total_revenue_component .last_week p{
    color: #484848;
    font-weight: 400;
    font-size: 18px;
}

.wthdrawn_component_barchart{
    width: 100%;
    min-height: 555px;
    max-height: 589px;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
    /* max-width: 438px;
    min-width: 439px; */
}

.widthdrawn_component {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
}

.widthdrawn_component .widthdrawn_component_inner h4{
    color: #F05829;
    font-weight: 700;
}

.widthdrawn_component .widthdrawn_component_inner h6{
    color: #484848;
    font-size: 16px;
    font-weight: 700;
}

.widthdrawn_component .widthdrawn_component_inner p{
    color: #484848;
    font-family: 14px;
    font-weight: 400;
}

.widthdrawn_component .widthdrawn_component_inner p span{
    color: #F05829;
    font-weight: 700;
    margin-right: 7px;
}


.wthdrawn_component_barchart.blue .widthdrawn_component .widthdrawn_component_inner h4{
    color: #4285F4;
    font-weight: 700;
}

.wthdrawn_component_barchart.blue .widthdrawn_component .widthdrawn_component_inner h6{
    color: #484848;
    font-size: 16px;
    font-weight: 700;
}

.wthdrawn_component_barchart.blue .widthdrawn_component .widthdrawn_component_inner p{
    color: #484848;
    font-family: 14px;
    font-weight: 400;
}

.wthdrawn_component_barchart.blue .widthdrawn_component .widthdrawn_component_inner p span{
    color: #4285F4;
    font-weight: 700;
    margin-right: 7px;
}

.graph_select_box {
    width: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    max-width: 365px;
    min-width: 365px;
    min-height: 40px;
    float: right;
    margin: 0 0 15px 0;
    padding: 0 10px;
    background: #fff;
}

.graph_select_box .form_select select {
    box-shadow: none;
    background-color: transparent;
    border: none !important;
    font-size: 18px;
    font-weight: 600;
}

.select_box .form-check-inline .form-check-label {
    color: #000;
    font-weight: 600;
    margin-left: 5px;
    font-size: 18px;
}

.form_select select:focus{
    box-shadow: none;
}

.graph_select_box .form-check-input[type=radio] {
    width: 24px;
    height: 24px;
    border: 1px solid #000;
}

.graph_select_box .form-check-input:checked[type=radio] {
    background-color: #F05829;
}

.select_box {
    display: flex;
}

.select_box .form-check {
    display: flex;
    padding: 4px 0;
}

.main-menu > ul li a {
    color: #000;
    font-size: 15px;
    width: 100%;
    text-align: left;
}

.all_games_date_range {
    display: flex;
}

.all_games_date_range .input-right-icon {
    display: flex;
    gap: 10px;
    -webkit-gap: 10px;
    -moz-gap: 30px;
    -o-gap: 30px;
    align-items: center;
}

.tabbing_all_games .tabbing_ui_fixing{
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
}

.tabbing_ui_fixing .nav-item {
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    border-right: 1px solid #E6E6E6;
}

.tabbing_ui_fixing .nav-item:last-child {
    border-right: initial;
}

.dashboard_organizer .tabbing_ui_fixing .nav-link {
    width: 100%;
    max-width: 218px;
    min-width: 204px;
    min-height: 40px;
}

.dashboard_organizer .tabbing_ui_fixing .nav-link:hover {
    border: none;
    box-shadow: none;
}

.dashboard_organizer .tabbing_ui_fixing .nav-link:focus {
    border: none;
    box-shadow: none;
}

.dashboard_organizer .tabbing_ui_fixing .nav-link:focus-visible {
    border: none;
    box-shadow: none;
}

.dashboard_organizer .tabbing_ui_fixing .nav-link.active {
    width: 100%;
    max-width: 195px;
    min-width: 161px;
    background: #F05829;
    font-weight: 600;
    min-height: 40px;
    color: #fff;
    border-radius: 10px;
}

.dashboard_organizer .tabbing_ui_fixing .nav-link.active:hover {
    color: #fff;
}

.premier_leagues_heading_subheading {
    display: flex;
    justify-content: space-between;
}

.premier_leagues_heading_subheading .premier_leagues_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.premier_leagues_heading h4 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
}

.premier_leagues_heading a {
    background: #F05829;
    color: #fff;
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 12px;
}

.tabbing_all_games .team_vs_team {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    border: 1px solid #EEF0EF;
    background: #FBFBFB;
    padding: 10px;
}

.tabbing_all_games .team_vs_team .team_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.tabbing_all_games .team_vs_team .team_1 h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.tabbing_all_games .team_vs_team p {
    margin-bottom: 0;
    max-width: 162px;
    width: 100%;
    color: #000;
    font-weight: 500;
}

.tabbing_all_games .team_vs_team .verticle_line {
    width: 1px;
    height: 100%;
    min-height: 30px;
    background: #C5C5C5;
}

.tabbing_all_games {
    border: 1px solid #E6E6E6;
    border-radius: 11px;
    width: 100%;
    margin-bottom: 10px;
    transition: all 0.4s ease-in-out;
    background: #fff;
}

.tabbing_all_games .tab-content {
    padding: 10px;
    max-height: 293px;
    overflow-y: auto;
    height: 100%;
    transition: all 0.4s ease-in-out;
}

.tabbing_all_games .tab-content:hover::-webkit-scrollbar {
    /* display: block; */
    transition: all 0.4s ease-in-out;
    visibility: visible;
}

.tabbing_all_games .tab-content::-webkit-scrollbar {
    /* display: none; */
    transition: all 0.4s ease-in-out;
    visibility:hidden;
}

.tabbing_all_games .tab-content::-webkit-scrollbar-track
{
	-webkit-box-shadow: transparent;
	background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.tabbing_all_games .tab-content::-webkit-scrollbar
{
	width: 6px;
    height: 80px;
	background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.tabbing_all_games .tab-content::-webkit-scrollbar-thumb
{
	background-color: #D9D9D9;
    transition: all 0.4s ease-in-out;
}

.tabbing_all_games .tab-content .premier_leagues_dates p {
    margin-bottom: 0;
    padding-bottom: 0;
}

.live_game {
    width: 100%;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    margin: 20px 0 0;
    padding: 20px;
    min-height: 414px;
    background: #fff;
    max-height: 415px;
}
.live_game .heading_live_game .form-check {
    margin: 0;
}

.live_game .heading_live_game .form-check .form_input_radio h4, .live_game .heading_live_game .form-check .form_input_radio p{
    margin: 0;
    padding: 0;
}

.live_game .heading_live_game .form-check .form_input_radio h4{
    font-size: 19px;
    color: #000;
    font-weight: 600;
}

.live_game .heading_live_game .form-check .form_input_radio a {
    background: #F05829;
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    padding: 4px 14px;
    font-weight: 400;
    margin-left: 10px;
    /* position: relative;
    top: -2px; */
}

.live_game .heading_live_game .form-check .form_input_radio p{
    color: #000;
    font-size: 12px;
    font-weight: 400;
    margin-top: 7px;
}

.heading_live_game .form-check .form-check-input[type=radio]{
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    background-color: #27AE60;
}

.heading_live_game .form-check .form-check-input:checked[type=radio]{
    background-color: #27AE60;
}

.heading_live_game .form-check .form-check-label {
    margin-left: 10px;
}

.live_game_team1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    -webkit-gap: 10px;
    -moz-gap: 10px;
    -o-gap: 10px;
    margin: 14px 0;
    background: #F2F7FF;
    border-radius: 10px;
    border: 1px solid #EEF0EF;
    height: 86px;
    margin-right: 9px;
}

.live_game_team1 h4{
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 0;
}

span.team_1_vs {
    display: block;
    width: 100%;
    text-align: center;
    color: #F05829;
    font-size: 24px;
    font-weight: 500;
    margin: 3px 0;
}

.team_1_see_detail{
    border: 1px solid #484848;
    border-radius: 10px;
    min-height: 47px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
    color: #484848;
    font-size: 20px;
    font-weight: 600;
    transition: all 0.4s ease-in-out;
}

.team_1_see_detail:hover{
    border: 1px solid #F05829;
    background: #F05829;
    color: #fff;
    transition: all 0.4s ease-in-out;
}

.notification_listing {
    width: 100%;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    margin: 20px 0 0;
    padding: 20px;
    height: 100%;
    max-height: 413px;
    position: relative;
    overflow-y: auto;
    transition: all 0.4s ease-in-out;
    padding-bottom: 0;
    background: #fff;
}

/* .notification_listing:hover::-webkit-scrollbar {
    display: block;
    transition: all 0.4s ease-in-out;
} */

.notification_listing::-webkit-scrollbar {
    display: block;
    transition: all 0.4s ease-in-out;
}


.tolal-details.payments-total {
    background: #F1372B;
}
.notification_listing::-webkit-scrollbar-track
{
	-webkit-box-shadow: transparent;
	background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.notification_listing::-webkit-scrollbar
{
	width: 6px;
    height: 80px;
	background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.notification_listing::-webkit-scrollbar-thumb
{
	background-color: #D9D9D9;
    transition: all 0.4s ease-in-out;
}

.sidebar.active .navigation-bar ul li {
    padding: 0px !important;
    margin: 20px 0 0 0px !important;
}

.heading_notification h4 {
    font-size: 20px;
    font-weight: 600;
}

.notification_1 {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid #EEF0EF;
    padding: 10px 0 10px 0px;
}

.body_notification .notification_1 svg {
    max-width: 110px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
    object-fit: cover;
}

.body_notification .notification_1 p span{
    display: block;
    color: #9A9A9A;
    font-size: 10px;
    font-weight: 400;
    margin-top: 5px;
}

.body_notification .notification_1 p {
    font-size: 12px;
    color: #484848;
    margin-bottom: 0;
}

.button_see_all_notification {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 10px;
    text-align: center;
    background: #fff;
}

.button_see_all_notification a{
    color: #F05829;
    font-size: 14px;
    font-weight: 600;
}

.live_game .slick-prev:before, .live_game .slick-next:before {
    color: #F05829;
    width: 40px;
    height: 40px;
    background: #EEF0EF;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.live_game .slick-prev {
    top: -40px;
    left: 226px;
}

.live_game .slick-next {
    top: -40px;
    left: 271px;
}

.live_game .slick-next:before {
    content: '🠊';
}

.live_game .slick-prev:before {
    content: '🠈';
}

.team_vs_team span{
    color: #F05829;
    font-weight: 600;
    font-size: 20px;
}

.all_games_section {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.accordion_inner_basketball .accordion .accordion-item {
    background: transparent;
}

hr.verticle-line {
    margin: 0;
}

.background_basketball{
    background: #F0EAE8;
}

.accordion_first_games .accordion_inner_basketball .accordion .accordion-item {
    margin-top: 0;
}

.color_black span.mx-2{
    color: #000 !important;
    font-size: 18px;
    font-weight: 500;
}

.dashboard_organizer .sidebar.active .navigation-bar .accordion-item .accordion-button h4 {
    padding-left: 0 !important;
}

.sidebar.active .navigation-bar ul li a, .navigation-bar .dropdown-toggle.btn {
    padding: 0 !important;
}

.sidebar .logout {
    padding-left: 40px;
    padding-bottom: 20px;
    position: absolute;
    bottom: 0;
}

.innerDashboard .top-nav .create_button{
    width: 100%;
    min-width: 107px;
    max-width: 107px;
    background: #27AE60 !important;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create_tournament_modal .modal-header {
    border-bottom: none;
    margin: 0;
    padding-top: 0;
}

.create_tournament_modal .create-next{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create_tournament_modal .create-next.submission {
    margin-top: 40px;
}

.create_tournament_modal .modal-body .modal-header button.btn-close {
    top: 25px;
}

.dashboard_icon_remove button::after{
    background-image:none !important;
}

.form_submit_basketball {
    width: 100%;
    margin-bottom: 10px;
}

.form_submit_basketball .form-group {
    margin-bottom: 30px !important;
}

.form_submit_basketball .form-group .text-danger {
    position: absolute;
    /* bottom: -27px; */
    font-family: 'Roboto', sans-serif;
}

.text-danger {
    font-size: 13px;
}


.form_submit_basketball .enable-input {
    margin-top: 5px !important;
}

.payment_detail_basic{
    /* font-size: 20px !important; */
    font-weight: 400;
}

.form_submit_tabbing .nav-link h4{
    margin-bottom: 0;
}

.dashboard_basketball {
    padding: 0 !important;
    margin: 0 !important;
}

.dashboard_basketball li {
    margin: 0 !important;
    display: flex;
    padding: 0 !important;
    align-items: center;
    padding-left: 0 !important;
}

.dashboard_organizer .dashboard_basketball li a {
    font-size: 18px !important;
    color: #000 !important;
    display: flex !important;
    padding-left: 15px !important;
    padding: 16px 0;
}

.dashboard_basketball li span{
    display: inline-block;
    margin-right: 10px;
}

.input_parent {
    width: 100%;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end;
}

.team_list_cards {
    padding: 25px;
    padding-bottom: 0 !important;
}

.team_list_navs{
    justify-content: flex-end !important;
    gap: 20px !important;
    padding: 20px 20px 0;
}

.team_list_navs a{
    width: 100%;
    max-width: 135px;
    min-width: 135px;
    max-height: 32px;
    min-height: 32px;
    background: #fff;
    color: rgba(72, 72, 72, 0.50);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 500;
}

.team_list_navs a:hover {
    color: #000;
}

.games_dropdown_date_range select.form-select {
    font-size: 18px;
    font-weight: 600;
}

a.btn.btn-info.btn.btn-primary {
    background: #298CEA;
    border-color: #298CEA;
    color: #fff;
    width: 100%;
    max-height: 33px;
    min-height: 23px;
    min-width: 76px;
    max-width: 97px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-total-event-table th {
    color: #484848;
}

.payment-history {
    display: flex;
    justify-content: space-between;
    padding: 22px 13px 9px;
}
.duepaymet {
    text-align: center;
}

.duepaymet h4 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 1rem;
}

.duepaymet h3 {
    font-size: 50px;
    font-weight: 700;
}

.duepaymet {
    width: 100%;
    height: 230px;
    border-radius: 10px;
    border: 1px solid #D4D4D4;
    padding-top: 1rem;
    position: relative;
}
a.color-view {
    color: #f05829;
    font-size: 14px;
    font-weight: 600;
}

.payment-history h4 {
    font-size: 20px;
    font-weight: 600;
}
a.view-next-details {
    color: #298CEA;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline !important;
}

.due-payments-details table.table.table-striped {
    white-space: nowrap;
    width: 1885px;
}
/* .dashboard-total-event-table table.table.table-striped td {
    max-width: 137px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */


.dashboard-total-event-table tr td p {
    max-width: 142px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    font-family: 'Roboto';
    color:#000;
    margin-right: 70px;
}

.dashboard-total-event-table tr td span {
    max-width: 142px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    font-family: 'Roboto';
    color:#000
    
}
.due-payments-details td {
    color: #000 !important;
    font-weight: 400;
}

.due-payments-details table.table td {
    font-family: 'Roboto';
}

table.table td{
    font-family: 'Roboto';

}

.due-payments-details table.table td {
    max-width: 142px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    font-family: 'Roboto';
}

.dashboard-total-event-table td {
    padding: 5px !important;
}

.total_revenue_line_graph .table-striped>tbody>tr:nth-child(even) {
    border: 1px solid #f4f4f4;
    border-top: 1px solid #f4f4f4 !important;
}

.due-payments-details .table-striped>tbody>tr:nth-child(even) {
    border: 1px solid #f4f4f4;
    border-top: 1px solid #f4f4f4 !important;
}
.due-payments-details .table th {
    color: #484848;
}

button.btn.btn-info.btn.btn-primary {
    background: #298CEA;
    border-color: #298CEA;
    color: #fff;
    width: 100%;
    max-height: 34px;
    min-height: 23px;
    min-width: 83px;
    max-width: 101px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footbal-icon {
    margin-top: 5px;
}

.dashboard-total-event-table tr td {
    border-top: 1px solid #f4f4f4 !important;
}
.due-payments-details tr td {
    border-top: 1px solid #f4f4f4 !important;
}

.due-payments-details table.table.table-striped tr {
    border-bottom: 1px solid #f4f4f4;
}

.due-payments-details table.table.table-striped thead {
    border-top: 2px solid #D3D3D3;
    border-bottom: 2px solid #D3D3D3 !important;
}

.dashboard-total-event-table table.table.table-striped thead {
    border-top: 3px solid #D3D3D3 ;
    border-bottom: 3px solid #D3D3D3 !important;
}

.footbal-icon.football-teams {
    margin-left: 18px;
}

.dashboard-total-event-table tbody tr {
    border-bottom: 1px solid #fafafa;
}





/* .video-box .bg-dark.p-2 {
    background: #0000005c;
    height: 100vh;
    width: 100%;
    opacity: 0.8;
    border-radius: 10px;
}

.video-box-started {
    position: absolute;
    z-index: 9999;
    width: 100%;
    min-width: 667px;
     max-width: 100%;
}

.video-box {
    position: relative;
}

.video-started-section {
    position: absolute;
    top: 27%;
    left: 31%;
} */
.css-16jesut >.ps-menu-button{

    padding-top: 30px;
    padding-bottom: 30px;
}

.css-1t8x7v1 >.ps-menu-button{

    padding-top: 30px;
    padding-bottom: 30px;

}

@media (min-width: 1500px) and (max-width: 1920px) { 
    .season-trophy-box .time_schedule {
        width: 22%;
    }

 }

 .live_game .empty-state {
    height: 271px;
}

.Notification-empty-box {
    height: 300px;
}
.dashboard-total-event-table .empty-state {
    height: 300px;
}

.tabbing_all_games .tab-content .No-game {
    height: 248px;
}

.seasonDashboardTab.active-seasonDashboar-tab .No-game {
    height: 246px;
}


.grade-content.team-list-side .No-game {
    height: 28vh;
}

.table_list.Enquiry-team-table th {
    width: 33.3%;
}

/* .background_body_table.games-table-body {
    height: 67vh;
    overflow: auto;
} */

.team_bracket_section.brackets-data {
    width: 100%;
    height: 550px;
    overflow-y: auto;
}
.venue-slots .No-games {
    height: 462px;
}
.group-dashboard.nav.nav-pills .no-games {
    text-align: center;
}
.grade-content.team-list-side button.btn.btn-dark.green.orange-color {
    padding: 5px 42px;
}

.payment_listing_background {
    height: 73vh;
    overflow: auto;
}

.payment_listing_background .No-game {
    height: 400px;
}

.No-games-slider{
    height: 400px;
}

.slider-spinner{
    height: 400px;
  }


@media (max-width: 1400px) { 
    .total_revenue_component {
        flex-direction: column;
    }
    
 }


 .item.Amount-reciver-due {
    min-height: 37px !important;
    border-radius: 5px !important;
}
.Amount-reciver-due .tolal-details {
   padding: 7px 10px 7px 9px;
   border-radius: 5px 1px 0px 6px;
}
.Amount-reciver-due h6 {
    font-size: 17px !important;
    margin: 0;
}