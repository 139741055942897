.empty-state {
  width: 100%; /* Adjusts width to 100% */
  height: 100%; /* Adjusts height to 100% */
  background: #ffffff;
  border-radius: 4px;
}

.empty-state__content {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers content horizontally and vertically */
  flex-direction: column;
  width: 100%; /* Ensures content takes 100% width */
  height: 100%; /* Ensures content takes 100% height */
}

.empty-state__icon {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: #f7fafc;
  box-shadow: 0px 2px 1px #e1e3ec;
}

.empty-state__icon img {
  width: 170px;
}

.empty-state__message {
  color: #38a169;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.85rem;
  margin-bottom: 0.3rem;
}

.empty-state__help {
  color: #a2a5b9;
  font-size: 0.875rem;
}


.reports .empty-state{
  display: flex;
  align-items: center;
  height: 641px;

} 