.tour__wrap {

  display: flex;
  max-height: 1550px;
  min-width: 1636px;
  /* width: 100%; */
  flex: 1 1;
}
.scroll{
  overflow: auto;
}
/* 
.archer__container {
  width: 100%;
} */

.se__round {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.se__placer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  padding: 15px;
}

/* .se__bracket {
  border: 1px solid yellow;
} */

/*  */

.se__custom {
  width: 250px;
  /* height: 150px; */
  padding: 5px;
  border: 1px solid black;
  text-align: center;
  background: #f0582924;
  border-radius: 11px;
  border: 1px solid #ef5829;
  padding: 11px 9px;
  margin: 1rem 0px;
}
.imge_with_team_name h5 {
  font-size: 14px;
}
p.todecited {
  font-size: 18px !important;
}

.bracket-card-view img.img-fluid {
  width: 20px;
  height: 20px;
}

.bracket-card-view p {
  margin: 0;
  font-size: 10px;
}

.bracket-card-view span.numbering_under_bracket.text.text-primary {
  background: #fff;
  padding: 0px 9px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  margin-left: 5px;
}

.bracket-card-view .imge_with_team_name h6 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 99px;
  max-width: 97px;
}
.bracket-card-view.second {
  background: aliceblue;
  border: 1px solid #03A9F4;
}

.bracket-details {
  display: flex;
  gap: 9px;
}

hr.border-dashed {
  border-top: 1px dotted #007bff;
  margin: 9px 0px;
}

.vennu-filed {
  text-align: justify;
  font-size: 12px;
}