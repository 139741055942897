.season-popup .weight_bold {
    font-weight: 500;
}

.create_season_modal .modal-dialog .newCreateLeague .nav-link.active {
    border-bottom: 2px solid #000;
}

.create_season_modal .modal-dialog .newCreateLeague .nav-tabs .nav-link {
    color: #969696;
    font-size: 16px;
    font-weight: 400;
}

.create_season_modal .modal-dialog .newCreateLeague .nav-tabs .nav-link.active {
    color: #000;
    font-weight: 400;
    font-size: 16px;
}

.create_season_modal .modal-dialog .newCreateLeague table tr {
    border-top: none;
    border-bottom: none;
}

.create_season_modal .modal-dialog .newCreateLeague table.table.table-striped.table-hover th {
    font-size: 16px;
    color: #484848;
    border: none;
    padding: 0 !important;
}

.create_season_modal .modal-dialog .newCreateLeague table tbody td input {
    border: 1px solid #D4D4D4;
    padding: 7px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    margin-top: 9px;
}

.create_season_modal .modal-dialog .newCreateLeague table tbody .remove_button {
    margin-top: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}

.create_season_modal .modal-dialog table.table.table-striped.table-hover .form-group {
    margin-bottom: 6px !important;
}

.create_season_modal .modal-dialog .tab-content button.add_more_plan {
    width: 100%;
    max-width: 129px;
    background: #C5F2D8;
    font-size: 14px;
    color: #27AE60;
    min-height: 25px;
    max-height: 25px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create_season_modal .modal-dialog table.table.table-striped.table-hover {
    margin-bottom: 0;
}

.create_season_modal .modal-dialog .paymentt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.create_season_modal .modal-dialog .paymentt input {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d4d4d4;
    min-width: 188px;
}

.create_season_modal .modal-dialog form.season-popup button.button_submit {
    padding: 9px 21px;
    margin-top: 12px;
    background-color: #27AE60;
    min-width: 197px;
    min-height: 44px;
}

/* CreateLeagueNew */
.season-popup .newCreateLeague .nav-link.active {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
}

.season-popup .newCreateLeague .nav-tabs .nav-link {
    color: #969696;
    font-size: 20px;
    font-weight: 500;
}

.season-popup .newCreateLeague .nav-tabs .nav-link.active {
    color: #000;
}

.season-popup .newCreateLeague .schedule-1 .create-next button.btn.btn-dark.next-orange-btn {
    width: 100% !important;
    height: 100%;
    background: #27AE60;
    border-color: #27AE60;
    max-height: 44px;
}

.season-popup .custom-pop-inner .custom-pop-box .create-form-inner .form-group .form-label {
    font-size: 20px;
    font-weight: 400;
    color: #484848;
    padding-bottom: 0;
}

.season-popup .tab-content .form-label {
    font-size: 20px;
    font-weight: 400;
    color: #484848;
}

.season-popup .tab-content label {
    /* font-size: 20px; */
    font-weight: 400;
    color: #484848;
}

.modal-body .modal-title {
    font-weight: 600;
}

/* Enroll Team */

.season-popup button.btn.btn-dark.green.add_more_plan {
    background: #27AE60;
    border: #27AE60;
}

.season-popup h3.pop-title.text-center.ms-0 {
    font-weight: 600;
}

/* Add Player Team */
.season-popup .divisions-schedule button.btn.btn-dark {
    background: #27AE60;
    border-color: #27AE60;
    color: #fff;
    max-height: 38px;
    height: 100%;
    min-width: 109px;
    z-index: 0;
}

.season-popup .create-teamf {
    max-height: 500px;
    overflow-y: auto;
    height: 100%;
}

.season-popup .create-teamf:hover::-webkit-scrollbar {
    /* display: block; */
    transition: all 0.4s ease-in-out;
}

.season-popup .create-teamf::-webkit-scrollbar {
    display: none;
    transition: all 0.4s ease-in-out;
}

.season-popup .create-teamf::-webkit-scrollbar-track {
    -webkit-box-shadow: transparent;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.season-popup .create-teamf::-webkit-scrollbar {
    width: 6px;
    height: 80px;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
}

.season-popup .create-teamf::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    transition: all 0.4s ease-in-out;
}

.season-popup select,
input[type="text"] {
    width: 100%;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 40px;
    height: 100%;
    display: flex;
    align-items: center;
}

.season-popup .searchWrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 40px;
    padding: 5px;
    position: relative;
    /* display: flex; */
    align-items: center;
}

.season-popup .optionListContainer .optionContainer li {
    text-align: left;
}

/* Add */

.season-popup button.addfield-btn {
    background-color: #27AE60;
    border-color: #27AE60;
    height: 40px;
    max-width: 42px;
}

.season-popup button.cross-btn {
    border: initial;
}

.season-popup .css-13cymwt-control {
    max-height: 40px;
    height: 100%;
}

.season-popup .css-qbdosj-Input {
    height: auto;
}

.season-popup .input-right-icon svg {
    top: 7px;
}

/* Common Form */
.season-popup .btn.btn-primary {
    background: #27AE60;
    border-color: #27AE60;
    border: 0;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.season-popup .react-tel-input .form-control {
    max-height: 40px !important;
    border-radius: 0 !important;
    border-radius: 8px !important;
    border: 1px solid #d4d4d4 !important;
}

.season-popup .create-form-inner input,
.create-form-inner select {
    max-height: 40px;
}

.season-popup .form-group .form-label {
    font-size: 16px;
    font-weight: 400;
    color: #484848;
    margin-bottom: 5px;
    display: inline-block;
}
.notification-dertails {
    color: #484848B2;
    /* border: 1px solid #484848B2; */
    border-radius: 10px;
    /* font-size: 16px; */
    background-color: #ECECEC;
    padding: 7px 12px;
}

.Instructions-listing li::marker {
    font-size: 20px;
}
ul.Instructions-listing.text-start li {
    font-size: 14px;
}

ul.Instructions-listing.text-start {
    padding: 0px 29px;
    margin: 0;
}

.find_input_button {
    display: flex;
}

.season-popup .create-form-inner .create-next button.btn.btn-dark.green {
    background: #27AE60 !important;
    border: #27AE60 !important;
    max-height: 38px;
    height: 100%;
    min-width: 109px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-weight: 400 !important;
}

.dashboard_organizer .organizer_events .season-popup .modal-content {
    transform: translateX(0%) !important;
}

.button_time_slot {
    max-height: 44px;
    height: 100%;
}

.edit_profile .form-sec .form-control {
    border-radius: 7;
    min-height: 44px !important;
    font-size: 17px;
}

.season-popup .table.table-striped.table-hover td {
    padding-left: 14px !important;
}

/* Season page */
.season_payment_table td:first-child {
    max-width: 240px;
}

.season_payment_table td:nth-child(2) {
    max-width: 140px;
}

/* .season_payment_table td:nth-child(2) input{
    text-align: end !important;
} */

.season_payment_table td:nth-child(3) {
    max-width: 139px;
}

/* .season_payment_table td:nth-child(3) input{
    text-align: end !important;
} */

/* .create_season_modal .modal-dialog {
    max-width: 780px !important;
    width: 100%;
    margin-top: 80px;
} */

.create_season_modal .modal-dialog .modal-body {
    /* padding: 40px; */
    padding: 30px;
}

.newCreateLeague .nav.nav-tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px !important;
}

/* input[type=date]{
    position: relative;
} */

select,
input[type="text"] {
    /* border: 1px solid #d4d4d4 !important; */
    border-radius: 8px !important;
    border: 0;
}

.input-find input {
    border: 1px solid #d4d4d4 !important;
}

input[type=date] {
    position: relative;
    border: 1px solid #d4d4d4;
    font-size: 14px;
}

input[type=date]::before {
    position: absolute;
    content: '';
    width: 8px;
    height: 19px;
    background: #fff;
    right: 9px;
}

input[type=date]::after {
    position: absolute;
    content: '';
    width: 25px;
    height: 28px;
    background-image: url(../images/calendar_icon.png);
    right: 9px;
    top: 6px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    position: absolute;
    right: 9px;
    height: 28px;
    width: 28px;
    z-index: 2;
}

/* .season-popup table.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent !important;
}

.season-popup table.table-striped tbody tr:nth-of-type(odd):hover {
    background-color: transparent !important;
} */

.season-popup .search-wrapper.searchWrapper input {
    padding: 0;
    border: none !important;
}

.input-right-icon .css-b62m3t-container input {
    border: none !important;
}

.create-form-inner input.form-control {
    border: 1px solid #d4d4d4 !important;
}

.season_payment_table table.table.table-striped tr th,
table.table tr td {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: top;
}

.form_submit_basketball .form-group .ErrorHandling {
    position: absolute;
}

/* 22-1-2023 preeti */

/* .season_payment_table td:nth-child(2) input {
    text-align: center !important;
} */

.season_payment_table td:nth-child(3) input {
    text-align: center !important;
}

.season-popup .tab-content label {
    font-size: 17px;

}

.create-from.form_submit_basketball input.form-control {
    width: 100%;
    max-width: 443px;
    min-width: 439px;
}

.create-from.form_submit_basketball .search-wrapper.searchWrapper {
    width: 100%;
    max-width: 529px;
    min-width: 533px;
    border-radius: 10px;
}

.custom-pop-box.table-pop-up.enroll-wrap {
    padding: 0;
}



/* table.table.standing-table.table-striped.score-past.enrollment_table_page th.enrollment_table-th {
    top: -30px;
} */
/* 
table.table.standing-table.table-striped.score-past.enrollment_table_page thead {
    height: 55px;
} */