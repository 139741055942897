.radius-10{
	border-radius:10px;
}
.radius-20{
	border-radius:20px;
}
.radius-circle{
	border-radius:50%;
}
.margin-t40 {
	margin-top: 40px;
}
.margin-t30 {
	margin-top: 30px;
}
.mb30{
	margin-bottom: 30px;
}
.mb20{
	margin-bottom: 20px;
}
.mb10{
	margin-bottom: 10px;
}
.margin-t20 {
	margin-top: 20px;
}
.margin-t10 {
	margin-top: 10px;
}
.f14 {
    font-size: 14px;
}
.red-link{
	color:#FF0000;
}
.red-link:hover{
	color:#FF0000;
	opacity: 0.8;
}
.blue-link {
    color: #00acc0;
}
.blue-link:hover {
    color: #1f9ead;
}
.btn {
    box-shadow: unset!important;
}
button.btn.btn-transparent {
    border: 1px solid #000;
}
button.btn.btn-transparent:hover {
    background: #000;
    color: #fff;
}
.btn.btn-primary:hover {
    background: #db655a;
}
.btn.btn-primary {
    background: #E77469;
    border-color: #E77469;
}
.btn-dark:hover {
    background-color: #3c4248;
    border-color: #3c4248;
}
.btn.btn-default {
    background: #D9D9D9;
    border: 1px solid #D9D9D9;
}
.btn.btn-default:hover {
    background: #d3d2d2;
}
.btn.gray-transparent-btn:hover {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0,0,0,1);
}
.btn.gray-transparent-btn {
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0,0,0,0.6);
}
.btn-lg{
    padding: 13px 20px;	
}
.btn-md{
    padding: .375rem 20px;	
}
.full-btn {
    width: 100%;
}
.bg-gray {
	background: #D9CEB4;
}
.full-width-btn {
    width: 100%;
}
.download-btn {
    width: 55px;
    height: 55px;
    background: rgba(0, 172, 192, 0.59);
    border-radius: 12px;
    border-width: 0;
    color: #fff;
}
.download-btn:hover {
    background: rgba(0, 172, 192, 0.7);
}
.btn-gray{
    background: #f0f0f0;
}
.btn-gray:hover {
    background: #ededed;
}
/*20/03/2023*/
.form-control {
	/* height: 60px; */
    
    height: 45px;
    border-color: #ddd;
    border-radius: 0;
    outline: unset;
    box-shadow: unset;
    box-shadow: unset;
}

.form-control:focus {
    box-shadow: unset;
}
.btn.btn-success {
    background: #1E8400;
    border-color: #1E8400;
    width: 100px;
    border-radius: 10px;
}
.btn.btn-success:hover {
    background: #269107;
}
.table-action .btn.btn-success {
    background: #1E8400;
    border-color: #1E8400;
    width: 80px;
    border-radius: 4px;
    font-size: 12px;
}
.table-action .btn.btn-danger {
    background: #DA0000;
    border-color: #DA0000;
    width: 80px;
    border-radius: 4px;
    font-size: 12px;
}
.form-control {
    box-shadow: unset!important;
    outline: 0!important;
}
.table-sec.common-table.margin-t30 {
    width: 100%;
}

.search .form-control {
    background: rgba(230, 230, 230, 0.6);
    border-radius: 10px;
    height: 42px;
    float: right;
    margin-right: 20px;
}

.enroll_team_button_search .search .form-control {
    background: transparent;
    border-radius: 10px;
    height: 44px;
    font-size: 14px;
    margin-left: 4px;
    width: 285px;
    margin-right: 0;
}

h3.title {
    font-size: 19px;
    margin: 17px 0px 10px;
}
button.btn.activities {
    background: rgba(230, 230, 230, 0.6);
    width: 50%;
    height: 49px;
    border-radius: 4px;
}
.create-venue-form button.btn.btn-dark {
    height: 46px;
    width: 187px;
}
.create-venue-form form {
    display: flex;
    column-gap: 1px;
}
.create-venue-form .form-control {
    background: rgba(230, 230, 230, 0.6);
    border-radius: 10px;
    height: 50px;
    border-width: 0;
}
.comunication-form .btn {
    height: 50px;
    width: 140px;
    float: right;
}
.dashboard-auto-wrapper-2 {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
    height: 50%;
}

.dashboard-top-btn-2 button.btn.btn-dark {
    font-size: 13px;
    margin: 23px 5px 4px;
}
.dashboard-top-btn-2 {
    display: flex;
    margin-bottom: 20px;
}
/*29-03-2023 Preeti*/
.dashboard-top-btn-2 {
   
    margin-top: 45px;
}
.custom-pop-box.dashboard-popup {
    height: 265px;
}
.modal-content.dashboard_pop_up {
    top: 125px;
}
.uploade-file.Contents-file {
    display: grid;
}
.Add-option {
    margin: 0px 0px 26px;
}

/*30-03-2023 Preeti*/
.skip {
    margin-top: 17px;
}

.time {
    border-color: #cbd0dd;
    display: flex;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group-addon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    background-color: #282041de;
}
.input-group-addon {
    color: white;
}
.time {
    width: 451px;
}
button.btn.btn-dark.green {
    /* background: #282041b8; */
    background:  #282041eb;
    border-color: #282041eb;
}

button.btn.btn-dark.blue {
    background: #282041eb;
    border-color: #282041eb;
}

/* button.btn svg path {
    color: #282041eb;
} */

.dashschedule {
    height: 182px !important;
}
.dashschedule .dashboard-top-btn-2 {
    margin: 20px !important;
}
.dashboard_schedule .modal-dialog{
    margin : 14.75rem 26rem !important;
}

/*31-03-2023 Preeti*/
.copy-tournament-date {
    margin-bottom: 35px;
    margin-top: 14px;
}

/*3-04-2023 Preeti*/
button.btn.btn-dark {
    background: #282041de;
    border-color: #282041de;
}
/* button.btn.btn-dark:hover {
    background: #fff;
    color: #282041de;
} */
.input-group.games-time {
    margin: 0px 0px 18px;
}

/*4-04-2023 Preeti*/


button.btn.btn-transparent {
    border: 1px solid #282041de;
}
/*6-04-2023 Preeti*/

.dashboard-top-btn h4 {
    flex: auto;
    margin: 11px 13px 4px;
    font-weight: 600;
}
/*10-04-2023 Preeti*/

.signup {
    color: #282041de;
    cursor: pointer;
}

/*11-04-2023 Preeti*/

.col-sm-12.Signup {
    margin-top: 15px;
}
button.btn.btn-transparent:hover {
    background: #282041de;
    color: #fff;
}

button.btn.btn-dark.savesubmit {
    width: 141px;
    height: 48px;
    font-size: 17px;
    font-weight: 100;
    background: #27ae60;
    border: 0;

}

/*11-04-2023 Preeti*/

label.custom-file-upload {
    padding: 10px 12px;
    cursor: pointer;
    background: #282041de;
    border-color: #282041de;
    color: #fff;
    border-radius: 10px;
    width: 143px;
    /* height: 52px;*/
    text-align: center;
    display: block;
}

label.profile-file-upload {
    cursor: pointer;
    display: block;
    color: #298CEA;
    margin: 0 6px;
}


.custom-file-upload input[type="file"], .profile-file-upload input[type="file"] {
    display: none;
}
/*13-04-2023 Preeti*/
.common-table a {
    color: #000;
}

.MuiTableCell-body a {
    color: rgba(0, 0, 0, 0.87);
}

.MuiTableCell-body a:hover {
    color: rgba(0, 0, 0, 0.87);
}

button.btn.btn-dark.green.green.addfamily {
    margin-bottom: 18px;
}


.pop-form label.custom-file-upload {
    font-size: 18px;
    padding: 10px 12px;
    cursor: pointer;
    background: #282041de;
    border-color: #282041de;
    color: #fff;
    border-radius: 10px;
    width: 143px;
    text-align: center;
    display: block;
}

button.btn.btn-dark.green.green.create {
    width: 109px;
    height: 42px;
}


.create-from-btn.submission.datesubmission {
    margin-top: 33px;
}

button.btn.btn-dark.green.rulesbtn {
    margin: 0px 0px 25px;
}

/*14-04-2023 Preeti*/
.dashboard-top-btn h5 {
    flex: auto;
    margin: -13px 13px 4px;
    font-weight: 600;
}

button.btn.btn-transparent.back {
    margin-top: 22px;
}

button.btn.btn-dark.green.team {
    margin-bottom: 20px;
}

button.exportbtn {
    margin: 0 0 1rem 0.5rem;

}

 table.table.table-striped.table-hover th {
    font-size: 16px;
    font-weight: 400;
    color: #6e7a7a;
    position: sticky;
    top: -1px;
    z-index: 1;
}
.table-format.top-table {
    margin-top: 5px;
}
 .table-wrapper  {
    padding: 28px 0px;
    margin: 19px 0;
    border-radius: 10px;
}
table.table.table-striped tr th, table.table tr td {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
}
table.table .table-striped tr th:first-child {
    width: 60px;
}
table.table .table-striped tr th:last-child {
    width: 100px;
}
/* table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F4F4F4;
}
table.table-striped.table-hover tbody tr:hover {
    background: #F4F4F4;
} */
table.table .table-striped th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
}	
table.table .table-striped td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
}


.custom-checkbox {
    position: relative;
}
.custom-checkbox input[type="checkbox"] {    
    opacity: 0;
    position: absolute;
    margin: 5px 0 0 3px;
    z-index: 9;
}
.custom-checkbox label:before{
    width: 18px;
    height: 18px;
}
.custom-checkbox label:before {
    content: '';
    /* margin-right: 10px; */
    display: inline-block;
    vertical-align: text-top;
    background: white;
    /* border: 1px solid #bbb; */
    border-radius: 2px;
    box-sizing: border-box;
    z-index: 2;
}
.tournament-img img {
    width: 25px;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid #000;
    border-width: 0 3px 3px 0;
    transform: inherit;
    z-index: 3;
    transform: rotateZ(45deg);
}
.custom-checkbox input[type="checkbox"]:checked + label:before {
    border-color: #000000;
    background: #000000;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
    border-color: #fff;
}
.custom-checkbox input[type="checkbox"]:disabled + label:before {
    color: #b8b8b8;
    cursor: auto;
    box-shadow: none;
    background: #ddd;
}
    .table.table-striped.table-hover td {
        font-size: 14px;
        padding: 20px 13px;
        color: #757575;
        font-family: 'Poppins';
    }
a.text-color {
    color: #f05829;
    padding: 0px 1px;
}
a.text-color-green {
    color:  #27AE60;
}
span.text-color {
    color: #f05829;
    font-size: 13px;
    font-weight: 600;
    font-family: system-ui;
}


.color-orange {
    color: rgba(242, 153, 74, 1) !important;
}
.text-color-green {
    color:  rgb(39 174 96) !important;
}
button.color-btn-green {
    color: #24af24;
    border: none;
    background-color: rgb(39 174 96 / 40%);
    width: 73px;
    height: 31px;
    border-radius: 8px;
    font-size: 15px;
}

button.color-btn-blue {
    background-color: rgba(47, 128, 237, 0.1);
    color: rgba(47, 128, 237, 1);
    border-color: rgba(47, 128, 237, 0.1);
    width: 119px;
    height: 37px;
    border-radius: 6px;
}
.pay-btn {
    margin-left: -2rem;
}
.dropsbtn {
    display: flex;
}
button.color-btn-orange {
    color: #f26a30;
    border: none;
    background-color: rgb(252 189 149 / 36%);
    width: 116px;
    height: 31px;
    border-radius: 8px;
    font-size: 14px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: #fff !important;
}

.table-striped>tbody>tr:nth-child(even) {
    --bs-table-bg-type: #FAFAFA !important;
  }

.table-hover>tbody>tr:hover>* {
    --bs-table-bg-state: rgb(254 231 217 / 18%);
}


.menu-nav {
    display: flex;
    justify-content: center;
  }

.menu-item {
color: #FCC;
/* padding: 3px; */
}

.new-table .three-dots:after {
cursor: pointer;
color: #444;
content: '\2807';
font-size: 20px;
padding: 0 5px;
}
.new-table
a {
text-decoration: none;
color: #000;
}

.new-table a div {
padding: 10px;
border-bottom: 1px solid #d8d3d33b;
font-size:13px;
}

.new-table .dropdown {
    position: absolute;
    /* right: 50px; */
    right: 15px;
    background-color: #fff;
    outline: none;
    opacity: 0;
    z-index: 0;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s;
    width: 180px;
    text-align: center;
    z-index: 1;
}

.dropdown-container:focus {
outline: none;
}

.dropdown-container:focus .dropdown {
opacity: 1;
display: block;
z-index: 100;
max-height: 100vh;
transition: opacity 0.2s, z-index 0.2s,;
}
.new-table a div:hover {
    background-color: #240b744d;
    color: #000000;
    border-bottom: 1px solid #ffffff70;
}

.dashboard-search-input {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: -3rem;
}

.input-icon input.form-control {
    width: 315px;
    height: 60px;
}

button.btn.filter-btn .slider-btn img {
    width: 31px;
    margin-right: 2px

}
 button.btn.btn-default.filter-btn.slider-btn {
    background: none;
    border: none;
    display: none;
} 

button.btn.btn-dark.green.orange-color {
    background: #27ae60;
    border-color: #27ae60;
    /* width: 145px;
    height: 49px; */
}
.learn-more button.btn.btn-dark.green {
    width: 166px;
    height: 52px;
}

button.btn.btn-dark.green.green-color {
    background: #27AE60;
    border-color: #27AE60;
}

.dashboard-auto-wrapper.dashboard-1 {
    margin-top: 37px;
}
.nav-item p {
    margin-left: 3rem;
    margin-top: -1.5rem;
    font-size: 14px;
}
.vertical {
        height: 54px;
        position: absolute;
        left: 60%;
        border-left: 2.3px dotted #f00;
        z-index: -8;
  }
  .enroll-team {
    display: flex;
    justify-content: flex-start;
    margin-top: 7px;
}
h3.pop-title {
    margin-left: 13px;
}
.text-color-element.element {
    font-size: 19px;
    margin-top: 0rem;
    margin-bottom: 2rem;
    font-weight: 600;
}
.select-dropdown select.form-control {
    width: 221px;
    background-color: #F9F9F9;
    height: 37px;
    font-family: 'Poppins';
}
/* .space {
    margin-left: 3rem;
    margin-top: -3rem;
} */
.coach-profle div {
    margin-left: 1rem;
}
.coach-profle {
    display: flex;
    align-items: center;
    margin-left: -1rem;
}
span.text-green-color {
    color: #219653;
    font-size: 17px;
    font-weight: 700;
    font-family: system-ui;
}
span.blue-text {
    color: rgba(47, 128, 237, 1);
    margin-right: 2rem;
}
.payment {
    background-color: rgba(47, 128, 237, 0.1);
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 8px 6px;
    margin-right: 1rem;
}
.payment p {
    color: rgba(47, 128, 237, 1);
}
span.blue-text.edit-text {
    font-size: 18px;
    font-weight: 500;
}
/* .text-color-element.element.element-group {
    margin-top: -3rem;
} */
.date {
    background-color: #f2c94c29;
    text-align: center;
    margin-top: 38px;
    height: 46px;
    padding: 7px 0px;
}

.location {
    display: flex;
    justify-content: space-between;
}
.available {
    font-size: 17px;
    font-weight: 600;
}
.camp {
    font-size: 17px;
    font-weight: 600;
}
.create-matches hr {
    border-style: dotted;
    border-width: 1px;
}
.buttons button {
    background: transparent;
    color: #F1582B;
    width: 158px;
    height: 55px;
    font-size: 21px;
    border-radius: 14px;
    border: 1px solid;
    font-weight: 500;
}
.buttons button:hover {
    background-color: #ee5729;
    color: #fff;
}
.buttons {
    display: flex;
    justify-content: space-around;
}
.note p {
    font-size: 13px;
    font-weight: 500;
    color: #0000007d;
}
.create-team {
    border: 2px solid #ddd7d7;
    padding: 16px 22px;
    border-radius: 13px;
}
.team-profile h5 {
    margin-left: 13.5px;
}
span.team-mail {
    font-size: 18px;
    font-weight: 500;
    margin-left: 13.5px;
}
button.btn.btn-dark.green.white-green-color.enroll-btn {
    width: 248px;
    height: 63px;
}
.table-pop hr {
    margin-top: 16px;
    width: 95%;
    margin-left: 1rem;
    color: #b1a9a9;
}
.details img {
    width: 108px;
    height: 94px;
    margin-left: -0.5rem;
    border-radius: 10px;
}
.element-group h4 {
    margin-top: 0rem;
    font-size: 26px;
    font-weight: 600;
    margin-left: -2rem;
}
.check-box-modal .custom-checkbox label:before {
    width: 25px;
    height: 25px;
    border-radius: 6px;
}
.check-box-modal label {
    font-size: 18px;
    font-family: unset;
    margin-left: 5px;
}
span.custom-checkbox.parking-check {
    margin-left: 9px;
    border: 0;
}
.check-box-modal .custom-checkbox input[type="checkbox"]:checked + label:after {
    left: 14px;
    top: 3px;
    width: 8px;
    height: 18px;
}
.check-box-modal .custom-checkbox input[type="checkbox"]:checked + label:before {
    border-color: rgba(238, 87, 41, 1);
    background: rgba(238, 87, 41, 1);
}
.check-box-modal .custom-checkbox label:before {
    border: 1px solid #aaaaaa75;
}
.result button {
    background: #e0e0e082;
    border: 1px solid #e0e0e082;
    width: 94px;
    height: 39px;
    font-size: 17px;
    border-radius: 8px;
}
.winner-team {
    border: 1px solid rgba(33, 150, 83, 1);
    width: 195px;
    height: 219px;
    padding: 19px 29px;
    text-align: center;
    border-radius: 16px;
}
p.dark-green {
    font-size: 21px;
    color: #219653;
    font-weight: 500;
}

.looser-team {
    border: 2px solid #E6627A;
    width: 217px;
    height: 219px;
    padding: 19px 29px;
    text-align: center;
    border-radius: 16px;
}
h4.text-color-green {
    color: #219653;
}
h4.text-color-pink {
    color: #E6627A;
}

.spacing-words {
    font-size: 15px !important;
    margin-left: 1rem;
    font-family: 'Poppins';
}
div#enroll-maker {
    margin-top: 2rem;
}
.team-coach-detail img {
    /* width: 95px;
    height: 95px; */
    width: 145px;
   
}
label.text-short {
    font-size: 14px;
    color: #0000008a;
}
.personal-details span {
    font-size: 15px;
}
.body-details {
    display: flex;
    justify-content: space-around;
}
.body-details img {
    width: 25px;
    height: 25px;
}
.documents-social-number img {
    width: 50%;
    margin-right: 1rem;

}
.documents-social-number span {
    font-size: 15px;
}
.documents-required-number img {
    width: 43px;
    margin-right: 1rem;
}
.text-details {
    background: #F2F2F2;
    width: 197px;
    height: 36px;
    padding: 6px 0px;
    border-radius: 13px;
    margin-left: 2rem;
    font-size: 13px;
}
div#ReactSimpleImageViewer {
    background-color: #00000069;
}
.coach-about {
    border: 1px solid #00000014;
    width: 258px;
    height: 310px;
    text-align: center;
    border-radius: 9px;
    padding: 30px 3px;
}
.coach-details-about {
    display: flex;
    flex-wrap: wrap;
}
.text-details.coach-text {
    background: #f0e5b347;
    width: 159px;
    height: 33px;
    margin-left: 4rem;
}
/* .text-details.coach-text-blue {
    background: aliceblue;
    width: 156px;
    height: 33px;
    margin-left: 4rem;
} */

button.veiw-details {
    background: aliceblue;
    width: 93px;
    height: 30px;
    border: 0;
    color: #2F80ED;
    border-radius: 5px;
    font-size: 14px;
}
.coach-profile-details span {
    font-size: 17px;
}
.coach-info img {
    width: 24px;
}
.dashboard-auto-wrapper.dashboard-border {
    border: 1px solid #0000002b;
}
.coach-about.players-detail {
    width: 259px;
    height: 328px;
    padding: 23px 1px;
    margin-right: 14px;
}
span.verification {
    float: right;
    font-size: 11px;
    padding: 4px;
    border-radius: 10px 2px 2px 10px;
}
span.verification.orange-verify.color-orange {
    background: #faebd766;
}
span.verification.verify-coach.text-color-green {
    margin-right: -3rem;
    margin-top: -8rem;
}
.dashboard-top-btn.green-text-players {
    margin-top: -2rem;
}
.team-about:hover {
    border: 1px solid #f1582b47;
}

.coach-about:hover {
    border: 1px solid #f1582b47;
}
.remove img {
    cursor: pointer;
    width: 30px;
}
button.btn.btn-dark.green.white-orange-color.find-btn {
    width: 136px;
    height: 42px;
    border-radius: 7px;
}

.notqualiffied{
    position: relative;
}

.notqualiffied .remarks {
    visibility: hidden;
    width: 195px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -64px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .notqualiffied .remarks::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .notqualiffied:hover .remarks {
    visibility: visible;
    opacity: 1;
  }
  /* .coach-detail-img img {
    width: 94%;
    height: 96px;
    margin-left: -17px;
    border-radius: 12px;
    margin-right: 35px;
} */
.coach-detail-img {
    width: 40%;
}
.coach-detail-img img {
    width: 63%;
    border-radius: 8px;
}
.coach-about img {
    margin-left: 3.5rem;
    margin-bottom: 1rem;
    border-radius: 50%;
}
button.status-btn {
    background: #f2994a12;
    border: none;
    color: #F2994A;
    border-radius: 6px;
}
/* Active Button  */
button.active-btn {
    background: #2196531f;
    border: none;
    color: #219653;
    border-radius: 6px
}
/* enroll button with action */
button.enrol-btn {
    background: #2F80ED1A;
    border: none;
    color: #2f80edad;
    border-radius: 6px;
}
.team2 {
    width: 40%;
    text-align: center;
}
.vs {
    width: 20%;
    text-align: center;
}
.team1 {
    width: 40%;
    text-align: center;
}
.team1 img {
    width: 50%;
    border-radius: 50%;
}
.team2 img {
    width: 50%;
    border-radius: 50%;
}
.coach-profle img {
    border-radius: 50%;
}

/* ======================== costom Css Start here =================== */
.MuiTable-root .MuiTableCell-root{
    position: relative;
}
.new-table .dropdown a {
    background: #fff;
    display: block;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.10);
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.10);
}
.create--tournaments .nav-item{
    position: relative;
    align-items: center;
    display: flex;
}
.create--tournaments .nav-item:after{
    content: "";
    position: absolute;
    top: 2px;
    bottom: 2px;
    width: 2px;
    background-color: rgb(79 79 79 / 27%);
    left: 17px;
}
.create--tournaments .nav-item:first-child:after {
    top: 50%;
}
.create--tournaments .nav-item a{
    z-index: 1;
    position: relative;
    background-color: #c9c9c9;
    display: inline-block;
    margin-top: 0 !important;
    margin-right: 15px;
}
.create--tournaments .nav-item p{
    display: inline-block;
    width: calc(100% - 60px);
    float: right;
    margin: 0 !important;
    padding: 0 !important;

}
@media screen and (max-width:767px){
    h3.page-name {
        font-size: 18px !important;
    }
}
@media screen and (max-width:574px){
    .dashboard-search-input .input-icon input.form-control{
        width: 100%;
    }
    .dashboard-search-input{
        margin-top: 15px !important;
        justify-content: center;
    }
    .search-input-outer {
        width: 100%;
    }
    .search-input-outer {
        margin-bottom: 0;
    }  
    .dashboard-top-btn {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        align-items: center;
        flex-direction: column;
    }
}

.search-input-outer{
    margin-bottom: 1.5rem;
}
/* ================== Custom Css Ends here ================== */

.new-table .dropdown {
    opacity: 1 !important;
}

.test:after {
    content: '\2807';
    color: #2e2e2e
  }

  .drpdwn{
    border: none !important;
    color: white;
  }

  /* button.btn.btn-dark.green.white-orange-color.find-btn{
    margin-right: 21px;
  } */
  .enroll-team .search {
    width: 100%;
    position: relative;
    max-width: 534px;
}
.profile .profile-details{
    width: 100%;
    max-width: 373px;
    margin-bottom: 24px;

}
 .create-match .check-box-modal .custom-checkbox input[type="checkbox"]:checked + label:after {
    left: 9px;
    /* top: 29px; */
    width: 7px;
    height: 14px;
}
.check-box-modal label {
    font-size: 18px;
    font-family: unset;
    margin-left: 5px;
    color: #757575;
    font-weight: 500;
}

.calender-daily .active {
    background: transparent;
    border: none;
    border-radius: 6px;
}


span.custom-checkbox.parking-check label::before {
    background: transparent !important;
    border-color: #00000038 !important;
}

span.custom-checkbox.parking-check label::after {
    color: #298CEA;
    border-color: #298CEA !important;
}

p.text-success {
    font-size: 17px;
    color: #27ae60 !important;
    font-weight: 400;
    padding: 9px 0px 0px 0px;
    margin-bottom: 0;
}

p.text-danger {
    font-size: 18px;
    color: #f1372b !important;
    font-weight: 400;
    padding: 9px 0px 0px 0px;
    margin-bottom: 0;
}


.round_section table .border-none {
    border: none;
    border-bottom: 2px solid #eeee;
}

.fade.logout-pop.modal.show button.btn.btn-secondary {
    padding: 6px 48px;
}

.fade.logout-pop.modal.show button.btn.btn-primary {
    padding: 6px 48px;
    background: #f44336;
}

.fade.logout-pop.modal.show .modal-footer {
    border-top: 0px solid #fff !important;
    justify-content: center;
}
.fade.logout-pop.modal.show .modal-header {
    border-bottom: 1px solid #fff;
    justify-content: center;
}
.logut-content {
    text-align: center;
}
.fade.logout-pop.modal.show {
    top: 30%;
}