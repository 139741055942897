/* 26/12/2023 */
.fade.help_popup.modal.show {
    width: 100%;
    position: absolute;
    top: 60px;
    height: 100vh;
}

.organizer_events {
  width: 100%;
  position: relative;
  min-height: calc(100vh - 166px);
  padding: 25px;
}
  
  .organizer_events .popup {
    position: absolute;
    bottom: 30px;
    right: 0;
    z-index: 2;
}
  
  .organizer_events .popup button{
    background: transparent;
    
  }

  .organizer_events .popup button:hover{
    background: transparent;
  }
  
  .organizer_events .popup h4{
    color: #000;
    /* background: #fff;   */
  }
  
  
  .organizer_events .popup h4 {
    color: #F1582B;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
}
  .organizer_events .popup .button_background {
    color: #000;
    position: relative;
    /* background: #fff; */
    border-radius: 6px;
    padding: 10px 20px;
    top: -10px;
    right: 10px;
  }
  
  /* .organizer_events .popup .button_background::after {
    display: block;
    width: 0;
    content: "";
    border: 15px solid transparent;
  } */
  
  .organizer_events .popup .button_background::after {
    border-top-color: #fff;
    border-bottom: 0;
    position: absolute;
    bottom: -10px;
    right: 9px;
  }
  .organizer_events .popup .button_background .speech::after { position: absolute; }
  .organizer_events .popup .button_background .speech.down::after {
    bottom: -15px; left: calc(50% - 15px);
  }
  
  .video_part iframe {
    width: 100%;
    min-height: 280px;
  }
  
  /* .video_content .accordion-header button.accordion-button {
    background: transparent;
    display: flex;
    flex-direction: row-reverse;
    padding: 16px 0px;
    font-weight: 700;
    color: #000;
    margin-left: 15px;
    box-shadow: none;
  } */

  .video_content .accordion-header button.accordion-button {
    background: transparent;
    display: flex;
    flex-direction: row-reverse;
    padding: 16px 0px;
    font-weight: 600;
    color: #000;
    margin-left: 15px;
    box-shadow: none;
    font-size: 18px;
}
  
  .video_content .accordion-body ul li {
    text-align: justify;
    margin: 12px 0;
  }
  
  .video_content .accordion-button:not(.collapsed)::after {
    position: relative;
    left: -14px;
    box-shadow: none;
    transform: rotate(-0deg);
  }
  
  .video_content .accordion-button::after {
    transform: rotate(-90deg);
    position: relative;
    left: -14px;
    box-shadow: none;
  }
  
  .organizer_events .modal-content {
    transform: translateX(100%);
  }
  
  .organizer_events .footer_follow {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .organizer_events .footer_follow .phone {
    display: flex;
    align-items: center;
  }
  
  .footer_follow .phone a {
    margin-bottom: 0;
    font-size: 15px;
    margin-left: 15px;
    color: #000;
  }
  
  .help_popup .modal_footer .heading h4{
    background: #000;
    color: #fff;
    max-width: 275px;
    font-size: 18px;
    padding: 10px;
    border-radius: 6px;
  }
  
  .help_popup .modal_footer .heading {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .help_popup .modal-title {
    padding: 10px 0px 0 0;
    color: #F05829;
  }
  
  .help_popup .modal.show .modal-dialog {
    margin-top: 0;
  }
  
  .help_popup .modal-content {
    min-height: 100vh;
    border-radius: 0;
    top: 0;
    height: 100%;
}
  
.help_popup .video_content {
    width: 100%;
    height: calc(100vh - 610px);
    overflow-y: auto;
    overflow-x: hidden;
}
  
  .help_popup .video_content::-webkit-scrollbar {
    display: none;
  }
  
  @media (min-width:320px) and (max-width: 768px){
    .help_popup .modal-dialog {
      margin: 0;
    }
  }
  
  
  .footer_follow {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .help_popup .modal-dialog {
    width: 100%;
    max-width: 520px !important;
    margin-top: -1px !important;
    margin-bottom: 0;
    position: absolute;
    right: 0;
}
  
  .organizer_events .footer_follow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .fade.modal-backdrop {
    top: 60px;
    height: calc(100vh - 56px);
}

.organizer_events .popup .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
}

.button_background h4 span {
  margin-right: 11px;
}

.breadcrumb .popup button.btn.btn-primary {
  background: transparent;
  color: #F1582B;
}

.breadcrumb .popup .button_background h4 {
  font-size: 16px;
  margin-bottom: 0;
}

.breadcrumb .popup button.btn.btn-primary {
  background: transparent;
  color: #F1582B;
  padding: 0;
  margin: 0;
}

.breadcrumb .popup {
  position: absolute;
  right: 8px;
}

.button_background {
  display: flex;
  gap: 10px;
}

.fade.help_popup.modal .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: calc(100vh - 137px);
}