body {
  font-family: "Roboto", sans-serif !important;
  background-color: #f7f7f7 !important;
  height: unset !important;

}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf");
}



a {
  text-decoration: none !important;
}

strong,
p {
  color: #626262;
}

img {
  max-width: 100%;
}

button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: table;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999999;
}

.loader {
  display: table-cell;
  align-items: center;
  vertical-align: middle;
  text-align: center;
}

.loader img {
  width: 70px;
}

/* The custom-check */
.custom-check {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #828282;
}

/* Hide the browser's default checkbox */
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  background-color: #fff;
  border: 2px solid #d7d7d7;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.custom-check:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-check input:checked~.checkmark {
  background-color: transparent;
  border: 1px solid #0000003b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-check input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-check .checkmark:after {
  /* left: 9px;
  top: 5px; */
  left: 5px;
  top: 1px;
  width: 7px;
  height: 12px;
  border: solid #298ceb;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*The custom-radio */
.custom-radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #757575;
  font-size: 14px;
}

/* Hide the browser's default checkbox */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Show the checkmark when checked */
.custom-radio input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-radio .checkmark:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
  position: static;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-radio input:checked~.checkmark {
  background-color: transparent;
  border-color: #000;
}

.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  background-color: transparent;
  border: 2px solid #ccc;
  border-radius: 50%;
  padding: 3px;
}

h3.page-name {
  float: left;
  margin-left: 20px;
  font-weight: 600;
  font-size: 25px;
  margin-top: 9px;
}

.small-logo {
  display: none;
}

.nav-sm .large-logo {
  display: none;
}

.nav-sm .small-logo {
  display: block;
}

.blue-txt {
  color: #e77469;
}

.dashboard-logo {
  font-weight: 600;
  font-size: 22px;
  padding: 0;
  float: left;

}

.main-menu .dropdown:hover>.dropdown-menu {
  display: block;
  /* top: 44px; */
  border-radius: 0;
  height: 100%;
  min-height: 554px;
}

.dropdown>.dropdown-toggle:active {
  pointer-events: none;
}

.nav-sm .dashboard-logo {
  font-size: 0;
  width: 90px;
}

.hover_none:hover {
  background: transparent !important;
  cursor: default !important;
}

.nav-sm .navigation-bar h4 {
  font-size: 15px;
  padding-left: 10px;
}

.nav-sm .side-nav {
  width: 80px;
  padding-left: 10px;
}

.nav-sm .navigation-bar>ul>li:hover>a {
  width: 260px;
}

.nav-sm .navigation-bar>ul>li:hover a span.menu-title {
  display: inline-block;
}

.nav-sm .navigation-bar span.menu-title {
  display: none;
  width: 136px;
}

.nav-sm .navigation-bar>ul>li:hover a span.open-menu {
  display: inline-block;
}

.nav-sm .navigation-bar ul ul.sub-menu {
  display: none;
}

.nav-sm .navigation-bar>ul>li:hover ul.sub-menu {
  display: block;
  position: absolute;
  background: #fff;
  top: 52px;
  margin-top: 0;
  width: 190px;
  left: 70px;
  padding-left: 0;
  box-shadow: 6px 8px 25px -4px rgb(0 0 0 / 10%);
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-sm .navigation-bar span.open-menu {
  display: none;
}

.nav-sm .navigation-bar h4 span {
  display: none;
}

.nav-sm .top-nav {
  padding-left: 20px;
}

.nav-sm .dashboard-inner {
  margin-left: 80px;
  width: calc(100% - 80px);
}

.dashboard-logo a {
  color: #d9ceb4;
}

.navigation-bar .dropdown-menu {
  position: static !important;
  transform: translate(0px, 0px) !important;
  border-width: 0;
  padding-left: 25px;
}

.side-nav {
  width: 300px;
  padding-left: 20px;
  position: fixed;
  left: 0 !important;
  top: 80px !important;
  height: calc(100% - 83px);
  background: #fff;
  box-shadow: 0 15px 24px -8px rgb(0 0 0 / 20%);
  padding-top: 20px;
  z-index: 9999;
  overflow: auto;
}

.navigation-bar h4 {
  font-size: 18px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.navigation-bar ul {
  padding: 0;
  margin-top: 20px;
}

.navigation-bar ul li {
  list-style: none;
  margin-bottom: 10px;
  position: relative;
}

.navigation-bar .dropdown-toggle.btn:after {
  display: none;
}

.navigation-bar ul li a,
.navigation-bar .dropdown-toggle.btn {
  display: inline-block;
  width: 100%;
  padding: 10px 10px 10px 20px;
  font-size: 15px;
  color: #757575;
  border-right: 5px solid transparent;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navigation-bar ul li a i {
  margin-right: 10px;
  font-size: 17px;
}

.navigation-bar ul li a.active i,
.navigation-bar>ul>li>a.active i {
  color: #fff;
}

.navigation-bar>ul>li>a.active,
.navigation-bar>ul>li>a.active:hover,
.navigation-bar>li>a.active,
.navigation-bar>li>a.active:hover {
  background: #282041eb !important;
  border-radius: 20px 0px 0px 20px;
  border-right: 5px solid #282041 !important;
  color: #fff;
}

.navigation-bar>ul>li:has(a.active)>a {
  background: #282041eb !important;
  border-radius: 20px 0px 0px 20px;
  border-right: 5px solid #282041 !important;
  color: #fff;
}

.navigation-bar>ul>li:has(a.active)>a svg {
  color: #fff;
}

.navigation-bar>ul>li:has(a.active) .sub-menu {
  display: block;
}

.navigation-bar ul.sub-menu li a.active {
  border-width: 0;
  background: transparent;
}

.navigation-bar>ul>li:hover>a,
.navigation-bar .dropdown-toggle.btn:hover {
  background: whitesmoke;
  border-radius: 20px 0px 0px 20px;
  border-right: 5px solid #e7e7e7;
}

.navigation-bar ul li.has-sub a {
  position: relative;
}

.navigation-bar .dropdown-menu a {
  background-color: transparent;
  padding: 5px 10px 5px 20px;
  font-size: 14px;
}

.navigation-bar .dropdown-menu a+a {
  margin-top: 10px;
}

.navigation-bar ul ul.sub-menu a {
  padding: 5px 10px 5px 20px;
  font-size: 14px;
}

.navigation-bar ul ul.sub-menu a:hover {
  color: #000;
}

.navigation-bar ul ul.sub-menu {
  padding-left: 24px;
}

.navigation-bar ul ul.sub-menu a.active {
  color: #000;
  font-weight: 500;
}

.sub-menu {
  display: none;
}

.sub-menu.show {
  display: block;
}

.open-menu {
  position: absolute;
  right: 10px;
  color: #757575;
  top: 21px;
}

.top-nav {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0px 30px 0px;
  background: #fff;
  z-index: 99999;
}

.top-nav .inner-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-nav .container-fluid {
  max-width: 1400px;
  width: 100%;
}

.main-menu>ul li a {
  color: #000;
  font-size: 15px;
}

.main-menu>ul li span {
  margin-left: 6px;
}

.main-menu>ul li {
  margin: 0 20px;
  height: 45px;
  display: flex;
  align-items: center;
}

.main-menu>ul {
  display: flex;
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
}

.search-close {
  display: none;
}

.toggle-menu {
  float: left;
  padding: 0;
  background: transparent;
  border-width: 0;
}

.dashboard-title {
  float: left;
  margin-left: 40px;
  font-size: 22px;
  padding-top: 10px;
  font-weight: 600;
}

.nav-search {
  float: left;
  width: 350px;
  margin-left: 40px;
  position: relative;
  background: #f3f3f3;
  border-radius: 40px;
}

.search-open-btn {
  display: none;
}

.search-inner {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 5px;
}

.nav-search input {
  background: transparent;
  border-width: 0;
  padding-left: 10px;
  width: calc(100% - 35px);
  outline: unset;
}

.search-form {
  display: flex;
}

.nav-search-btn {
  background: linear-gradient(122.47deg, #e77469 13.1%, #fec072 80.56%);
  color: #fff;
  border-width: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.nav-search-btn:hover {
  background: linear-gradient(122.47deg, #fec072 13.1%, #e77469 80.56%);
}

.profile-info img {
  width: 40px;
  height: 40px;
  float: left;
  border-radius: 50%;
  margin-top: 9px;
  object-fit: cover;
  object-position: 50% 50%;
}


.profile-info {
  margin-left: 2.5rem;
  margin-top: -6px;
  position: relative;
}

.profile-tooltip {
  position: absolute;
  top: 71px;
  background: #626262;
  border-radius: 15px;
  width: 200px;
  box-shadow: 0px 3px 11px 3px rgb(211 214 228 / 25%);
  padding: 10px 0;
  display: none;
  right: 0;
}

.profile-tooltip.show {
  display: block;
}

.profile-info-box {
  cursor: pointer;
  display: inline-block;
}

.profile-tooltip a {
  display: inline-block;
  width: 100%;
  padding: 12px 15px;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  font-weight: 300;
  font-size: 14px;
}

.profile-tooltip a i {
  margin-right: 5px;
}

.profile-tooltip a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.profile-tooltip a:last-child {
  border-bottom: 0px solid #f1f1f1;
}

.profile-tooltip a img {
  width: 20px;
  margin-right: 10px;
  border-radius: 0;
}

.profile-name h5 {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.profile-name span {
  color: #8f8f8f;
  font-size: 13px;
}

.profile-name {
  float: left;
  padding: 10px 10px 0;
}

.profile-icon {
  color: #d3d6e4;
  display: inline-block;
  margin-top: 13px;
  font-size: 20px;
}

.notification-icon {
  width: 42px;
  height: 42px;
  border-radius: 12px;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-icon img {
  width: 81%;
}

.message-notificaion {
  display: inline-block;
  margin-left: 33px;
  margin-top: -6px;
  position: relative;
}

.left-menu-content {
  display: flex;
  align-items: center;
}

.notification-icon span {
  width: 15px;
  height: 15px;
  position: absolute;
  display: inline-block;
  background: #f05829;
  border-radius: 50%;
  border: 3px solid #fbfbfb;
  top: -4px;
  right: 0;
}


.dashboard-inner {
  width: calc(100% - 300px);
  margin: 90px 0 0 300px;
  padding: 20px;
}

.dashboard-top-btn {
  display: flex;
  justify-content: flex-end;
}

.dashboard-inner .btn {
  border-radius: 7px;
}

.dashboard-top-btn .btn {
  padding: 10px 15px;
}

.dashboard-auto-wrapper {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
}

.filter-box button.btn.btn-dark {
  border-radius: 50%;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.wrapper-head {
  display: flex;
  justify-content: space-between;
}

button.btn.filter-btn img {
  width: 20px;
  margin-right: 5px;
}

button.btn.filter-btn {
  color: #757575;
  font-weight: 500;
}

.wrapper-head h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.follower-count-box {
  text-align: center;
}

span.follower-icon {
  display: flex;
  width: 80px;
  height: 80px;
  background: #e6e6e6;
  border-radius: 50%;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 15px;
}

.follower-count-box h4 {
  font-weight: 500;
  margin-bottom: 5px;
}

.follower-count-box p {
  color: rgba(0, 0, 0, 0.6);
}

.dash-notification-box {
  padding: 10px 0;
  display: flex;
}

.notification-user-img img {
  width: 30px;
  border-radius: 50%;
}

.notification-con {
  width: 100%;
}

.noti-user-name h5 {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.notification-con p {
  font-size: 15px;
  color: #979797;
  margin: 0;
}

.common-table thead tr th {
  background: #f05829;
  color: #fff;
  font-weight: 500;
  padding: 12px 15px 12px 20px;
}

.common-table thead tr th:last-child {
  border-top-right-radius: 10px;
}

.common-table thead tr th:first-child {
  border-top-left-radius: 10px;
}

.common-table .table td {
  background-color: #fafafa;
  border-top: 10px solid #fff;
  padding: 8px 15px 0px 15px;
  color: #757575;
}

.common-table .white-td-table td {
  background: #fff;
  border-top: 10px solid #fafafa;
}

.table-tab-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-tab-filter .dashboard-top-btn {
  margin: 0;
}

.table-tabs ul#pills-tab {
  border-bottom: 1px solid rgba(0, 0, 0, 0.18);
}

.table-tabs ul#pills-tab .nav-link,
#common-tabs-section .nav-link {
  padding: 10px 23px;
  border-width: 0;
  border-bottom: 1px solid #dee2e6;
  color: #000 !important;
}

#common-tabs-section .nav.nav-tabs {
  border-bottom: 0;
}

.table-tabs .nav-pills .nav-link,
#common-tabs-section .nav-link {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  font-size: 20px;
}

.table-tabs .nav-pills .nav-link.active,
.table-tabs .nav-pills .show>.nav-link,
#common-tabs-section .nav-link.active {
  font-weight: 700;
  border-width: 0;
  border-bottom: 3px solid;
  border-radius: 0;
  border-color: #F05829;
  background: transparent;
  color: #F05829 !important;
}

.common-table td:first-child {
  position: relative;
}

.common-table td:first-child:before {
  content: "";
  border-left: 4px solid #282041;
  height: 87%;
  position: absolute;
  left: 0px;
  top: 5px;
}

td.table-user img {
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

td.table-user {
  font-weight: 500;
}

.comunication-form .form-control {
  background: rgba(230, 230, 230, 0.6);
  border-radius: 10px;
  height: 50px;
  border-width: 0;
}

.comunication-form .btn {
  height: 50px;
  width: 140px;
}

.comunication-form form {
  display: flex;
  column-gap: 20px;
}

.wrapper-head span {
  color: #929292;
  font-size: 14px;
}

.planner-revenue-graph img {
  width: 100%;
}

.planner-revenue-graph {
  margin-top: 15px;
}

.register-graph-head span.stats-name {
  font-size: 12px;
  margin-right: 15px;
}

.available-check {
  position: absolute;
  right: 5px;
  top: 15px;
}

.custom-check.available-check input:checked~.checkmark {
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  height: 20px;
  width: 20px;
}

.custom-check.available-check .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid #e77469;
  border-width: 0 2px 2px 0;
}

.card-icon {
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 50%;
  font-size: 45px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.card-success .card-icon {
  color: #e77469;
  background: linear-gradient(0deg,
      rgba(231, 116, 105, 0.1),
      rgba(231, 116, 105, 0.1)),
    #ffffff;
}

.card-default .card-icon {
  background: rgba(217, 206, 180, 0.2);
  color: #d9ceb4;
}

.card-icon img {
  display: inline-block;
  margin: 0 auto;
}

.card-icon i {
  flex: 0 0 100%;
}

.dashboard-card {
  padding: 10px 30px 30px;
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: center;
}

.card-text {
  margin-top: 15px;
}

.card-text label {
  color: #969ba0;
  margin-bottom: 10px;
}

.card-text h3 {
  font-weight: 700;
}

.card-progress {
  display: inline-block;
  width: calc(100% + 20px);
  height: 7px;
  background: #e7e7e7;
  position: absolute;
  left: -10px;
  bottom: -26px;
  border-radius: 10px;
}

.card-progress:before {
  content: "";
  background: #e77469;
  position: absolute;
  width: 200px;
  height: 7px;
  border-radius: 10px;
  left: 0;
}

.card-default .card-progress:before {
  content: "";
  background: #fec072;
  position: absolute;
  width: 200px;
  height: 7px;
  border-radius: 10px;
}

.page-head h3 {
  font-weight: 600;
  font-size: 22px;
}

.page-head p {
  margin: 0;
}

.page-head {
  margin-bottom: 30px;
}

.report-table th {
  color: #e77469;
  font-size: 18px;
  font-weight: 500;
  padding: 18px 10px;
  border-bottom: 1px solid #efefef !important;
}

.report-table td {
  padding: 18px 10px;
  color: #757575;
  border-bottom: 1px solid #efefef !important;
}

.report-table td a {
  text-decoration: underline;
}

.report-table tr:last-child td {
  border-bottom: 0px solid #efefef !important;
}

.head-filters {
  display: inline-block;
  width: 100%;
  margin-top: -5px;
}

.filter-date-range {
  width: auto;
  float: right;
  margin: 0 20px;
  min-width: 300px;
}

#reportrange {
  background: transparent;
  cursor: pointer;
  padding: 8px 10px;
  border: 2px solid #e77469;
  width: 100%;
  border-radius: 7px;
  font-size: 15px;
}

#reportrange i.fas.fa-calendar-alt {
  color: #e77469;
}

#reportrange i.fas.fa-angle-down {
  float: right;
  font-size: 18px;
  margin-top: 2px;
  margin-left: 20px;
}

.next-previous-btns {
  float: right;
  background: #fff;
  border: 1px solid #d3d6e4;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 4px;
}

.next-previous-btns a {
  display: inline-block;
  color: #d3d6e4;
  padding: 4px;
  width: 40px;
  text-align: center;
}

.next-previous-btns a:first-child {
  border-right: 1px solid #d3d6e4;
}

.notification-pop {
  position: absolute !important;
  background: #fff;
  width: 430px !important;
  right: 0;
  box-shadow: 0px 4px 4px 3px rgb(0 0 0 / 4%);
  border-radius: 20px;
  padding: 5px 25px 25px;
  top: 70px !important;
  display: none;
  max-height: 70vh;
  overflow: auto;
  border: 1px solid #ddd;
  left: unset !important;
}

.notification-title {
  font-size: 19px;
  margin-top: 20px;
}

.notificaion-box {
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  display: inline-block;
  width: 100%;
}

.notificaion-box img {
  width: 37px;
  border-radius: 10px;
  float: left;
}

.notification-con {
  width: calc(100% - 65px);
  float: left;
  padding-left: 15px;
}

.notification-con p {
  color: #000;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 0px;
  font-size: 14px;
}

.notifaction-date-time {
  color: #969ba0;
  font-size: 14px;
}

.notifaction-date-time span {
  min-width: 80px;
  display: inline-block;
  padding-right: 10px;
}

.list-view-btn {
  float: right;
}

.list-view-btn a {
  display: inline-block;
  border: 2px solid #e77469;
  color: #e77469;
  padding: 5px 12px;
  border-radius: 7px;
  font-size: 20px;
}

.list-view-btn a:hover {
  background: #e77469;
  color: #fff;
}

.couple-clr,
.planner-clr {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #e77469;
  border-radius: 50%;
}

.planner-clr {
  background: #fec072;
}

span.stats-days {
  font-size: 14px;
  margin-left: 20px;
  display: inline-block;
  line-height: 1;
}

span.stats-days.active span {
  background: #e77469;
}

span.stats-days span {
  width: 14px;
  height: 14px;
  float: left;
  margin-right: 5px;
  background: #c4c4c4;
}

.graph-filter {
  text-align: right;
}

.table-members {
  display: flex;
  justify-content: flex-end;
}

.table-members ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.table-members ul li:first-child {
  margin: 0;
}

.table-members ul li {
  display: inline-block;
  width: 23px;
  margin-left: -10px;
  border: 1px solid #e8736a;
  border-radius: 50%;
  height: 23px;
}

.table-members p {
  background: linear-gradient(122.47deg, #e77469 13.1%, #fec072 80.56%);
  margin: 0 0 0 5px;
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 9px;
  text-align: center;
  padding-top: 5px;
}

.table-sec th {
  font-size: 16px;
  font-weight: 500;
  color: #757575;
  border-bottom: 1px solid #dddddd !important;
  padding: 15px 10px 15px 0;
}

.table-sec td {
  font-size: 16px;
  font-weight: normal;
  vertical-align: middle;
}

td.table-profile-img img {
  border: 1px solid #e8736a;
  border-radius: 50%;
}

.text-right {
  text-align: right;
}

span.menu-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(../images/menu-icon.png);
}

.navigation-bar ul li .menu-title {
  margin-left: 10px;
}

.dashboard-icon {
  background-position: -8px -13px;
}

.current-page .dashboard-icon,
.active .dashboard-icon {
  background-position: -8px -60px;
}

.promotion-icon {
  background-position: -47px -11px;
}

.current-page .promotion-icon,
.active .promotion-icon,
.navigation-bar>ul>li:has(a.active)>a .promotion-icon {
  background-position: -47px -57px;
}

.request-icon {
  background-position: -202px -12px;
}

.current-page .request-icon,
.active .request-icon {
  background-position: -202px -60px;
}

.communication-icon {
  background-position: -124px -15px;
}

.current-page .communication-icon,
.active .communication-icon {
  background-position: -124px -60px;
}

.user-icon {
  background-position: -337px -12px;
}

.current-page .communication-icon,
.active .user-icon {
  background-position: -337px -12px;
}

.chat-head {
  background: #00acc0;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 15px;
  position: relative;
}

.chat-head h5 {
  margin: 0;
  font-weight: 500;
}

.btn-ligt-blue {
  background: #80d5df;
  color: #fff;
}

.chat-list-head {
  padding: 0 0 25px;
  display: inline-block;
  width: 100%;
  position: relative;
  display: flex;
}

.chat-list-head button {
  float: left;
  background: transparent;
}

.chat-list-head button:hover {
  background: transparent;
}

.chat-list-title {
  float: left;
  width: calc(100% - 50px);
  text-align: center;
}

.chat-list-title p {
  font-size: 12px;
  margin: 0;
}

.chat-list-title h6 {
  margin: 0;
  font-size: 15px;
}

.chat-search {
  background: transparent;
  border-radius: 40px;
  height: 56px;
  border: 1px solid #ddd;
  position: relative;
  width: 100%;
}

.chat-search-inner {
  display: flex;
  position: relative;
  padding: 4px 10px;
  height: 100%;
  align-items: center;
}

.chat-search.active .chat-search-inner {
  display: block;
}

.chat-search.active .search-btn {
  display: none;
}

#chat-area {
  display: flex;
}

.search-btn {
  padding: 7px;
  height: 35px;
  font-size: 14px;
  width: 35px;
  text-align: center;
  display: block;
  cursor: pointer;
}

.chat-search.active {
  top: 0;
  height: 100%;
  width: calc(100% - 30px);
  padding-top: 18px;
  background: #fff;
}

.chat-search-inner input {
  background: transparent;
  border-width: 0;
  font-size: 14px;
  width: 100%;
  padding-left: 7px;
  outline: none;
  padding-right: 30px;
}

.chat-search-inner i {
  position: static;
  right: 8px;
  top: 9px;
  opacity: 0.5;
}

.chat-name-alphabet {
  padding-left: 20px;
  font-size: 18px;
  margin-top: 10px;
}

.chat-contact-box {
  padding: 15px 0px;
  display: flex;
  align-items: flex-start;
}

.chat-title {
  color: #8d8d8d;
}

.chat-contact-box:last-child {
  border-width: 0;
}

.chat-contact-box a.show-single-chat {
  display: flex;
  width: 100%;
  color: #212529;
  text-decoration: none;
}

.cperson-img {
  margin-right: 10px;
}

.cperson-img img {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.cperson-detail h4 {
  font-size: 22px;
  margin: 0 0 5px;
}

.cperson-detail p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1.2;
  margin: 0;
}

.cperson-img span {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid #fff;
  display: inline-block;
  position: absolute;
  bottom: 1px;
  right: 0px;
}

.cp-active {
  background: #00ff29;
}

.cp-inactive {
  background: #ff0000;
}

.cperson-img img {
  border-radius: 50%;
}

.chat-scroll {
  height: calc(100vh - 270px);
  overflow: auto;
}

#chat-single {
  width: calc(100% - 400px);
}

.chat-action {
  position: absolute;
  right: 30px;
  top: 50px;
}

.chat-action-btn {
  width: 35px;
  height: 35px;
  border-radius: 6px;
  text-align: center;
  padding-top: 7px;
}

.green-txt {
  color: #00ff29;
}

.chat-messages-list {
  height: calc(100vh - 230px);
  overflow: auto;
  margin-top: -8px;
  position: relative;
}

.chat-message-sec {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 15px 20px;
  background: #fff;
  z-index: 9;
}

.chat-message-sec .btn {
  width: auto;
  height: auto;
  border-radius: 5px;
  padding: 14px 15px;
  border-width: 0;
  margin-top: 4px;
  background: linear-gradient(122.47deg, #e77469 13.1%, #fec072 80.56%);
  color: #fff;
  margin-left: 15px;
}

.chat-message-sec .btn:hover {
  background: linear-gradient(122.47deg, #fec072 13.1%, #e77469 80.56%);
}

.chat-message-sec i {
  margin-left: 15px;
}

.chat-message-sec textarea {
  border-width: 0;
  width: calc(100% - 117px);
  float: left;
  outline: none;
  height: 51px;
  resize: none;
  margin-top: 5px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 13px;
}

.chat-list {
  height: calc(100% - 53px);
  width: 400px;
}

.dashboard-auto-wrapper.chat-wrapper {
  background: #fff9f1;
  padding: 0;
}

#cmember-list {
  padding: 30px;
  background: #fff;
}

.single-chat-messages {
  padding: 20px;
  position: absolute;
  bottom: 65px;
  max-height: calc(100% - 100px);
}

.admin-chat img {
  float: right;
  width: 65px;
}

.admin-chat {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
}

.message-box {
  float: right;
  margin-right: 15px;
  border-radius: 8px 0px 8px 8px;
  padding: 10px;
  width: 400px;
  position: relative;
}

.message-gray {
  background: rgb(255 255 255);
}

.message-box p {
  font-size: 17px;
  margin: 0;
}

.message-gray p {
  color: #757575;
}

.message-gray span {
  color: rgba(117, 117, 117, 0.8);
}

.message-box span {
  font-size: 13px;
}

.member-chat img {
  float: left;
  width: 65px;
}

.message-box.message-blue {
  background: #e77469;
  color: #fff;
  float: left;
  border-radius: 0px 8px 8px 8px;
  margin-right: 0;
  margin-left: 15px;
}

.message-blue p {
  color: #fff;
}

.member-chat {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
}

.message-box.message-blue:before {
  content: "";
  position: absolute;
  border-top: 0px solid;
  border-right: 17px solid #e77469;
  border-bottom: 10px solid transparent;
  left: -17px;
  top: 0;
}

.message-box.message-gray:before {
  content: "";
  position: absolute;
  border-top: 0px solid;
  border-left: 17px solid rgba(217, 206, 180, 0.3);
  border-bottom: 10px solid transparent;
  right: -17px;
  top: 0;
}

#chat-single .chat-list-head {
  padding: 20px 30px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#chat-single .chat-list-head .chat-contact-box {
  align-items: center;
}

#chat-single .chat-list-head .chat-contact-box p {
  margin-top: 5px;
}

.message-search {
  float: left;
  padding-right: 20px;
}

.message-search input {
  display: none;
}

.show-msg-search {
  margin-top: 5px;
  display: inline-block;
  cursor: pointer;
}

.message-search input {
  display: none;
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 40px;
  width: 250px;
  margin-top: -6px;
  background: transparent;
  outline: unset;
}

.msg-action {
  position: absolute;
  top: 0;
}

.msg-action {
  position: absolute;
  top: 0;
  left: -12px;
}

.message-box.message-blue .msg-action {
  right: -12px;
  left: auto;
}

.msg-action-icon {
  color: rgba(117, 117, 117, 0.8);
  cursor: pointer;
}

.msg-mem-action {
  display: inline-block;
  position: relative;
}

.action-tooltip {
  position: absolute;
  background: #fff;
  width: 100px;
  right: 25px;
  top: 0;
  padding: 5px 0;
  border-radius: 5px;
  z-index: 99;
  display: none;
}

.action-tooltip a {
  display: inline-block;
  padding: 8px 10px;
  color: inherit;
  width: 100%;
}

.action-tooltip a:hover {
  background-color: rgba(217, 206, 180, 0.3);
}

.action-tooltip:before {
  content: "";
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 12px solid rgba(255, 255, 255, 1);
  right: -11px;
  top: 3px;
}

.notificaion-inner {
  position: relative;
}

.unread-noti-btn {
  color: #e77469;
  text-decoration: underline;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
}

.unread-noti-btn:hover {
  text-decoration: none;
  color: #e77469;
}

.table-top-btns {
  justify-content: right;
}

.dashboard-btns {
  display: flex;
}

.dashboard-btns .btn {
  padding: 13px 20px;
  width: 150px;
  border-radius: 10px;
}

.table-user img {
  width: 40px;
  margin-right: 5px;
}

.dashboard-table.table-sec td {
  font-size: 14px;
}

.dashboard-table.table-sec th {
  font-weight: 500;
}

.table-actions img {
  max-height: 16px;
}

.table-actions a {
  display: inline-block;
  margin-left: 8px;
}

.table-actions a:hover {
  opacity: 0.7;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
  max-width: 50px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  top: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked+.slider {
  background-color: #e77469;
}

.switch input:focus+.slider {
  box-shadow: 0 0 1px #e77469;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.table-filter {
  margin-right: 20px;
}

.dashboard-btns button.btn.btn-tansparent {
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: #e77469;
  background: transparent;
}

.dashboard-btns button.btn.btn-tansparent:hover {
  border: 1px solid #e77469;
  color: #fff;
  background-color: #e77469;
}

.custom-modal.show {
  display: table;
}

.custom-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: none;
  left: 0;
  top: 0;
  z-index: 99999;
}

.custom-pop-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.popup-large .modal-dialog .custom-pop-box {
  width: 900px;
}

.coad-d {
  max-width: 300px;
  width: 100%;
}

.custom-pop-box {
  background: #fff;
  padding: 8px 0;
  border-radius: 12px;
  margin: 0 auto;
}

.enroll-wrap {
  height: 69vh;
  overflow: auto;
}

.popup-medium .modal-dialog {
  max-width: 95%;
  width: 700px;
}

.popup-large .modal-dialog {
  max-width: 95%;
  width: 900px;
}

.modal-backdrop {
  z-index: 99999;
}

.modal {
  z-index: 999999;
}

.modal-open .modal {
  width: 100%;
  /* max-width: calc(100% - 300px);
  margin-left: 300px; */
}

.pop-title {
  font-size: 24px;
  margin-bottom: 5px;
}

button.btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  box-shadow: unset !important;
  outline: unset;
}

button.btn.btn-long {
  width: 200px;
  height: 50px;
  border-radius: 10px;
}

.pop-form label {
  color: #757575;
  font-size: 16px;
  width: 100%;
}

.pop-form textarea.form-control {
  min-height: 80px;
}

.msgarea-msg {
  text-align: right;
  font-size: 12px;
  margin-top: 5px;
}

.pop-form label.switch {
  width: 50px;
}

.pop-form .form-control {
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 6px;
  height: 52px;
}

/* .form-sec .form-control {
  border: 1px solid rgba(0, 0, 0, 0.28);
  box-sizing: border-box;
  border-radius: 12px;
  height: 72px !important;
  appearance: none;
  color: #828282;
  font-size: 17px;
} */

.form-sec.edit-profile-sec.form-sec .form-control {
  box-sizing: border-box;
  height: 46px !important;
  appearance: none;
  color: #828282;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
}

.form-sec label {
  margin-bottom: 5px;
  width: 100%;
}

.dashboard-btns .btn:first-child {
  margin-right: 20px;
}

.uploaded-img {
  width: 150px;
  margin-top: 10px;
  max-height: 150px;
  overflow: hidden;
}

.uploaded-img img {
  border-radius: 15px;
}

.file-field span {
  background: #e77469;
  border-radius: 0px 12px 12px 0px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  color: #fff;
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-field {
  position: relative;
}

.file-field input[type="file"] {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  cursor: pointer;
  opacity: 0;
}

select.form-control {
  background-image: url(../images/select-icon.svg);
  background-repeat: no-repeat;
  background-position: 95% center;
}

.table-filter button.btn.btn-tansparent {
  margin: 0;
}

.common-message-pop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: none;
  left: 0;
  top: 0;
  z-index: 9999999;
}

.common-message-pop.show {
  display: table;
}

.common-pop-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.common-pop-box {
  width: 500px;
  background: #fff;
  text-align: center;
  padding: 30px;
  border-radius: 20px;
  margin: 0 auto;
}

.error-icon {
  color: #ff8585;
  font-size: 100px;
}

.success-icon {
  color: #35b32d;
  font-size: 100px;
}

.message-pop-button {
  margin-top: 30px;
}

.message-pop-button button {
  padding: 10px 20px;
}

.warning-icon {
  color: #f8bb86;
  font-size: 100px;
}

.message-pop-button button.btn.btn-default {
  background-color: #c1c1c1;
  color: #fff;
  border-color: #c1c1c1;
}

.message-title.success-title {
  color: #39d78d;
  text-transform: uppercase;
  font-weight: 900;
  margin: 20px 0;
}

.plan-pop {
  position: absolute;
  background: #fff;
  width: 800px;
  right: 0;
  box-shadow: 0px 4px 4px 3px rgb(0 0 0 / 4%);
  border-radius: 20px;
  padding: 5px 25px 25px;
  top: 70px;
  display: none;
  max-height: 70vh;
  overflow: auto;
  border: 1px solid #ddd;
}

.plan-pop-inner {
  position: relative;
}

.plan-title {
  background: #e77469;
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 15px 15px 0 0;
}

.plan-price {
  text-align: center;
  margin: 10px 0;
}

.plan-tag {
  display: block;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.plan-tag.red-tag {
  color: #e77469;
  background: linear-gradient(0deg,
      rgba(231, 116, 105, 0.1),
      rgba(231, 116, 105, 0.1)),
    #ffffff;
}

.red {
  color: #FF1E10;
}

.plan-desc {
  color: #969ba0;
  font-size: 15px;
  text-align: center;
  padding: 5px 20px 15px;
  line-height: 18px;
}

.plan-tag.orange-tag {
  background: rgba(254, 192, 114, 0.2);
  color: #fec072;
}

.plan-tag.green-tag {
  background: rgba(57, 215, 141, 0.2);
  color: #39d78d;
}

/* #filter-pop {
  position: fixed;
  right: -500px;
  top: 0;
  width: 500px;
  height: 100%;
  background: #fff;
  z-index: 99999;
  transition: 0.4s;
  padding: 40px 40px 40px;
} */

#filter-pop {
  position: fixed;
  right: -500px;
  top: 57px;
  width: 500px;
  height: calc(100vh - 109px);
  background: #fff;
  z-index: 99999;
  transition: 0.4s;
  padding: 40px 40px 40px;
}

.filter_button_nav {
  margin-right: 10px;
}

button.btn.btn-default.filter-btn {
  max-height: 43px;
  height: 100%;
}

.table-filter.filter_button_nav {
  margin-right: 10px;
}

.filter-form-btns {
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 30px;
}

.filter-pop-inner {
  position: relative;
  min-height: 450px;
  height: 100%;
}

#filter-pop.show {
  right: 0;
}

.filter-head h4 {
  border-bottom: 2px solid;
  font-size: 22px;
  padding-bottom: 5px;
  padding-right: 5px;
  margin-top: 30px;
}

.popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
}

.filter-head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: flex-start;
}

.filter-body .form-control {
  background: #f3f3f3;
  border-color: #f3f3f3;
  height: 56px;
  border-radius: 20px;
}

.input-icon {
  position: relative;
}

.input-icon input {
  padding-left: 30px;
}

.input-icon img {
  position: absolute;
  right: 10px;
  top: 23px;
}

.input-icon i,
.input-icon .input-icon-alt {
  position: absolute;
  left: 15px;
  top: 14px;
  color: #b6b6b6;
}

.filter-form-btns button.btn {
  border-radius: 20px;
  height: 60px;
}

.times-btn {
  background: transparent;
  border-width: 0;
  padding: 0;
  font-size: 27px;
  color: #000;
}

.times-btn:hover {
  opacity: 0.8;
}

.modal-content {
  border-radius: 20px;
}

.table>tbody tr:hover {
  background: rgba(231, 116, 105, 0.1);
}

.dashboard-auto-wrapper.profile-wrapper {
  padding: 0px;
}

.profile-cover img {
  width: 100%;
}

.profile-header {
  padding: 0 40px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  position: relative;
}

.profile-img img {
  border: 10px solid #fff;
  border-radius: 50%;
}

.profile-img {
  border-radius: 50%;
  margin-top: -145px;
  margin-bottom: 20px;
}

.profile-htitle {
  font-size: 22px;
  font-weight: 500;
}

.profile-header span {
  color: #757575;
  font-size: 18px;
  font-weight: 500;
}

.profile-hname {
  width: 200px;
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
}

.profile-hname:after {
  content: "";
  position: absolute;
  height: 50px;
  width: 1px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  top: 9px;
  right: 0;
}

.profile-follower {
  width: 170px;
  text-align: center;
}

.profile-header-info {
  display: flex;
  align-items: center;
}

.profile-hmenu a {
  color: #8f8f8f;
  display: inline-block;
  padding: 0 25px;
}

.profile-hmenu a.active {
  color: #757575;
  font-weight: 500;
}

.profile-bottom-box {
  max-width: 1100px;
  margin: 0 auto;
}

.profile-inner-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 30px 25px;
  margin-top: 40px;
}

.wrapper-head p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  line-height: 23px;
}

.profile-contact-box {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-contact-box span {
  width: 30px;
  height: 33px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
}

.btn.btn-primary.btn-md {
  padding: 12px;
  border-radius: 10px;
}

.follwer-list .chat-contact-box {
  align-items: center;
  position: relative;
  padding: 10px 0px;
}

.follwer-list .chat-contact-box .cperson-img img {
  max-width: 44px;
  max-height: 65px;
  border-radius: 50%;
}

.follwer-list .chat-contact-box .cperson-detail h4 {
  font-size: 15px;
  margin: 0;
}

.follwer-list .chat-contact-box .cperson-detail p {
  font-size: 13px;
}

.pfollower-tab {
  background: rgba(254, 192, 114, 0.1);
  display: flex;
  border-radius: 10px;
}

.pfollower-tab a {
  flex: 0 0 50%;
  text-align: center;
  color: inherit;
  font-size: 14px;
  padding: 5px;
  border-radius: 10px;
}

.pfollower-tab a.active {
  background: #e77469;
  color: #fff;
}

.follwer-list .chat-contact-box .msg-action {
  left: auto;
  right: 0;
  top: 24px;
}

.follwer-list {
  margin-top: 20px;
}

.follwer-list .action-tooltip {
  background: rgba(254, 192, 114, 0.1);
}

.follwer-list .action-tooltip:before {
  border-left: 12px solid rgba(254, 192, 114, 0.1);
}

.pink-link {
  color: #e77469;
  font-size: 15px;
}

.follower-list-link {
  margin-top: 10px;
}

.profile-about.profile-inner-wrapper .plan-box {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-width: 0;
  margin-top: 20px;
}

.profile-feed-box {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
  border-radius: 12px;
  padding: 30px 25px 0;
  margin-top: 40px;
  display: inline-block;
  width: 100%;
}

.profile-feed-box .chat-contact-box {
  align-items: center;
  padding: 0;
  margin-bottom: 20px;
}

.profile-feed-box .chat-contact-box .cperson-detail h4 {
  font-size: 18px;
}

.profile-feed-box .chat-contact-box .cperson-detail p {
  font-size: 16px;
}

.feed-desc {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 23px;
}

.feed-images {
  display: flex;
  margin-top: 15px;
}

.feed-images img {
  width: 100%;
}

.feed-large {
  width: 73%;
}

.feed-small {
  width: 30%;
}

.feed-small-box:last-child {
  margin-top: 10px;
}

.feed-large img {
  max-width: 97.5%;
}

.feed-action {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.feed-action-box {
  margin-right: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.feed-action span {
  margin-right: 5px;
  display: inline-block;
  width: 23px;
  height: 21px;
  background-repeat: no-repeat;
}

.feed-footer {
  display: flex;
  padding: 15px 0 15px 30px;
  margin: 0 -25px;
  border-top: 1px solid #ddd;
  align-items: center;
}

.feed-footer-members ul li:first-child {
  margin: 0;
}

.feed-footer-members ul li {
  display: inline-block;
  width: 30px;
  margin-left: -10px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  height: 30px;
}

.feed-footer-members ul li img {
  width: 100%;
}

.feed-footer-members p {
  margin: 0 0 0 5px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
}

.feed-footer-members ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.feed-footer-members {
  display: flex;
  align-items: center;
}

.feed-footer-members p {
  padding: 0;
}

.feed-footer-box:last-child {
  margin-left: 50px;
  font-size: 14px;
}

.feed-footer-box {
  color: #626262;
}

.error {
  color: red;
  font-size: 15px;
  display: none;
}

.nav-close {
  display: none;
}

.table-action .btn {
  display: block;
  height: 28px;
  padding: 0px;
}

.table-action .btn+.btn {
  margin-top: 5px;
}

.common-table td.table-action {
  padding: 10px 15px 10px 20px;
}

.create-from-steps {
  width: 100%;
  padding-left: 40px;
  max-width: 100%;
  padding-bottom: 40px;
}

.create-from-steps h4 {
  font-weight: 600;
}

.form-check {
  color: #757575;
  font-size: 14px;
}

.next-step-btn button.btn {
  width: 150px;
  height: 50px;
}

.form-check-input:focus {
  border-color: #000;
  outline: 0;
  box-shadow: unset;
}

.create-from-steps {
  display: none;
}

.create-from-steps.active {
  display: block;
}

.form-label {
  color: #757575;
  font-size: 18px;
  font-weight: 500;
}

.create-from-steps .form-control {
  height: 50px;
  border-radius: 6px;
}

.input-right-icon {
  position: relative;
}

.input-right-icon .invalid-feedback {
  position: absolute;
}

.form_submit_basketball .create-form-inner .form-group .error-message {
  position: absolute;
}

.input-right-icon svg {
  position: absolute;
  right: 10px;
  top: 17px;
}

.create-form-inner .tab_content {
  margin-bottom: 20px;
}

.time-add-more {
  padding-top: 33px;
}

.time-add-more .btn {
  height: 50px;
}

.create-from-btn button.btn {
  width: 160px;
  height: 50px;
}

.create-from {
  position: relative;
}

button.step-back-btn {
  background: transparent;
  border-width: 0;
  outline: 0;
  position: absolute;
  left: 0;
  font-size: 22px;
  top: -5px;
}

.profile-bar {
  background: #000;
  margin: -25px 0 30px -25px;
  width: calc(100% + 50px);
  border-radius: 10px 10px 0 0;
  padding: 15px 25px;
}

.profile-bar p {
  margin: 0;
  color: #fff;
}

.profile-img-box {
  align-items: center;
  padding: 0;
  display: flex;
  flex: auto;
}

.edit-profile-btn .edit-btn-icon {
  padding: 3px 5px 3px 10px;
  border-left: 1px solid #fff;
  margin-left: 10px;
}

.create-from-btn button.btn.edit-profile-btn {
  width: auto;
}

.create-from-btn button.btn.edit-profile-btn span {
  padding: 0 10px;
}

.subscription-btn .btn {
  background: #ff8642;
  border-color: #ff8e4e;
  padding: 10px 20px;
}

.plan-box {
  border-radius: 15px;
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
  padding: 30px;
  margin-top: 30px;
  border: 1px solid transparent;
  background: #fff;
}

.current-plan-txt {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.profile-membership-plan .plan-box {
  margin: 0;
}

span.price-con {
  font-size: 40px;
  font-weight: 600;
}

.plan-box.selected {
  border: 1px solid;
}

.plan-box.selected .choose-plan-btn {
  background: #000;
  color: #fff;
}

.plan-box.selected .choose-plan-btn:hover {
  background: #000;
  color: #fff;
}

ul.plan-point {
  margin-top: 15px;
  list-style: none;
  padding: 0;
}

ul.plan-point li {
  padding-left: 35px;
  position: relative;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
}

ul.plan-point li:before {
  content: "";
  width: 22px;
  height: 22px;
  background-image: url(../images/list-check.png);
  position: absolute;
  left: 0;
  top: 3px;
  background-size: 100% 100%;
}

.plan-box h4 {
  font-weight: 600;
  font-size: 20px;
}

.popup-overlay.show {
  display: block;
}

.common-pop.modal.show {
  z-index: 99999;
}

.fade.modal-backdrop {
  z-index: 99999;
}

.common-pop .modal-content {
  border-radius: 20px;
}

.create-from-btn {
  display: flex;
  column-gap: 15px;
  width: 100%;
}

.common-pop-box.created-message-box {
  padding: 100px 30px;
  width: 450px;
  background-image: url(../images/success-bg.jpg);
}

.common-pop-box.created-message-box h2 {
  font-size: 50px;
  font-weight: 600;
}

.common-pop-box.created-message-box .message-txt {
  font-size: 24px;
}

/*--------------------------------------------- Responsive ---------------------------------*/
@media screen and (max-width: 1400px) {
  .profile-info {
    margin-left: 30px;
    width: auto;
    max-width: 235px;
  }

  .venue-top .wrap-create-button.venuw-wrap-create-button.d-flex {
    left: 88% !important;
  }

  .enrollment_table_page td button:first-child {
    min-width: 40px !important;
    max-height: 30px !important;
    font-size: 17px !important;
    margin-right: -12px !important;
    display: flex;
    justify-content: center;
  }

  .approve_button_table_listing {
    display: inline-block;
    margin: 0 2px !important;
    padding-top: 11px;
    /* flex-direction: row; */
  }

  .enroll_team_button_search {

    margin-top: 49px !important;

  }




}

@media screen and (max-width: 1300px) {
  .search-open-btn {
    display: block;
    padding: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 13px;
    text-align: center;
    background: linear-gradient(122.47deg, #e77469 13.1%, #fec072 80.56%);
    color: #fff;
    margin-top: 3px;
  }

  .nav-search {
    width: auto;
  }

  .search-inner {
    position: fixed;
    top: 0;
    display: none;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .search-inner.show {
    display: block;
  }

  .search-close {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 26px;
  }

  .search-form {
    display: block;
    width: 500px;
    margin: 100px auto 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    max-width: 90%;
  }







}

@media screen and (max-width: 1100px) {
  .side-nav {
    /* left: -250px !important; */
    left: -300px !important;
    top: 0 !important;
    height: 100%;
  }

  .dashboard-inner {
    width: 100%;
    margin: 90px 0 0 0px;
    padding: 30px;
  }

  .nav-sm .dashboard-inner {
    margin-left: 0px;
    width: 100%;
  }

  .nav-sm .top-nav {
    padding-left: 40px;
  }

  .dashboard-logo img.large-logo {
    display: none;
  }

  .dashboard-logo img.small-logo {
    display: block;
  }

  .dashboard-logo {
    width: auto;
  }

  .nav-sm .dashboard-logo {
    font-size: 0;
    width: auto;
  }

  .nav-sm .side-nav {
    width: 250px;
    padding-left: 10px;
  }

  .side-nav.show {
    left: 0 !important;
  }

  .nav-sm .navigation-bar span.menu-title {
    display: block;
  }

  .menu-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }

  .menu-overlay.show {
    display: block;
  }

  .nav-close {
    display: none;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 99999999;
    color: #fff;
    font-size: 25px;
  }

  .search-inner.show {
    display: block;
  }

  .table-responsive table.table {
    min-width: 900px;
  }

  .side-nav.show .nav-close {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .top-nav {
    padding: 10px;
  }

  .message-notificaion {
    margin-top: -3px;
    margin-bottom: -3px;
  }

  .nav-search {
    margin-left: 20px;
  }

  .profile-info {
    margin-left: 20px;
  }

  .profile-name {
    display: none;
  }

  .profile-info {
    width: auto;
    margin-top: 0;
  }

  .profile-info img {
    width: 45px;
    margin-right: 5px;
  }

  .dashboard-logo img {
    max-height: 45px;
  }

  .notification-icon {
    width: 50px;
    height: 50px;
    padding-top: 11px;
  }

  .notification-icon img {
    max-height: 20px;
  }

  .dashboard-inner {
    /* margin: 55px 0 0 0px; */
    margin: 5.5rem 0 0 0px;
    padding: 30px 20px;
  }

  .profile-tooltip {
    top: 55px;
    right: 0;
  }

  .profile-tooltip a {
    padding: 10px 15px;
  }

  .notification-pop {
    width: 300px !important;
    right: -220px;
    padding: 5px 15px 25px;
    top: 55px !important;
  }

  .notification-con {
    width: calc(100% - 37px);
  }

  .notification-con p {
    font-size: 14px;
  }

  .dashboard-btns .btn {
    padding: 10px 15px;
    width: 120px;
    border-radius: 10px;
    font-size: 15px;
  }

  .table-filter {
    /* margin-right: 10px; */
    margin-right: 0px;
  }

  .wrapper-head {
    text-align: center;
  }

  .table-top-btns {
    justify-content: center;
    margin-top: 10px;
  }
}

@media screen and (max-width: 574px) {
  .dashboard--icons {
    display: flex;
    flex-direction: row-reverse;
    width: 145px;
    float: right;
  }

  .search-open-btn {
    padding: 8px;
    width: 35px;
    height: 35px;
    font-size: 13px;
    margin-top: 0;
  }

  .profile-info img {
    width: 35px;
  }

  .profile-icon {
    margin-top: 4px;
  }

  .notification-icon {
    width: 35px;
    height: 35px;
    padding-top: 6px;
  }

  .message-notificaion {
    margin-left: 0px;
    margin-right: 5px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .notification-icon span {
    width: 15px;
    height: 15px;
  }

  .profile-info {
    margin-left: 0;
  }

  .dashboard-logo img {
    max-height: 35px;
    margin-right: 5px;
  }

  .toggle-menu img {
    max-height: 35px;
  }

  .nav-search {
    margin-left: 10px;
  }

  .notification-pop {
    right: -142px;
    top: 45px !important;
  }

  .notification-title {
    font-size: 15px;
    margin-top: 15px;
  }

  .notificaion-box {
    padding: 10px 0;
  }

  .profile-tooltip {
    top: 45px;
  }
}

/*20-03-2023*/
.dashboard-logo img {
  margin-right: 10px;
  max-width: 92px;
}

.dashboard-top-btn .btn {
  font-size: 13px;
  margin: 0px 2px 3px;
}

/* Manorma start */
.tournament-box {
  padding: 10px 0;
  display: flex;
  background-color: #fafafa;
  border-top: 10px solid #fff;
  padding: 20px 15px 20px 20px;
}

.tournament-con {
  width: 100%;
}

.tournament-con {
  width: calc(100% - 65px);
  float: left;
  padding-left: 15px;
}

.tournament-con p {
  color: #757575;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 0px;
  font-size: 14px;
}

.tournament-name h5 {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.tournament-box:before {
  content: "";
  border-left: 4px solid #000;
  height: 22%;
  position: absolute;
  margin-left: -19px;
  margin-top: -20px;
}

.tournament-box.selected {
  background-color: #21252936;
  cursor: pointer;
}

.tournament-box:focus,
.tournament-box:hover {
  background-color: #21252936;
  cursor: pointer;
}

.Subscription-bar>a>.btn.btn-success {
  width: 145px;
}

.profile-dash #common-tabs-section {
  margin-top: -11px;
}

.profile-bar {
  background: #000;
  margin: 0 0 25px 0;
  width: calc(100% + 0px);
  border-radius: 10px;
  padding: 15px 25px;
}

table.table.white-td-table {
  table-layout: fixed;
}

.msl-wrp.msl-vars {
  border: 0;
  width: 100%;
  border: 1px solid #ced4da !important;
}

.msl {
  border: 1px solid #ced4da !important;
}

.schedule-maker .ant-collapse-item {
  box-shadow: 0px 0px 17px -6px rgb(0 0 0 / 10%);
  border-radius: 12px !important;
  margin-bottom: 15px;
  border-width: 0;
  border-bottom-width: 0px !important;
}

.schedule-maker .ant-collapse {
  border: 0px solid #d9d9d9;
  background: #fff;
}


.schedule-maker .ant-collapse-content {
  border-top: 0px solid #d9d9d9;
}

.schedule-maker .ant-collapse-header-text {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  border-width: 0;
  border-radius: 0;
}

.otp-input {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

button.btn-close.loginClose {
  margin-right: 0;
  position: inherit !important;
  margin-left: auto;
  display: block;
  margin-top: -21px;
}

#created-success-pop .common-pop-box div {
  margin-top: 30px;
}

.divisions-schedule {
  justify-content: flex-end;
  margin-bottom: 20px;
}

.divisions-schedule button {
  margin: 0px 2px 3px;
}

.rdw-editor-wrapper {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.rdw-editor-toolbar {
  border: inherit !important;
}

.comunication-form .form-control {
  background: rgba(230, 230, 230, 0.6);
  border-radius: 10px;
  height: 50px;
  border-width: 0;
  margin: 0px 0px 30px;
}

.add-fields input {
  height: 40px;
}

.input-right-icon.add-fields {
  margin: 0.5rem 0;
}

/*4-4-2023 Preeti */
/* .custom-check input:checked~.checkmark {
  background-color: #282041;
} */

.sub-menu .svg-inline--fa,
.menu-svg-inline--fa {
  width: 30px;
  height: 30px;
}

.schedular-body li a {
  box-shadow: -1px 0px 13px 0px rgb(40 32 65 / 20%);
  border-radius: 12px;
  margin-bottom: 15px;
  border-width: 0;
  border-bottom-width: 0px !important;
  padding: 12px 18px;
  font-size: 20px;
  color: #757575;
  font-weight: 600;
  display: block;
}

.schedular-body li {
  list-style: none;
}

.schedular-body li svg {
  float: right;
}

.common-table td {
  /* background-color: #FAFAFA; */
  border-top: 10px solid #fff;
  padding: 8px 15px 0px 15px;
  /* color: #757575; */
}

/* info box */

.info-box {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  padding: 0.5rem;
  min-height: 80px;
  background: #ffffff;
  display: flex !important;
}

span.info-box-icon.bg-info.elevation-1 {
  color: #fff !important;
  background: rgba(240, 103, 34, 1);
  background: -moz-linear-gradient(top,
      rgba(240, 103, 34, 1) 0%,
      rgba(255, 177, 82, 1) 100%);
  background: -webkit-gradient(left top,
      left bottom,
      color-stop(0%, rgba(240, 103, 34, 1)),
      color-stop(100%, rgba(255, 177, 82, 1)));
  background: -webkit-linear-gradient(top,
      rgba(240, 103, 34, 1) 0%,
      rgba(255, 177, 82, 1) 100%);
  background: -o-linear-gradient(top,
      rgba(240, 103, 34, 1) 0%,
      rgba(255, 177, 82, 1) 100%);
  background: -ms-linear-gradient(top,
      rgba(240, 103, 34, 1) 0%,
      rgba(255, 177, 82, 1) 100%);
  background: linear-gradient(to bottom, #f05829 50%, #ffb072 94%);
}

.info-box-content {
  /* padding: 5px 10px; */
  padding: 5px 7px;
  flex: 1;
  width: 169px;
  width: 135px;
  color: #757575;
}

.progress-description,
.info-box-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box-number {
  display: block;
  font-weight: 700;
  font-size: 30px;
}

.info-box-icon {
  border-radius: 0.25rem;
  display: flex;
  width: 70px;
  text-align: center;
  font-size: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: center !important;
  justify-content: center;
}

table.event span {
  font-size: 13px;
  font-weight: 600;
}

.event_table span {
  font-size: 13px;
  font-weight: 600;
  color: #757575;
}

.dashboard_tab {
  margin-top: 0px !important;
}

button.btn.yellow svg path {
  color: #ffc107 !important;
}

.Financial td:nth-child(4) {
  text-align: center;
}

.Mailphon td:nth-child(3) {
  cursor: pointer;
}

.Mailphon td:nth-child(2) {
  cursor: pointer;
}

.organizer_table td:nth-child(6) {
  text-align: center;
}

.event_table td:nth-child(7) {
  text-align: end;
}

.MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
  background-color: #f05829 !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #f05829 !important;
}

.dashboard-top-btn .create-from-btn .btn {
  height: unset !important;
}

.message-colm h4 {
  flex: auto;
  font-weight: 600;
}

.message-colm {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.message-colm h5 {
  flex: auto;
}

#backbttn .active {
  background: #f05829 !important;
}

.assigned-team {
  display: flex;
}

/* .assigned-team {
    display: flex;
    gap: 1%;
} */
.assigned-team span {
  color: #f05829;
  font-weight: 700;
  margin-left: 1rem;
}

.team-view-table li {
  width: 33.3%;
  text-align: center;
}

.team-view-table ul {
  width: 60%;
  padding: 6px;
  border-radius: 10px;
  justify-content: space-between;
  outline-color: #393150;
  background: #393150;
  margin: 2rem 13rem;
}

.team-view-table .nav-link {
  color: #fff;
}

.view-profile {
  justify-content: space-around;
}

.team-view-table>ul>li:has(button.active)>button {
  width: 100% !important;
  border-radius: 0.5rem;
  color: #000 !important;
}

#team-table ul>li>button {
  width: 100%;
}

.team-view-table .nav-link:focus,
.team-view-table .nav-link:hover {
  isolation: isolate;
  border-color: transparent !important;
  /* color: #fff; */
}

.team-logo {
  width: 20%;
  background: #ddd;
}

.pteam-detail ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  color: #000000;
  font-weight: 600;
}

.pteam-detail span {
  color: #757575;
  font-weight: 400;
}

.venuestable {
  margin-top: -68px;
}

.team_coach {
  margin: 11px 0px !important;
}

.cperson-img-message {
  position: relative;
  margin-right: 9px;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.08);
  border: 2px solid #000000;
  border-radius: 50%;
}

.email-reply-part {
  margin-bottom: 2rem;
  border-bottom: 1px solid #ddd;
  padding: 0 0 1.5rem 0;
}

.cperson-detail-message p {
  margin: 0px;
}

.venue-tabs li {
  width: 50%;
}

.team-edit {
  display: flex;
}

.reports-view .ant-collapse-item {
  box-shadow: 0px 0px 17px -6px rgb(0 0 0 / 20%);
  border-radius: 12px;
  margin-bottom: 15px;
  border-width: 0;
  border-bottom-width: 0px !important;
  background: #fff;
}

.reports-view .ant-collapse {
  border: 0px solid #d9d9d9;
  background: transparent;
}

.reports-view .ant-collapse-content-box {
  border-top: 1px solid #f9f7f7;
  padding-top: 0;
}

.reports-view .ant-collapse-content {
  border-top: 0px solid #d9d9d9;
}

.reports-view .ant-collapse-header-text {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  border-width: 0;
  border-radius: 0;
}

.reports-view tr.MuiTableRow-root.MuiTableRow-hover td {
  padding: 1rem 1rem;
}

.dashboard_lastrow {
  position: relative;
}

div#daily-special-tabs-tabpane-current {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
}

.rbc-toolbar button {
  padding: 0.175rem 0.71rem !important;
}

.dashboard-auto-wrapper.p-4.calender {
  height: 571px;
}

.dashboard_lastrow {
  height: 525px;
}

.rbc-allday-cell {
  display: none !important;
}

td.table-venue img {
  width: 60px;
  height: 58px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.Description-message p {
  font-size: 15px;
  color: #000;
}

/*26-04-23 Preeti*/
.uploade-file_logo img {
  width: 47%;
}

/*27-04-23 Preeti*/
.message-edit {
  display: flex;
  align-content: flex-end;
  flex-direction: row-reverse;
}

/* #common-tabs-section .tab-content>.active{
  margin-top: 5rem;
} */
.wrap.current-games-top {
  display: flex;
  margin-bottom: 21px;
}

.dash-tab .table-responsive table thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.wrap.current-games-top .table-responsive {
  height: 300px;
  overflow-y: auto;
}

.wrap.current-games-top .inner-f {
  display: flex;
  align-items: end;
  max-width: 615px;
  width: 100%;
  margin-right: 20px;
}

.outer-wraping {
  padding-top: 5rem;
}

.l-card.create-league.upcoming-event button {
  padding: 7px;
  max-width: 179px;
}

.l-card.create-league.upcoming-event .wrap {

  width: 100%;
  max-width: unset;
}

.l-card.create-league.upcoming-event .inner-f {
  display: flex;

}

.l-card.create-league.upcoming-event {
  max-width: 550px;
  width: 100%;
}

.l-card.create-league.upcoming-event {
  border: 1px solid #cccccc73;
  margin: 0px auto;
}

.Description-message {
  padding: 0 42px;
}

.report-flag {
  display: flex;
  justify-content: flex-end;
}

.report-flag .form-label {
  flex: auto;
}

.row.reports-view .dashboard-auto-wrapper div {
  justify-content: end;
}

.row.reports-view .dashboard-auto-wrapper div h4 {
  flex: auto;
}

.reports-view .exportbtn {
  font-size: 12px;
}

.exportbtn {
  font-size: 15px;
}

.reports-view .dashboard-auto-wrapper {
  height: 400px;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
  z-index: 100000 !important;
  top: 5rem !important;
  right: 0rem !important;
}

input.border.form-control,
select.border.form-control {
  border-width: 2px !important;
}

#outlined-basic {
  border: 1px solid rgba(0, 0, 0, 0.28);
  box-sizing: border-box;
  border-radius: 4px;
  height: 46px !important;
  appearance: none;
  color: #828282;
  font-size: 17px;
  margin-bottom: 17px;
  width: 100%;
  outline: 1px;
  padding: 9px;
}

button.cross-btn {
  padding: 0px 13px;
  margin-left: 0.9rem;
  height: 46px;
  background: #433d5a;
  border-radius: 3px;
}

button.cross-btn span {
  font-size: 28px;
  color: #fff;
}

.Inputlist_div {
  display: flex;
}

span.Emptyinput {
  font-size: 16px;
  padding: 10px 0px;
  color: #dc3545;
  margin-left: 21px;
  font-weight: 600;
}

button.addfield-btn {
  margin: 4px 0;
  background-color: #433d5a;
  border-color: #433d5a;
  color: #ffff;
  font-size: 14px;
  height: 44px;
  width: 122px;
  border-radius: 7px;
}

.VideoInput_input {
  display: none;
}

.table>thead {
  background-color: #e77469;
}

.table>thead {
  background-color: transparent;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
}

th.text-center,
td,
th {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-width: 0px !important;
}

.ErrorHandling {
  color: #ed1515;
}

.react-tel-input .form-control {
  display: block;
  width: 100% !important;
  padding: 0.575rem 0.75rem;
  font-size: 1rem !important;
  font-weight: 400;
  height: 72px !important;
  line-height: 1.5 !important;
  color: #212529;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.28) !important;
  appearance: none;
  border-radius: 12px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-left: 48px;
}

.lds-roller {
  margin: 0px !important;
  height: 72px !important;
}

.match_score {
  display: inline-flex;
  margin-bottom: 2rem;
  /* width: 11vh; */
}

.row.venue_name {
  margin: 0 auto;
  width: 100%;
}

.venue_name select {
  width: 100%;
}

.view-matchs-page .divisions-schedule {
  margin-bottom: 0px !important;
}

.card-match {
  margin: 0 0 1rem 0;
}

.view-matchs-page .ant-collapse-header {
  background: #393150f2;
  color: #fff !important;
  border-radius: inherit !important;
}

.view-matchs-page .ant-collapse-content-active {
  border: 1px solid #ffffff;
  border-top: 0px !important;
}

.view-matchs-page .ant-collapse-header-text {
  color: #000000;
}

.view-matchs-page .ant-collapse-item-active .ant-collapse-header {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.date-time .form-group {
  display: grid;
  margin-top: -2.3rem !important;
}

.date-time .form-group .form-label {
  width: 100%;
}

.view-matchs-page .cperson-img {
  margin-right: 10px;
  display: contents;
}

.view-matchs-page .cperson-img img {
  width: 70px;
  height: 70px;
}

button.btn.teamedit {
  margin: 0 0 3rem 1rem;
}

.msl-chip {
  padding: 5px 3px 5px 6px !important;
}

.msl {
  height: 50px;
}

.msl-wrp.msl-vars {
  border: none !important;
}

.disabled-link {
  pointer-events: none;
}

.navigation-bar>ul>li:has(a.disabled-link)>a {
  background: #28204133 !important;
  border-radius: 20px 0px 0px 20px;
  /* border-right: 5px solid #555555!important; */
  border-right: 5px solid #282041 !important;
  color: #fff;
}

.common-table .torunament_btn {
  margin: 0 0.1rem;
  font-size: 12px;
  border: 2px #38304f solid !important;
  padding: 3px 10px;
}

button.btn.torunament_btn.enroll {
  background: #f05829 !important;
  color: #fff;
  border: 0px !important;
}

button.btn.torunament_btn.enrolled {
  background: #198754 !important;
  color: #fff;
  border: 0px !important;
  cursor: not-allowed;
}



button.btn.torunament_btn.edit_match {
  background: #1a084cad !important;
  color: #fff;
  border: 0px !important;
}

button.btn.torunament_btn.generate_btn {
  background: #2096f2 !important;
  color: #fff;
  border: 0px !important;
}

button.btn.torunament_btn.add_score {
  background: #6c757d !important;
  color: #fff;
  border: 0px !important;
}

.btn-dark.green.publishmatch {
  background: #f05829 !important;
  border-color: #f05829 !important;
  font-weight: 600;
  width: 100px;
  margin-left: 11px;
}

.publishmatch svg path {
  fill: #fff;
}

.published {
  background: #198754 !important;
  color: #fff;
  border: 0px !important;
  cursor: not-allowed;
}

.group-heading h4 {
  font-weight: 600;
}

.common-table .white-td-table .wine-categories td {
  padding: 14px 15px 14px 20px;
  background-color: #a3a8af;
  margin: 14px 0 !important;
}

button.btn.btn-dark.green.add {
  width: 92px;
  height: 39px;
}

.common-table .white-td-table td {
  padding: 11px 9px 19px 15px;
  background-color: #4a416400;
}

tr.wine-categories td:first-child:before {
  border: 0px;
}

.table>tbody tr:hover {
  /* background: rgb(231 231 231);  */
  background: transparent;
}

h5.qualiffied {
  font-weight: 600;
  color: #219653;
  font-size: 16px;
}

h5.notqualiffied {
  font-weight: 600;
  color: #F2994A;
  font-size: 16px;
}

span.remarks {
  font-size: 14px;
  font-style: italic;
  color: red;
}

/* .enrollcheck {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgb(224, 224, 224);
    text-align: left;
    padding: 0px 0px 0px 4px;
    color: rgba(0, 0, 0, 0.87);
    width: 48px;
} */
/* .ant-collapse-item {
    border-radius: 8px 8px 8px 8px;
} */

li.breadcrumb-item {
  font-size: 20px;
  font-weight: 600;
}

.breadcrumb-item a {
  color: #000;
  font-size: 15px;
}

li.breadcrumb-item.active a {
  color: #f05829;
}

.team-logo.coach-picture {
  width: 23%;
  background: #fff;
}

table.table.table-striped th {
  font-size: 14px;
  position: sticky;
  top: -1px;
}

.table-responsive.score-tab {
  height: 450px;
  overflow-y: auto;
}

table.table.table-striped td {
  font-size: 14px;
  padding: 20px 13px;
  color: #757575;
  font-family: 'Roboto', sans-serif;
}

.table-scroll {
  border: 1px solid #ccc;
}

.table-scroll .table-wrapper {
  padding: 0;
  margin: 0;
}

table.table.standing-table td {
  color: #000;
}

.table-responsive.standing-tb {
  height: 600px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
}

.standing-table thead th {
  position: sticky;
  top: -1px;
}

/* .group-tables .common-table thead tr th {
    background: #fbfbfe;
    color: #000;
    font-weight: 500;
    padding: 20px 15px 20px 20px;
    padding: 15px 15px 15px 20px;
    padding: 12px 15px 12px 20px;
    border: 1px solid #f58c6b;
} */

/* tournament */
.tournament .ant-collapse-content {
  border: 0px !important;
}

.ant-collapse.tournament {
  background: transparent;
  border: 0px !important;
}

.tournament .ant-collapse-item {
  border: 0px !important;
  margin: 1rem 0;
}

.tournament .ant-collapse-header {
  background: #fff !important;
  border: 0px !important;
}

.ant-collapse.tournament>.ant-collapse-item:last-child {
  border: 0px !important;
}

.ant-collapse.tournament>.ant-collapse-item:first-child {
  border: 0px !important;
  margin: 0;
}

.tournament .ant-collapse-content-box {
  padding: 1rem 2rem !important;
}

/* #enroll-maker .ant-collapse-header-text div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
} */

img.tableprofile {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.wrapperClassName svg {
  fill: #e75b32;
}

._loading_overlay_overlay {
  background: #75757538 !important;
}

.team-logo-enroll img {
  width: 60px;
  height: 60px;
}

.profile-img-box-enroll {
  align-items: center;
  padding: 0;
  display: flex;
  flex: auto;
}

.profile-img-box-enroll .pteam-detail ul {
  gap: 2px;
}

.box-enroll .enroll {
  background: #f05829 !important;
  color: #fff;
  border: 0px !important;
  margin: 0.2rem;
  padding: 3px 9px;
  font-size: 14px;
  width: 72px;
}

a.btn.btn-sm.btn-icon.btn-outline-primary.rounded-circle {
  width: calc(1rem + 14px);
  line-height: 1.5rem;
  height: 31px;
}

.checkbox-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.3125rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.8125rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.sc-gEvEer {
  /* width: 100% !important;
  display: block !important;
  flex: unset !important;
  background: #393150;
  margin-bottom: 2rem;
  padding: 2rem 0rem;
  color: #fff; */
  width: 31% !important;
  display: block !important;
  flex: unset !important;
  background: #393150;
  margin-bottom: 2rem;
  padding: 2rem 0rem;
  color: #fff;
}

.sc-imWYAI {
  background-color: transparent !important;
  box-shadow: none !important;
}

.sc-dcJsrY {
  /* border: 1px solid #000000fa; */
  margin: 0.5rem 0px;
  box-shadow: rgb(17, 22, 48) 0px 2px 4px -2px;
  background: #fff !important;
}

.sc-dcJsrY .score {
  color: #000;
  font-size: 16px;
}

.sc-eqUAAy {
  color: rgb(241 107 66) !important;
  font-weight: 700 !important;
  text-align: center !important;
  font-size: 22px;
}

.status_game {
  background: #0dcaf0 !important;
  color: #fff;
  font-size: 11px;
  border: 0px;
  padding: 0.3rem 6px;
  border-radius: 10px;
}

.score_add_div {
  display: flex;
}

.score_add_div li {
  list-style: none;
}

.score_add_div .date {
  display: block;
  font-weight: 700;
  font-size: 1.1em;
  text-transform: uppercase;
  color: #333;
  width: 14%;
}

.score_add_div .venue li svg {
  margin: 0 10px 0 0;
}

.score_add_div .venue {
  width: 18%;
}

.score_add_div .referee {
  width: 28%;
}

.referee .pteam-detail ul,
.referee .pteam-detail li {
  margin: 0px !important;
  text-align: left;
}

.score_add_div .date,
.score_add_div .venue,
.score_add_div .referee {
  text-align: left;
}

.score .profile-img-box-enroll {
  margin: 1rem 0;
}

.score .profile-img-box-enroll input {
  margin: 0rem 0.5rem;
}

.score_add_div .score {
  padding: 0.5rem 1rem;
}

button.btn.btn-dark.green.add svg path {
  fill: #fff !important;
  color: #fff !important;
}

td.MuiTableCell-root.MuiTableCell-body.no_data {
  height: 158px;
  text-align: center;
  font-size: 20px;
}

.imp_ast {
  color: red;
  font-size: 16px;
  padding-left: 3px;
}

.sportlist button {
  margin-top: 0px;
}

.continue-btn.sportlist {
  background: #fff;
  padding: 1rem ; 
  width: 80%;
  margin: auto;
}

.panel_sports.create-form-inner h4 {
  font-size: 24px;
  font-weight: 600;
}


.hover-blue:hover {
  color: #298cea;
}
.enroll svg path {
  fill: #fff !important;
  color: #fff !important;
}

.react-confirm-alert-body {
  text-align: center !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.react-confirm-alert-overlay {
  background: #75757557 !important;
}

.react-confirm-alert-button-group>button {
  background: #F1582B !important;
}

.react-confirm-alert-body {
  font-size: 19px;
}

div#enroll-maker .ant-collapse-header {
  background: #ffffff;
}

.sc-aXZVg {
  background: #393150;
}

.sc-iGgWBj {
  border: 1px solid #00000085;
}

.breadcrumb-top {
  display: flex;
  justify-content: space-between;
}

.ant-collapse.edit-match {
  padding: 0px !important;
}

.not_verified_status {
  background: #dc3545 !important;
  color: #fff;
  font-size: 13px;
  border: 0px;
  padding: 0.5rem 8px;
  border-radius: 10px;
}

.verified_status {
  background: #45a907 !important;
  color: #fff;
  font-size: 13px;
  border: 0px;
  padding: 0.5rem 8px;
  border-radius: 10px;
}

/* .team_detail td{
 padding:16px !important;
} */

.team_detail svg path {
  fill: #f05829;
}

.document_list th {
  text-align: center;
}

.document_list td:last-child {
  text-align: center;
}

.groups-link {
  font-size: 12px;
  padding: 0.2rem 0.4rem;
  color: #0d6efd;
  font-weight: 500;
  border: 2px solid #0d6efd;
  border-radius: 17px;
}

.groups-link:hover {
  color: #fff !important;
  background-color: #0d6efd;
}

.bracket-view {
  padding: 0px !important;
}

.team_detail td a {
  color: #05489c !important;
}

.document_player_list td {
  padding: 19px;
}

.plr_detail {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 50%;
}

.file-hide {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 14px;
  top: 11px;
  color: #f05829;
}

.edit-panel .ant-collapse-header-text div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.ant-collapse-content-box .publishmatch span {
  background: #f05829 !important;
  border-color: #f05829 !important;
  font-weight: 500;
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  font-size: 17px;
  color: #fff;
}

.ant-collapse-content-box .publishmatch {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.white-td-table .wine-categories td {
  padding: 14px 15px 14px 20px;
  background-color: #a3a8af;
  margin: 14px 0 !important;
}

.edit-panel .wine-categories .custom-check {
  color: #000;
}

.edit-panel .wine-categories .form-label {
  color: #000;
}

.common-table.new-table thead tr th {
  background-color: #fff;
  color: #605a5a;
  /* font-size: 17px; */
  font-size: 15px;
  font-weight: 500;
  padding: 23px;
  font-family: inherit;
}


.common-table.new-table td:first-child:before {
  border-left: none;
}

/* .common-table.new-table td{
  padding: 11px 9px 19px 15px;
  background-color: #F6F9FF;
} */

.common-table.new-table tbody tr:nth-child(odd) {
  background: #F6F9FF;
}

.new-table h6 {
  color: #000;
  /* font-size: 16px; */
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
}

/* .new-table img {
  margin-bottom: 6px;
} */
.schedule-1 {
  justify-content: center;
  margin-bottom: 20px;

}

button.btn.btn-dark.next-orange-btn {
  width: 100% !important;
  height: 53px !important;
  background: #EE5729;
  border-color: #EE5729;
}

.schedule-1 {
  /* max-width: 125px; */
  width: 100%;
}

.result-modal .modal-dialog {
  width: 833px;
}

.dashboard-top-btn h3 {
  flex: auto;
  margin: 11px 13px 4px;
  font-weight: 600;
}

.create-form-inner {
  background: #fff;
  padding: 1rem 1rem 0;
  border-radius: 19px;
}

.create-form-inner .summary input.form-control {
  height: 113px;
}

button.btn.btn-dark.green.white-orange-color.create-group {
  width: 155px;
  height: 42px;
  border-radius: 8px;
  background: #F1582B;
  color: #fff;
}


nav.navbar.navbar-expand-sm {
  justify-content: center;
}

a.nav-link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 37px;
  padding: 5px;
  color: rgba(0, 0, 0, 0.28);
}

span.badge.bg-warning {
  font-size: 9px;
  position: absolute;
}

b.white-orange-color {
  font-weight: 500;
  color: #F2994A;
}

b.text-color-green {
  color: #219653;
  font-weight: 500;
}

.sub_heading {
  margin: 17px 0px 0px;
}

.start_new_transaction {
  background-color: white;
  outline-style: solid;
  outline-color: whitesmoke;
  outline-width: initial;
  padding: 17px;
  border-radius: 25px;
}

select#sel1 {
  width: 38%;
  display: inline-block;
  background-color: #F8F8F8;
  border: white;
  padding: 10;
  box-shadow: none;
  margin: 0px 26px 0px;
}

select#sel2 {
  width: 38%;
  display: inline-block;
  background-color: #F8F8F8;
  border: white;
  padding: 10;
  box-shadow: none;
  margin: 0px 26px 0px;
}

select#sel3 {
  width: 38%;
  display: inline-block;
  background-color: #F8F8F8;
  border: white;
  padding: 10;
  box-shadow: none;
  margin: 0px 26px 0px;
}

.Edit_detail_timer {
  display: flex;
  margin: 10px 0px 5px;
}

.continue_botton {
  border-radius: 18px;
  width: 169px;
  height: 39px;
  margin: 26px;
}

.Continue_btn {
  text-align: center;
}

input#my_email {
  width: 100%;
  display: inline-block;
  background-color: #F8F8F8;
  border: white;
  padding: 10;
  box-shadow: none;
}

input#Transaction_title {
  width: 100%;
  display: inline-block;
  background-color: #F8F8F8;
  border: white;
  padding: 10;
  box-shadow: none;
}

select#currency {
  width: 100%;
  display: inline-block;
  background-color: #F8F8F8;
  border: white;
  padding: 10;
  box-shadow: none;
}

input#Price {
  display: inline-block;
  background-color: #F8F8F8;
  border: white;
  padding: 10;
  box-shadow: none;
}

input#Transaction_Date_2 {
  display: inline-block;
  background-color: #F8F8F8;
  border: white;
  padding: 10;
  box-shadow: none;
}

select#types_transaction {
  width: 100%;
  display: inline-block;
  background-color: #F8F8F8;
  border: white;
  padding: 10;
  box-shadow: none;
}

.form-check {
  display: inline-block;
  margin: 1px 20px 0px;
}

.placeholders_transaction {
  margin: 17px 0px 5px;
}

.new_transaction_tabs a.nav-link.active {
  background: #2B3B47 !important;
}

.new_transaction_tabs .nav-link {
  background: #D9D9D9;
  color: #fff !important;
  padding: 1px 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 15px;
  line-height: 36px;
}

.new_transaction_tabs {
  width: 464px;
  margin: auto;
  display: block;
}

.round_div {
  background: #393150;
}

.round_div [dir="ltr"] .sc-imWYAI:nth-child(2n)::after {
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
}

.round_div [dir="ltr"] .sc-imWYAI:nth-child(2n+1):not(:last-child)::after {
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
}

.round_div [dir="ltr"] .sc-imWYAI:nth-child(2n)::before {
  border-top: 0px
}

b.white-orange-color {
  font-weight: 500;
  color: #F2994A;
}

b.text-color-green {
  color: #219653;
  font-weight: 500;
}

.round_div .sc-gEvEer {
  margin: 0px;
  padding: 1rem 0 0 0;
  display: block !important;
  width: 100% !important;
}

.round_div .sc-imWYAI {
  width: 33%;
  display: inline-block;
}

th.table_create_match {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.new_transaction_tabs p {
  margin: 0px 27px 6px;
}

.tabs_options h4 {
  text-align: center;
}

/* .nav-pills .nav-link {
  border-radius: 55px;
  width: 35px;
  height: 35px;
  background-color: rgb(79 79 79 / 27%);
  margin-top: 18px;
  padding: 0px;
  font-size: 14px;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: rgba(79, 79, 79, 1);
  font-size: 13px;
} */

/* .nav-link:focus,
.nav-link:hover {
  color: #fff;
} */

/* .nav-item {
  padding: 15px 0px;
} */

.create-form-inner input.form-control {
  background: #fff;
  border: 1px solid #e4e3e3;
  /* height: 70px; */
}

.create-form-inner select.form-control {
  background-color: #fff;
  /* height: 70px; */
}

/* .create-form-inner label.form-label {
  color: #4f4f4f;
  font-size: 16px;
} */

:where(.css-dev-only-do-not-override-dfjnss).ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  color: #000;
}

.divisions-schedule button.btn.btn-dark {
  background: #F1582B;
  border-color: #F1582B;
  color: #fff;
  width: 120px;
  /* margin-top: -3rem; */
  z-index: 3;
  /* margin-right: 4rem; */
  height: 50px;

}

button.btn.btn-dark.green.white-orange-color {
  background: transparent;
  color: #F1582B;
  width: 184px;
  height: 59px;
  font-size: 17px;
  border: 1px solid;
  border-radius: 14px;
}

button.btn.btn-dark.green.white-green-color {
  background: #27AE60;
  border-color: #27AE60;
  color: #ffffff;
  width: 184px;
  height: 59px;
  font-size: 17px;
}

.search .form-control {
  background: transparent;
  border-radius: 10px;
  height: 44px;
  font-size: 14px;
  margin-left: 14px;
}

.pop-form {
  padding-left: 14px;
  padding-top: 10px;
}

.coach-info img {
  max-width: 18px;
}

.search span.input-icon-alt {
  color: #b6b6b6;
  float: right;
  font-size: inherit;
  position: absolute;
  right: 36px;
  top: 10px;
}

.table-pop .modal-dialog {
  width: 60%;
}

.profile-details {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0rem 2rem;
}

.profile-details {
  border: 1px solid #efe4e4;
  padding: 3px 11px;
  border-radius: 13px;
  width: 390px;
  height: 115px;
}

svg.svg-inline--fa.fa-pen-to-square {
  color: #2F80ED;
  font-size: 22px;
}

svg.svg-inline--fa.fa-trash-can {
  color: #EB5757;
  font-size: 22px;
}

/* td.select-dropdown select.form-control {
  width: 256px;
  background-color: #F9F9F9;
} */

.coach-profle h5 {
  color: #000000ba;
  font-size: 18px;
  font-weight: 600;
}

span.mail {
  font-size: 16px;
  color: #000000ad;
  font-family: 'Roboto', sans-serif;
}

.profile {
  /* margin-bottom: 4rem; */
  /* margin-top: -1rem; */
}

/* .create-from-btn {
  margin-top: -5rem;
} */

span.coach-mail {
  margin-left: 12.5px;
}

.coach-info {
  margin-bottom: 10px;
  font-size: 14px;
}

.text-color-element.element.elements {
  margin-top: -2rem;
}

.edit-option button.btn.btn-dark {
  background: #fff;
  border-color: #fff;
  width: 210px;
  margin-top: -4rem;
  z-index: 3;
  margin-right: 1rem;
  height: 43px;
}

.team {
  display: flex;
  justify-content: space-between;
}

.create-matches {
  border: 2px solid #ddd7d7;
  padding: 21px 16px 18px 21px;
  border-radius: 13px;
  /* width: 502px; */
}

span.dark-green {
  color: #27ae60;
  font-size: 17px;
  font-weight: 600;
  margin-left: 7px;
}

span.orange-color {
  color: #EE5729;
  font-size: 32px;
  font-weight: 700;
}

span.pink {
  color: #E6627A;
  font-size: 17px;
  font-weight: 600;
  margin-left: 7px;
}

.location span {
  font-size: 15px;
  color: #0000008f;
}

.location a.colored-text {
  font-size: 15px;
}

.team-profile {
  display: flex;
  align-items: flex-end;
}

.common-table.new-table.table.table-striped.table-hover {
  border: 1px solid #EAEAEA;
  border-radius: 16px;
}

.table-pop .custom-check input:checked~.checkmark {
  background-color: #27ae60;
  border: 2px solid #27ae60;
}

.MuiPaper-elevation1 {
  box-shadow: none;
}

/* .top-table {
  margin-top: -3rem;
} */

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.coach-profile-details {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.team-coach-detail {
  width: 49%;
}

.team-about {
  border: 1px solid #00000014;
  width: 289px;
  height: 310px;
  text-align: center;
  border-radius: 9px;
  padding: 30px 3px;
}

.team-details-about {
  display: flex;
  flex-wrap: wrap;

}

.learn-more {
  text-align: center;
}

.change-group select.form-control {
  width: 256px;
  background-color: #e0e0e03d;
  height: 40px;
  margin-left: -2rem;
  font-size: 13px;
}


/*======================
    404 page
=======================*/


.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 500px;
  background-position: center;
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #EE5729;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}


/* new menu css */

.menuCol {
  float: left;
  padding: 10px;

}

/* Style the columns */
.menuCol a {
  float: none;
  color: black;
  padding: 10px;
  margin-bottom: 10px;
  display: block;
}

/* .megaMenu {
  padding: 0 10px;
} */
/* Add hover */
.menuCol.menu-first a:hover {
  background-color: #f05829;
  color: #fff;
  border-radius: 4px;
}

.menuCol.menu-first {
  padding-left: 0;
}

.main-menu>ul li .dropdown-menu {
  position: absolute;
  display: none;
  width: 100%;
  min-width: 230px;
  left: 99%;
  top: -7px;
}

.d-flex.leagues-details {
  background-color: #f5f5f5;
}

.side-nav {
  display: none;
}

.dashboard-inner {
  width: 100%;
  margin: 90px 0 0 0px;
}

footer {
  text-align: center;
}

.top-nav {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%), 0 6px 20px 0 rgb(0 0 0 / 0%);
}

.l-card {
  /* max-width: 372px; */
  width: 100%;
  /* max-width: 23%; */
  background-color: #fff;
  border-radius: 13px;
  min-height: 180px;
}

/* .l-card {
  max-width: 23%;
} */



.l-card .wrap .l-title {
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  gap: 3px;
}

.l-status {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: flex-start;
}

.card-content .date {
  margin-top: 0px;

  background-color: transparent;
}

.groups-count {
  display: flex;
  justify-content: flex-start;
}

.l-card .wrap {
  padding: 15px;
}

.card-content .date p {
  text-align: initial;
}

.card-content .date p {
  font-size: 14px;
}


.group .btn {
  padding: 3px 8px;
  font-size: 13px;
  font-weight: 600;
}

.groups-count .group {
  margin-right: 0;
  display: flex;
  gap: 10px;
  align-items: center;
}

.card-content {
  margin-left: 20px;
}

.l-status span {
  width: 10px;
  height: 10px;
  background-color: #5ac911;
  display: inline-flex;
  border-radius: 12px;
  margin: 0 6px;
}

.l-status>p {
  margin-right: 12px;
  font-size: 13px;
  margin-top: 3px;
}

.l-status>p:first-child {
  margin-right: 12px;
  background-color: #DDFFEB;
  padding: 4px 6px;
  font-size: 14px;
  border-radius: 7px;
  color: #27AE60;
  font-weight: 500;
}

.groups-count {
  display: flex;
  justify-content: left;
}

.date-card {
  /* margin-bottom: -7px; */
  background: transparent;
}

.date-card p {
  text-align: left;
  margin-bottom: 5px !important;
}

.l-card {
  margin-right: 25px;
  margin-bottom: 25px;
}

/* .dashboard-top-btn{
  margin-bottom: 67px;
} */
.create--tournaments .nav-item {
  max-width: 253px;
  width: 100%;
}

.create--tournaments .nav-item:after {
  display: none;
}

.create--tournaments .nav-item p {
  text-align: center;
}

.create--tournaments .nav-item {
  flex-direction: column;
}

.create--tournaments .nav-item p::after {
  content: "";
  display: inline-flex;
  width: 79px;
  height: 2px;
  background: #000;
  position: absolute;
  right: 0;
  top: 31px;
  left: 80%;
}

.create--tournaments .nav-item:last-child p::after {
  display: none;
}

.dashboard-search-input {
  justify-content: space-between;
}

.dashboard-inner {
  margin: 57px 0 0 0px;
}

.table-filter {
  margin-right: 0;
}

.create--tournaments .nav-item {
  padding-bottom: 0;
}

.top-steps-sticky.col-sm-12 {
  position: sticky;
  top: 75px;
  background-color: #fff;
  padding: 14px 24px;
  border-radius: 10px;
  margin-bottom: 32px;
  z-index: 2;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%), 0 6px 20px 0 rgb(0 0 0 / 0%);
}

.dashboard-top-btn {
  margin-top: 27px;
}

.input-icon input.form-control {
  padding-left: 37px;
}


.l-card.create-league .wrap button {
  margin: 6px 0;
  padding: 18px 0;
}

.l-card.create-league .wrap span {
  margin-top: 8px;
  color: #6c6c6c;
  font-size: 16px;

}

.dashboard-inner {
  background-color: #eeeeee;
}

.l-card .wrap .l-title {
  display: flex;
  align-items: center;
}

.date-card {
  height: auto;
}

.fcard .score-board .team-name {
  display: flex;
  justify-content: space-between;
}

.score-update {
  display: flex;
  justify-content: space-between;
}

.fcard {
  max-width: 132px;
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px 8px;
  background-color: #fff;
}

.fcard span {
  font-weight: 600;
}

.fcard .score-board .team-name {
  display: flex;
  justify-content: space-between;
}

.score-board h5 {
  font-size: 13px;
  margin-top: 9px;
  color: #f1582a;
  text-decoration: underline;
}

.score-update {
  display: flex;
  justify-content: space-between;
}

.score-update p:last-child {
  font-weight: 700;
}

.score-update p {
  margin-bottom: 2px;
  font-size: 13px;
}

.fcard {
  margin-right: 6px;
}

.score-update p:first-child::before {
  content: ">";
  display: block;
  position: absolute;
  left: 0;
}

.score-update p {
  margin-bottom: 2px;
  font-size: 13px;
  position: relative;
  padding-left: 10px;
}

p.text-bold {
  font-weight: 700;
  color: #161515;
  font-size: 23px;
  padding-right: 9px;
  margin-bottom: 0;
}

.session .s-wrap {
  max-width: 300px;
  width: 100%;
  background-color: #fff;
  padding: 10px 12px;
  border-radius: 7px;
}

.card-content h5 {
  font-size: 16px;
}

.session .s-wrap {
  margin-right: 17px;
}

.card-content {
  margin-left: 20px;
}

.session .s-wrap {
  margin-top: 23px;
}

.session {
  display: flex;
  flex-wrap: wrap;
}

.card-content h6 {
  white-space: nowrap;
  /* width: 247px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-content.d-flex img {
  max-width: 4px;
  width: 100%;
}

.l-card .wrap .l-title .dots {
  max-width: 4px;
}

.actions {
  border: 0;
  background-color: transparent;
}

.actions::after {
  display: none;
}

.acts:hover>.dropdown-menu {
  display: block;
  top: 20px;
}

a.dropdown-item img {
  width: 16px;
  margin-right: 11px;
  height: 16px;
  object-fit: contain;
}

/* .create_tournament_modal .modal.show .modal-dialog {
  max-width: 600px;
  width: 100%;
  margin-top: 80px;
}  */


.create_tournament_modal .modal-dialog {
  max-width: 600px;
  width: 100%;
  margin-top: 80px;
}

.modal-title {
  padding: 1rem 0 0 2rem;
}

.l-card.create-league .wrap button {
  margin: 6px 0;
  /* padding: 18px 13px; */
}

.schedule-1 {
  max-width: 125px;
  width: 100%;
  display: inline-block;
  /* margin-left: 32px; */
}

.modal-dialog .btn-secondary {
  padding: 13px 21px;
}

select.form-control {
  background-size: 16px;
}

/* form.season-popup {
  padding: 1rem 0rem;
} */

form.season-popup button {
  padding: 9px 21px;
  margin-top: 11px;
  border-radius: 6px;
  border: 0;
  background-color: #F1582B;
  color: #fff;
}

.copy-right {
  padding: 14px;
  background-color: #000;
  color: #fff;
}

.l-card.create-league .wrap {
  max-width: 206px;
  margin: 0 auto;
  width: 100%;
}

.l-card.create-league {
  padding: 20px;
}

.qr.qr-seperate {
  margin-bottom: 21px;
  margin-top: 24px;
}

span.mem-no .form-control {
  border: 0 !important;
  font-size: 16px !important;
  text-align: center;
  cursor: auto;
  font-weight: 500;
  margin: 6px 0 !important;
  padding: 0;
  letter-spacing: 0;
  height: auto !important;
}

span.mem-no .flag-dropdown {
  display: none;
}

.card-content a h6 {
  /* max-width: 91px; */
  width: 100%;
  display: inline;
  background-color: #2d2b2b;
  padding: 15px 45px;
  border-radius: 10px;
  color: #fff;
}

.l-card.create-league {
  padding: 15px 22px 5px 22px;
}

.menuCol.menu-first svg {
  max-width: 24px;
  height: 20px;
  margin-right: 3px;
}

.menuCol a {
  padding: 7px 8px;
}

.l-card.create-league .wrap button {
  width: 100%;
}

.l-card.create-league {
  display: flex;
  align-items: center;
}

.create-form-inner input,
.create-form-inner select {
  height: 50px;
}

.groups-count strong {
  word-break: break-word;
}

.modal-body .btn-close {
  position: absolute;
  top: 18px;
  z-index: 2;
}

.create-from h4 {
  margin-bottom: 28px;
}

footer {
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
}

button.btn.filter-btn {
  height: 58px;
  width: 100%;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.group.total-groups strong {
  display: flex;
  justify-content: center !important;
  color: #1D90E5;
}

.pay-btn {
  font-size: 12px;
  padding: 5px 10px;
  margin-left: -14px;
}

.groups-count {
  align-items: flex-start;
  justify-content: space-between;
}

.dropdown.acts .dropdown-item:active {
  background-color: transparent;
  color: #323131;
}

img.p-icon {
  max-width: 13px;
  margin-left: 9px;
}

img.t-icon {
  max-width: 20px;
  width: 100%;
}

img.t-icon {
  max-width: 27px;
  width: 100%;
  margin-right: 6px;
}

img.register-ico {
  max-width: 23px;
  width: 100%;
  margin-right: 5px;
}

ol.breadcrumb li {
  font-size: 17px;
}

.l-card.create-league .wrap button {
  padding: 15px 13px;
  background: #27AE60 !important;
  border: 1px solid #27AE60 !important;
  min-height: 44px;
  max-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 10px;
}

.side-navbar ul li {
  margin-bottom: 19px;
  list-style: none;
  padding: 11px;
}

.side-navbar ul li a {
  color: #000;
  margin-left: 14px;
}

.side-navbar {
  padding: 2rem 2rem;
}

.side-navbar {
  background-color: #fff;
  border-radius: 11px;
  max-width: 297px;
  width: 100%;
  height: 100%;
}

.side-navbar p {
  padding: 10px;
  background-color: #f1582a;
  border-radius: 7px;
  text-align: center;
}

.side-navbar p strong {
  color: #ffffff;
  font-weight: 400;
}

.side-navbar ul li.active {
  list-style-type: none;
  background-color: #cccccc30;

  border-radius: 5px;
}

.side-navbar ul {
  padding-left: 4px;
}

.side-navbar ul li.active {
  background-color: #cccccc30;
}

select.form-select.fs1 {
  max-width: 362px;
  width: 100%;
  font-size: 14px;

}

.outer-warp select {
  max-width: 100px;
  width: 100%;
  font-size: 14px;
}

body {
  background-color: #eee !important;
}

.standing-table thead tr,
.standing-table tr {
  border-top: none;
  border-bottom: 1px solid #E9E9E9;
}

.standing-table tr th,
.standing-table tr td {
  font-size: 14px;
  padding: 13px 9px !important;
}

.flag-img img {
  margin: 0 12px;
  max-width: 40px;
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
}

.flag-img span {
  font-weight: 500;
}

td.color-changes {
  color: green !important;
}

.standing-table tr th {
  max-width: 63px;
  font-size: 13px;
}

table.table tr td {
  color: #838080;
}

.standing-table a {
  color: #000;
  text-decoration: underline !important;
}

.select-calender img {
  max-width: 38px;
  width: 100%;
}

.weekly-updates ul {
  display: flex;
  list-style-type: none;
  padding-left: 2px;
}

.calender-daily {
  display: flex;
  background-color: #fff;
  border-radius: 7px;
  align-items: center;
  padding: 0 16px;
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  height: 114px;
  justify-content: center;
}

.calender-daily ul {
  padding: 11px 6rem;
}

.ui .prev {
  position: absolute;
  top: -10px;
  z-index: 0;
  left: 0;
}

.ui .next {
  position: absolute;
  right: 0;
  z-index: 0;
  top: -10px;
}

.weekly-updates a {
  font-size: 12px;
  color: #262626;
}

.weekly-updates ul {
  display: flex;
  list-style-type: none;

  margin-bottom: 0;
  padding: 12px 20px 10px 0;
}

.weekly-updates li {
  text-align: center;
  border-right: 1px solid #cccccc4f;
  padding-right: 20px;
  padding-left: 20px;

}

.weekly-updates li a p {
  font-size: 12px;
  font-weight: 400;
}

.weekly-updates a {
  font-weight: 600;
}

.score-past img {
  margin-left: 0;

}

.score-past tr td {
  color: #484444 !important;
}

.team-match p {
  font-weight: 600;
  color: #1b1a1a;
}

.side-navbar {
  height: 650px;
}

table {
  background-color: #fff;
}

tr.team-head th {
  font-size: 18px;
}

table.table-match td,
table.table-match th {
  padding: 13px 10px !important;
  white-space: nowrap;
}

.dashboard-search-input {
  margin-top: -16px;
}

.groupwrap {
  max-width: 100%;
  border-radius: 12px;
  /* padding: 20px 20px 24px; */
  /* background-color: #fff; */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  align-items: center;
}

.cfirst {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 185px;
  min-height: 210px;
  width: 100%;
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%), 0 6px 20px 0 rgb(0 0 0 / 9%); */
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid #cccccc9e;
  flex-wrap: wrap;
  position: relative;
}

.dropdown_team {
  position: absolute;
  right: 10px;
  top: 10px;
}

.other-info {
  padding-top: 21px;
}

.aboutgrp p {
  font-size: 23px;
  font-weight: 600;
  margin-top: 17px;
  margin-bottom: 0;
}

.other-info {
  margin: 0 25px 0;
}

.aboutgrp p {
  margin-top: 0;
  padding-left: 0;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
  padding-bottom: 0;
  line-height: 20px;
}

.aboutgrp {
  margin-left: 20px;
}

.groupicon {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.selected_game_wrap {
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  margin-top: 16px;
  min-height: 453px;
}

.selected_team_profile_section {
  border-radius: 20px;
  border: 1px solid #D3D3D3;
  background: #FFF;
  padding: 20px;
  margin-bottom: 20px;
}

.selected_team_qr_section {
  border-radius: 20px;
  border: 1px solid #D3D3D3;
  background: #FFF;
  padding: 20px;
}

.qr-wrap svg {
  width: 100%;
  height: 100%;
  max-width: 180px;
  max-height: 180px;
}

.qr-div .qr_sec_content p {
  margin-bottom: 0;
}

.selected_team_qr_section .qr-div {
  display: flex;
  gap: 20px;
}

.selected_team_qr_section .qr_sec_content button {
  width: 100%;
  min-width: 168px;
  min-height: 38px;
  background: #298CEA;
  border-radius: 6px;
}

.selected_team_qr_section .qr_sec_content button:hover {
  background: #298CEA;
}

.qr_sec_content p strong {
  color: #F05829;
  display: block;
  font-size: 20px;
  font-weight: 500;
}

.team_code_span {
  margin: 10px 0 20px;
  display: block;
}

.grpdocuments p {
  font-size: 15px;
}

.doc1 svg {
  max-width: 81%;
}

.other-info strong {
  font-size: 16px;
  background-color: #ff8a66;
  padding: 7px 15px;
  color: #fff;
  border-radius: 11px;
  font-weight: 500;
}

button.butn-bg {
  background-color: #f1582a;
  color: #fff;
  border: 0;
  padding: 8px 20px;
  border-radius: 8px;
  display: none;
}

.cfirst img {
  border-radius: 53%;
}

.c-details span {
  font-size: 14px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.c-details svg {
  margin-right: 4px;
}

.coaches {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-bottom: 20px;
  gap: 20px;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -o-gap: 20px;
}

.other-info p {
  padding-left: 0;
  font-size: 16px;
}

.grpdocuments {
  margin: 30px 0;
}

.players-card {
  margin-top: 0;
  height: 640px;
  overflow-y: auto;
}

.cfirst .verified {
  position: absolute;
  top: 8px;
  left: 6px;
  font-size: 12px;
  background-color: #dbffea;
  padding: 3px 8px;
  border-radius: 11px;
}

.cfirst {
  position: relative;
}

.players-card p.c-name {
  margin-top: 10px;
  margin-bottom: 3px;
  color: #000;
  font-weight: 600;
}

p.dob {
  font-size: 16px;
  margin-bottom: 4px;
  text-align: center;
}

.c-details {
  text-align: center;
}

.coaches .btn.selected_team_view_profile {
  border: 0;
  color: #27AE60;
  background: #DDFFEB;
  margin: 10px 0;
}

.coaches .btn.selected_team_view_profile:hover {
  color: #27AE60;
  background: #DDFFEB;
}

.players-card .cfirst {
  max-width: 206px !important;
  width: 100%;
}

p.c-name {
  margin-top: 0;
  color: #000;
  font-weight: 600;
  margin-bottom: 5px;
}

.grade-dash {
  background-color: #fff;
  border-radius: 11px;
  max-width: 297px;
  width: 100%;
}

.grade-content {
  width: 100%;
}

.search-input {
  display: flex;
  justify-content: end;

}

.search-input {
  position: relative;
}

.search-input .s-icon {
  position: absolute;
  top: 11px;
  right: 9px;
}

.coaches .btn:hover {
  color: #fff;
  background-color: #f05829;
}

.total-players {
  font-size: 18px;
  font-weight: 600;
  color: #212020;
}

img.player-img {
  border-radius: 50%;
  width: 36%;
}

p.mb-0.sports span {
  color: #f1582a;
}

p.mb-0.sports {
  font-size: 14px;
  padding-left: 0;
  margin-top: 6px;
}

.d-flex.img-wraps {
  max-width: 132px;
  width: 100%;
}

.pd img {
  max-width: 21px;
  width: 100%;
  margin-right: 5px;
}

.age-grp span {
  text-align: center;
  font-size: 14px;
}

.age-grp {
  display: flex;
  flex-direction: column;
}

.age-grp p {
  color: #208dd4;
  margin-bottom: 8px !important;
}

.team-count {
  background-color: #f1582a;
  padding: 4px 9px;
  border-radius: 6px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}

.teams p.c-name {
  text-align: center;
}

.age-grp p {
  text-align: center;
}

.teams .cfirst {
  max-width: 300px;
}

.tabbing_all_games ul.mb-3.nav.nav-tabs .nav-item {
  padding-bottom: 0;
  width: 25%;

}

.nav-tabs .nav-link.active {
  color: #f88967;
  font-weight: 600;
}

.nav-tabs .nav-link {
  color: #000000;
}

.nav-tabs .nav-link:hover {
  color: #000000;
}

input#exampleFormControlInput1:focus {
  border-color: #d7d7d7;
}

.horizontal {
  width: 1px;
  height: 128px;
  background-color: #d3d3d3;
  margin: 0 2rem;
}

.qr {
  max-width: 121px;
  text-align: center;
}

.qr button {
  padding: 2px 14px;
  margin-top: 8px;
  font-size: 14px;
}

.nav-tabs .nav-link.active:hover {
  color: #f88967;
}

.weekly-updates li:last-child {
  border-right: unset;
}

.next {
  display: flex;
  align-items: center;
}

.next .material-icons {
  background-color: #fff;
  border: 1px solid #f1582a;
  color: #f1582a;
  padding: 1px 8px;
  border-radius: 7px;
}

.prev {
  display: flex;
  align-items: center;
}

.prev .material-icons {
  background-color: #fff;
  border: 1px solid #f1582a;
  color: #f1582a;
  padding: 1px 8px;
  border-radius: 7px;
}

button.prev,
button.next {
  border: 0;
  background-color: transparent;
}

ul.carousel.weekly-updates .card {
  border: 0;
  border-right: 1px solid #cccccc4f;
}

/* score */

.carouselwrapper {
  overflow: hidden;
  background: var(--color-lightgrey);
  position: relative;
}

.new_carousel .calender-daily {
  max-width: 1420px;
  overflow: hidden;
  overflow-x: scroll;
}

.new_carousel .calender-daily .ui {
  display: none;
}

.new_carousel .calender-daily li.border.text-center {
  width: 270px;
  min-width: 270px;
}

.new_carousel .calender-daily li.border.text-center p {
  margin-bottom: 0;
}

.carousel {
  width: var(--carouselWidth);
  margin: 0 0 0 var(--carouselLeftMargin);
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.prev {
  animation-duration: .6s;
  animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  animation-name: prev;
}

.next {
  animation-duration: .6s;
  animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  animation-name: next;
}

li {
  /*padding: 50px; */
  text-align: center;
  width: calc(var(--cardWidth) - var(--card-margin) * 2);
  box-sizing: border-box;
}


.ui {
  position: absolute;
  top: 56%;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: var(--card-margin);
  z-index: 2;
  padding: 0 19px;

}

.calender-daily ul {
  background-color: #fff;
  border-radius: 8px;
  padding-left: 0;
  padding: 9px;
  width: 100%;
  justify-content: center;
}

.calendar_icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.weekly-updates .card {
  box-shadow: 0px 1px 2px 0px var(--card-shadow);
  border-radius: 4px;
  margin: var(--card-margin);
  background: var(--color-white);
  max-width: 100px;
  height: 98px;
  max-width: 164px;
  /* height: 98px; */
  display: flex;
  align-items: center;
  justify-content: center;


}

@keyframes next {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(calc(0px + var(--cardWidth)));
  }
}

@keyframes prev {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(calc(0px - var(--cardWidth)));
  }
}

ul.carousel.weekly-updates .card:last-child {
  border-right: 0;
}

ul.carousel.weekly-updates p {
  font-size: 14px;
}

ul.carousel.weekly-updates span {
  font-size: 15px;
  font-weight: 600;
}

.l-card.c-loader {
  padding: 20px;
}

.mc-loader {
  background-color: #fff;
  padding: 20px 10px 10px;
  border-radius: 8px;
  height: 100% !important;
}

.mc-loader svg {
  max-width: 200px !important;
  width: 100%;
  height: 90px;

}

.l-card.c-loader svg {
  max-width: 305px;
  width: 100%;
}

ul.dropdown-menu li {
  cursor: pointer;
}

.card-content h6 {
  color: #000;
  margin-left: 10px;
  /* font-weight: 600; */
}

.text-group p {
  margin-bottom: 2px;
}

.side-navbar ul li {
  cursor: pointer;
}

.group-dashboard.nav.nav-pills {
  display: flex;
  flex-direction: column;
}

.group-dashboard.nav.nav-pills .nav-link.active {
  background-color: rgb(236 236 236);
  font-size: 15px;
}

.group-dashboard.nav.nav-pills .nav-link {

  width: 100%;
  height: auto;
  color: #000;
  margin-top: 18px;
  padding: 0px;
  font-size: 15px;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif, sans-serif !important;
  background-color: #fff;
  text-align: start;
  padding: 6px 15px;
}

.group-dashboard.nav.nav-pills .nav-item {
  padding: 0;
}

p.dash img {
  display: none;
}

/* .grade-content{
  padding-left: 2rem;
} */
/* button.btn.ss.btn-outline-primary {
  padding: 4px 10px;
  font-size: 14px;
  border-color: #f1582a;
  color: #fff;
  background-color: #f1582a;
} */

button.btn.ss.btn-outline-primary {
  padding: 4px 10px;
  font-size: 14px;
  border-color: #27ae60;
  color: #fff;
  background-color: #27ae60;
}



button.btn.ss.btn-outline-primary img {
  width: 19px;
  margin-right: 2px;
}

.create-next.submission img {
  max-width: 15%;
  margin-right: 7px;
}

.modal-body .modal-title {
  padding-left: 0px;
}

.dashboard-auto-wrapper {
  padding-top: 0;

}

.modal-body {
  padding: 6px 24px;
}

button.btn.btn-dark.savesubmit.add-team-icon {
  max-width: 310px;
  width: 100%;
  margin-top: 17px;
}

.match-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.l-title .card-content {
  margin-left: 0;
}

.delete-popup-content {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  padding: 1.7rem 0;
  text-align: center;
  max-width: 530px;
  width: 100%;
}

.delete-popup-content p {

  font-size: 20px;
  width: 64%;
  margin: 0 auto;
  padding-bottom: 2rem;
  font-size: 18px;
}

.delete-popup-btn.cancel {
  background: #f05829;
  color: #ffffff;
  padding: 9px 35px;
}

.delete-popup-btn.delete {
  background: #f05829;
  color: #fff;
  padding: 9px 35px;
}

.l-card.create-league .wrap {
  text-align: center;
}

.add-ven-form {
  margin-top: 0 !important;
  padding-top: 12px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-13cymwt-control {
  height: 52px;
}

.css-qbdosj-Input {
  height: 40px;
}

button.addfield-btn {
  margin: 4px 0;
  background-color: #f1582a;
  border-color: #f1582a;
  color: #ffff;
  font-size: 14px;
  height: 44px;
  width: 122px;
  border-radius: 3px;
  border: 0;

}

.add-files {
  text-align: end;
}

button.cross-btn {
  padding: 0px 11px;
  margin-left: 8px;
  height: 46px;
  background: #fff;
  border-radius: 3px;
  border: 0;
  border: 1px solid #ccc;
  color: #000;
}

button.cross-btn span {
  color: #000;
}

.Inputlist_div {
  display: flex;
  margin-top: 1.3rem;
}

.addd-ven img {
  max-width: 15px;
}

.l-card.venue img {
  max-width: 85px;
  border-radius: 50%;
  height: 85px;
  object-fit: cover;
}

.l-card.venue .date {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.l-card.venue .date p {
  margin-bottom: 13px !important;
}

.addd-ven {
  padding-top: 19px;
  padding-left: 0;
  padding-right: 0;
}

/* .members img {
  max-width: 55px;
  width: 100%;
  margin-bottom: 14px;
} */

.members .date {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.date-card p {
  text-align: left;
  margin-bottom: 5px !important;
  line-height: 26px;
  font-size: 16px;
  color: #484848;
  width: 126px;
  word-wrap: break-word;
}

.create-form-inner.member-edit {
  padding: 0;
  padding-top: 1rem;
}

.cperson-img img {
  width: auto;
  height: auto;
}

.cperson-img {
  margin-right: 10px;
  width: 100%;
}

.member-create {
  padding: 0;
  padding-top: 1rem;
}

.input-find input {
  width: 86%;
}

.input-find {
  margin-bottom: 20px;
}

.input-find input {
  width: 80%;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 10px;
  margin-right: 11px;
}

.input-find button {
  padding: 11px 26px;
}

.create-form-inner.pt-2.add-teams {
  padding: 0 16px;
}

.create-match {
  padding: 12px 0;
  width: 100%;
  height: calc(91vh - 85px);
  position: relative;
}

.parking-check .custom-checkbox input[type="checkbox"] {
  opacity: 1;
  position: absolute;
  margin: 5px 0 0 3px;
  z-index: 9;
  height: 30px;
  width: 30px;
  top: 36%;
  left: -7px;
}

.parking-check .form-check-input:checked,
.foodcheck .form-check-input:checked {
  background-color: #f05829;
  border-color: #f05829;
}

.foodcheck .custom-checkbox input[type="checkbox"] {
  opacity: 1;
  position: absolute;
  margin: 5px 0 0 3px;
  z-index: 9;
  height: 30px;
  width: 30px;
  top: 36%;
  left: -7px;
}

.create-match .form-group.summary.mb-4 {
  margin-top: 18px;
}

.create-match-btn {
  max-width: 200px !important;
  margin-bottom: 0;
  text-align: center;
  position: absolute;
  /* bottom: 0px; */
  left: 25%;
}

.create-match-wrap .custom-pop-box {
  padding-bottom: 0;
}

.season-mange .groups-count {
  display: flex;
  padding-top: 2rem;

}

/* 
span.menu-title.league-tourna {
  font-size: 14px;
} */

.season-mange .groups-count .group {
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-right: 11px;
  gap: 9px;
  align-items: center;
}

.l-card .wrap {
  cursor: pointer;
}

.form-group label {
  color: #757575;
  font-weight: 600;
}

.time-slots .form-group input {
  background: rgb(224 224 224 / 20%);
  border: 1px solid #ccc;
  height: 46px;
  width: 100%;
  border-radius: 7px;
  padding: 4px 16px;
}

select.form-control {
  background: rgb(224 224 224 / 20%);
}

.add-slot-btn .btn {
  padding: 10px 9px;
}

.add-slot-btn .btn {
  width: 25%;
}

.add-slot-btn {
  width: 100%;
  display: flex;
}

.add-slot-btn img {
  width: 10%;
}

.time-slot-create {
  max-width: 234px;
}

.common-plus {
  width: 40px;
}

/* table.table-match tr th {
  font-size: 13px;
} */

.l-card.create-league .wrap {
  max-width: 100%;
}

.cperson-img img {
  cursor: pointer;
}

.groupicon img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.cfirst img {
  border-radius: 50%;
  margin-top: 16px;
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: top center;
}

img.plus-icon-craete {
  width: 8%;
  margin-right: 7px;
}

table.table-match tr td img {
  width: 34px;
  height: 34px;
  object-fit: cover;
  margin-right: 12px;
  border-radius: 50%;
}

.league-pay p {
  margin-right: 13px;
  margin-bottom: 0;
}

.league-pay {
  display: flex;
}

.groups-count.league {
  align-items: center;
  justify-content: space-between;
}

p.d-flex.mb-0.status-enable {
  background: transparent;
  color: #7d7d7d;
  padding: 0;
}

.season-count strong {
  justify-content: flex-start !important;
}

button.butn-edit-profile {
  background-color: transparent;
  border-color: #f1582a;
  color: #f1582a;
  padding: 6px 22px;
}

button.butn-edit-profile:hover {
  background-color: #f1582a;
  border-color: #f1582a;
}

.del-item {
  position: absolute;
  top: 13px;
  right: 13px;
}

img.register-ico {
  width: 20% !important;
}

.msl-arrow-btn:hover,
.msl-clear-btn:hover {
  background: transparent;
}

.msl-input {
  margin: 10px;
}

.pop-title {
  text-align: left !important;

}

button.btn.btn-outline-primary.pay-btn {
  background-color: #f5ecff;
  border: 0;
  border-radius: 25px;
  padding: 6px 7px;
}

button.btn.btn-outline-primary.pay-btn strong {
  margin-right: 10px;
  margin-left: 10px;
  color: #000000;
}

.update-score h5 {
  font-size: 29px;
}

.highlightOption {
  background: #e5e5e5 !important;
  color: #000 !important;
}

.team-loader {
  background-color: #fff;
  border-radius: 16px;
  height: 500px;
}

.multiSelectContainer li:hover {
  background: #e5e5e5 !important;
  color: #000 !important;
}

.create-teamf {
  height: 500px;
  overflow-y: auto;
}

.create-teamf .searchBox {
  border: 0;
}

.chip {
  background: #505050 !important;
}

.groups-count.team-list {
  justify-content: space-between;
}

.team-list-side {
  margin-top: -1rem;
  /* width: calc(100% - 300px); */
  width: 100%;
}

.team-list-breadcrumb {
  background-color: #eee;
  width: 100%;
  padding-bottom: 3px !important;
}

.grade-content.team-list-side .breadcrumb {
  display: none;
}

/* .qr .qr-wrap{
  max-width: 130px !important;
    width: 100%;
    margin-bottom: 15px !important;
} */

/* textarea.form-control {
  height: auto;
  min-height: 233px;
} */

.score-final span {
  font-size: 13px;
}

.weekly-updates li:nth-child(3) p {
  color: #000000;
  font-weight: 500;
}

.weekly-updates li:nth-child(3) {
  background-color: #ffe7e0;
  color: #f1582a;
}

.score-past thead tr th {
  width: 1%;
}

.score-final strong {
  margin-left: 8px;
  background-color: #f1582a;
  color: #fff;
  border-radius: 5px;
  padding: 1px 8px;
  font-size: 11px;
  height: 18px;
  margin-right: 8px;
}

table.table-match tr th {
  font-size: 16px;
  position: sticky;
  top: 0;
  background-color: #fff;
}

.venue-slots {
  height: 650px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 10px;
}

.venue .groups-count .group {
  margin-right: 28px;
}

.venue .groups-count {
  justify-content: space-between;
  align-items: center;
}

.season_active h6 {
  margin: 0 0px 0px 8px;
  padding: 0;
}

.season_active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.season-icon svg {
  max-width: 80px;
  height: 80px;
  width: 100%;
  min-width: 80px;
  margin-right: 10px;
}

.season_active h6 {
  width: 100%;
  min-width: 207px;
  max-width: 207px;
  font-weight: 600;
}

.venue .groups-count .group {
  margin-right: 0;
}

.venue .btn {
  background-color: #f1582a !important;
  color: #ffffff;
  border-radius: 5px !important;
  padding: 7px 9px !important;
  font-size: 11px;
}

.l-card.venue .l-title {
  position: absolute;
  right: 14px;
}

.l-card.venue {
  position: relative;
}

.side-navbar.venue-side {
  height: auto;
}

.side-navbar.venue-side .nav-item a {
  margin-bottom: 15px !important;
}

.venue-sidenav {
  height: 574px;
  overflow-y: auto;
  padding: 0 !important;
}

.side-navbar.venue-side {
  height: 650px;
  padding: 15px;
}

td.del-cio img {
  max-width: 21px !important;
  width: 100% !important;
  height: auto !important;
}

.time-input input {
  max-width: 140px;
  margin-right: 12px;
}

.venue-tables select {
  max-width: 152px;
}

.venue-tables input {
  max-width: 169px;
}

.group-dashboard.nav.nav-pills .nav-item span {
  font-size: 14px;
}

.group-dashboard.nav.nav-pills .nav-item a {
  margin-bottom: 0 !important;
}

.group-dashboard.nav.nav-pills .nav-item hr {
  margin: 10px 0;
  height: auto !important;
}

.back-btn {
  background-color: #000000 !important;
}

.address-venuee {
  height: 65px;
}

.l-card.venue .date p,
.group.total-groups p {
  font-size: 16px;
  margin-bottom: 0;
}

.team-head th {
  position: sticky;
  top: 0;
}

img.match-icons {
  width: 19px !important;
  object-fit: contain !important;
  cursor: pointer;
}

td.del-cio span {
  cursor: pointer;
}

.ven-form .cperson-img img {
  width: 86px;
  height: 86px;
  object-fit: cover;
}

.wrap.mem-card .l-title {
  position: absolute;
  right: 14px;
  display: block;

}

.wrap.mem-card {
  position: relative;
}

.members img {
  width: 70px !important;
  object-fit: cover;
  height: 70px;
  border-radius: 50%;
  max-width: unset !important;
  margin-bottom: 0;
}

.members {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -o-gap: 20px;
}

.match-add {
  width: 14%;
}

/* .calender-daily .active span {
  color: #f1582a;
  font-weight: 600;
} */

.team-search {
  max-width: 280px;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  min-width: 280px;
}

.team-search .s-icon {
  right: 13px;
  position: absolute;
  top: 9px;
}

.team-search svg {
  position: absolute;
  right: 10px;
  top: 8px;
}

/* .calender-daily .active span {
  color: #f1582a;
  font-weight: 600;
} */


.form-group.mb-4.add-files input {
  max-width: 90%;
}

.form-group.mb-4.add-files {
  position: relative;
  height: 123px;
  overflow-y: auto;
}

button.addfield-btn {
  position: absolute;
  top: -4px;
  right: 2px;
  width: auto;
  padding: 0px 12px;
  font-size: 23px;
}

.modal-dialog .create-form-inner {
  padding-left: 0;
  padding-right: 0;
}

.modal-body .cperson-img img {
  width: 84px;
  height: 63px;
  object-fit: cover;
  border-radius: 50%;
}

.member-create .input-find input {
  width: 79%;
}

.grade-dash {
  margin-top: -15px;
}

/* .mem-l-card {
  max-width: 23%;
  width: 100%;
} */

.wrap.mem-card {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
}

.mem-l-card .groups-count .group {
  margin-right: 10px;
}

.wrap.mem-card .l-title {
  position: absolute;
  right: 8px;
  top: 0;
  z-index: 7;
  max-width: 16px;
}

.mc-loader {
  display: flex;
  flex-direction: column;
}

.type-mem p {
  font-size: 13px;
}

.type-mem p {
  display: flex;
  background-color: #e9f5e9;
  max-width: 96px;
  border-radius: 5px;
  color: #499449;
  font-weight: 500;
  justify-content: center;
  margin-bottom: 0;
}

.member-search .team-search {
  margin-right: 9px;
}

.mem-filter .form-check {
  margin-bottom: 0;
  margin-left: 4px;
}

.d-flex.align-items-center.member-search .team-search {
  margin-bottom: 0;
}

/* .mem-filter {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
} */

.mem-filter .form-check input {
  font-size: 24px;
  margin-top: -1px;
}

.mem-filter .form-check label {
  font-size: 16px;
  font-weight: 500;
}

.mem-filter .form-check input:checked {
  background-color: #f1582a;
  border-color: #f1582a;
}

.mem-filter .form-check input:focus {
  border-color: #fff;
}

.mem-filter .team-search {
  margin-bottom: 0;
  margin-left: 15px;
}

.group-modal {
  padding: 20px;
  padding-bottom: 0;
}

.mem-filter .btn {
  padding: 9px 27px;
}

.match-outer {
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  overflow: auto;
}

.table-responsive.score-tab {
  background-color: #fff;
  border-radius: 5px;
}

span.score-pt {
  background-color: #e7e7e7;
  margin-left: 0;
  border-radius: 5px;
  padding: 1px 4px;
  font-size: 11px;
  display: table-caption;
  margin-top: 6px;
  font-weight: 600;
}

.enroll-tab .table-scroll {
  border: 0;
}

.edit-venue-field .addfield-btn {
  top: 16px;
  right: 0;
}

.form-group.mb-4.text-end.edit-venue-field {
  height: 151px;
  overflow-y: auto;
}

.form-group.mb-4.text-end.edit-venue-field:hover::-webkit-scrollbar {
  display: block;
  transition: all 0.4s ease-in-out;
}

.form-group.mb-4.text-end.edit-venue-field::-webkit-scrollbar {
  display: none;
  transition: all 0.4s ease-in-out;
}

.form-group.mb-4.text-end.edit-venue-field::-webkit-scrollbar-track {
  -webkit-box-shadow: transparent;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.form-group.mb-4.text-end.edit-venue-field::-webkit-scrollbar {
  width: 6px;
  height: 80px;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.form-group.mb-4.text-end.edit-venue-field::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  transition: all 0.4s ease-in-out;
}

.coach {
  padding: 8px 15px;
  background-color: white;
  border-radius: 9px;
  color: #f1582a;
}

.coach strong span {
  background-color: #ececec;
  padding: 1px 5px;
  border-radius: 5px;
  color: #000000;
  margin-left: 6px;
}

.coach strong {
  color: #f1582a;
  font-weight: 600;
}

.coach strong {
  font-size: 14px;
}

.mem-filter .tab-content {
  display: none;
}

.mem-filter .nav {
  border: 0;
}

.mem-filter .nav-tabs .nav-link {
  border-radius: 9px;
  /* border: 1px solid #ccc; */
  margin-right: 11px;
  background-color: #fff;
  border: 0;
}

.mem-filter .nav-tabs .nav-link.active {
  font-weight: 500;
  background-color: #298CEA !important;
  color: #fff !important;
  border: 0;
}

.nav-tabs .nav-link.active:hover {
  color: #ff8a66;
}

.input-icon input.form-control {
  padding-left: 37px;
  padding-top: 2px;
  padding-bottom: 3px;
  height: 51px;
}

.qr-div .qr {
  width: 100%;
  max-width: 100%;
}

.qr.qr-seperate {
  max-width: 100%;
}

.groupicon.qr-team-logo .other-info {
  margin: 0 8px;
  padding: 5px;
}

.qr.qr-seperate {
  max-width: 620px;
  width: 100%;
  background-color: #FFF4F0;
  border-radius: 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}

.qr.qr-seperate .groupname {
  max-width: unset !important;
}

p.text-center.qr-code-text {
  padding-top: 1.2rem;
  font-size: 22px;
}

.qr-sep {
  max-width: 240px;
}

.qr-wraps {
  max-width: 350px;
  width: 100%;
  background-color: #fff;
  padding: 2rem;
  display: flex;
  justify-content: center;
  margin-top: 23px;
  border-radius: 19px;
}

.qr-seperate button {
  padding: 8px 24px;
  margin-top: 8px;
  font-size: 14px;
}

button.btn.btn-dark.savesubmit.payment-btn img {
  max-width: 17px;
}

img.register-ico.del-whi {
  max-width: 49px;
}

.enro p {
  margin-top: 21px;
  color: #939090;
}

.enro {
  text-align: center;
  margin-top: 9rem;
}

.enro img {
  max-width: 69px;
  width: 100%;
}

.b-logo img {
  max-width: 200px;
  margin-bottom: 14px;
}

.qr-team-logo img {
  width: 105px;
  height: 105px;
  border-radius: 50%;
}

.qr-team-logo p {
  padding-left: 0;
  font-size: 23px;
}

.qr-team-logo .other-info {
  padding-top: 13px;
}

.qr-team-logo .other-info strong {
  font-size: 15px;
  padding: 6px 11px;
  background-color: #f1592a;
}

.qr-team-logo {
  padding-left: 1rem;
}

.qr-copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  /* width: 94%; */
  max-width: 340px;
  width: 100%;
}

.qr-copy p {
  background-color: #fff;
  padding-top: 0 !important;
  margin-bottom: 0;
}

.qr-box {
  padding: 8px 17px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #cccccc7d;
  text-align: initial;
  width: 86%;
}

.copy-code {
  background-color: #f1592a;
  display: flex;
  justify-content: center;
  padding: 11px;
  border-radius: 5px;
  cursor: pointer;
}

.qr-copy p {
  color: #000;
  font-weight: 600;
  font-size: 18px;
}

.form-group label {
  display: inline;
}

.calender-daily .active span {
  color: #ef5829;
  font-weight: 600;
}

.calender-daily .active p {
  color: #ef5829;
  font-weight: 500;
}

li.border.text-center {
  padding: 14px 22px;
}

li.border.text-center {
  padding: 0 30px;
  border: 0 !important;
}

.save-all {
  display: none;
}

.c-details span {
  word-break: break-word;
  font-size: 16px;
}


.c-details p {
  word-break: break-word;
  font-size: 16px;
}

.l-title>img {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  object-fit: cover;
}

.play-w {
  padding: 40px 20px 24px;
}

.p-d {
  border: 1px solid #ccc;
  padding: 9px;
  align-items: center;
  border-radius: 4px;
  margin-right: 20px !important;
  cursor: pointer;
  text-align: center;
}

.p-d p {

  font-size: 14px;
  font-weight: 600;
  color: #5a5a5a;
  margin-top: 10px;

}

.doc1.me-2.p-d img {

  height: 58px;
  object-fit: cover;
}

.play-w .nav-tabs {
  display: none;
}

.play-w .search-input {
  margin-bottom: 28px;
}

.play-w .teams .cfirst {
  max-width: 190px;
  width: 100%;
  margin-bottom: 20px;
}

.doc-img.modal-body img {
  max-width: 600px;
  width: 100%;
  border-radius: 6px;
}

.doc-img.modal-body {
  padding: 4rem 3rem;
}

form.season-popup span {
  /* font-size: 24px; */
  font-weight: 500;
}

.score-load {
  /* max-width: 460px !important; */
  width: unset !important;
}

.payments {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.leag-details {
  display: flex;
  align-items: start;
}

.league-details {
  margin-left: 14px;
}

.league-details h4 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 6px;
}

.league-details p strong {
  font-weight: 600;
  color: #F1592A;
}

.league-details p {
  font-size: 17px;
  margin-bottom: 0;
}

.team-top-fil {
  display: flex;
  align-items: center;
}

.team-heading {
  width: 100%;
  /* display: flex; */
  align-items: center;
  gap: 20px;
}

ul.mb-3.tabs-match.nav.nav-tabs {
  border: 0;
}

.tabs-match .nav-link.active {
  color: #fff;
  font-weight: 600;
  background: #f1582a;

  /* border: 1px solid #f1582a; */
  border-radius: 8px;
}

.tabs-match .nav-link.active:hover {
  color: #fff;
}

.tabs-match .nav-link {
  color: #000;
  background-color: #fff;
  margin-right: 10px;
  border-radius: 8px;
}

.tabs-match .nav-link:hover {
  color: #000;
}

.tabs-match .nav-link:hover {
  border-color: transparent;
}

tr.team-head {
  border: 0 !important;

  position: relative;
  z-index: 5;
}

.players-wrap .search-input {
  margin-left: 16px;
}

.generatematch {
  display: block !important;
}

.generatematch {
  background-color: #0006;
}

.generatematch .modal-header {
  border-bottom: 0 !important;
}

.generatematch .modal-dialog {
  max-width: 610px;
  width: 100%;
}

.generatematch .modal-footer {
  border-top: 0 !important;
  padding: 25px 21px;
}

.generatematch h5 {
  font-size: 23px;
  padding-left: 13px;
  padding-top: 7px;
}

.generatematch .col-md-6 {
  margin-bottom: 17px;
}

.generatematch select {
  padding: 10px;
}

.team-top-fil .btn {
  display: none;
}

/* .top-title.d-flex.justify-content-between.px-3 {
  margin-bottom: 20px;
} */

.venu-menue {
  display: flex;
  /* margin-bottom: 10px; */
  align-items: baseline;
}

.venu-menue span {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  margin-left: 4px;
}

table.table.table-striped.standing-table .flag-img {
  display: flex;
  align-items: center;
}



/* responsive */
@media(max-width:1440px) {
  .side-navbar {
    width: 145px;
    padding: 11px;
  }

  /* .group-dashboard.nav.nav-pills .nav-link {
    background-color: transparent;
  } */

  /* .group-dashboard.nav.nav-pills .nav-item span {
    display: none;
  } */

  p.dash strong {
    display: none;
  }

  p.dash img {
    display: block;
  }

  .side-navbar p {
    display: flex;
    justify-content: center;

  }

  .grade-dash {
    max-width: 107px;
  }

  .grade-content {
    width: 100%;
  }
}

button.btn.btn-dark.green.add.next-orange-btn.add-slot-btns {
  max-width: 135px;
  width: 100%;

}

/* new */

.update-score input {
  background: rgb(255 255 255 / 20%);
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 47px;
  padding: 9px 12px;
}

.uscore {
  max-width: 144px;
  padding: 10px 12px;
}

label {
  color: #757575;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: #f1582a;
  border-color: #f1582a;
}

/* 30-11-2023 */
.dashboard_current_game {
  height: 100%;
  max-height: 571px;
  overflow-y: auto;
}

.league_height {
  height: 52px !important;
}

.newCreateLeague .nav.nav-tabs {
  border: none;
  background: transparent;
}

.newCreateLeague .nav-link.active {
  border: none;
  border-bottom: 1px solid #f88967;
  border-radius: 0;
}

.newCreateLeague li.nav-item {
  margin-top: 0;
  padding: 0;
}

.ck.ck-editor__editable_inline>:last-child {
  height: 200px;
  margin-bottom: 20px;
}

.newCreateLeague .nav-tabs li.nav-item button {
  background: transparent !important;
}

/* .coach_image_detail {
  display: flex;
} */

.coach_image_detail {
  display: flex;
  min-width: 150px;
}

.coach_detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.coach_image_detail img {
  border-radius: 7px;
  object-fit: cover;
}

.coach_detail span:first-child {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.group-dashboard .nav-link .group_page_svg {
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 10px;
}

.custom-number-input {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 40px;
}


.top-nav {
  z-index: 999999 !important;
}

.acts ul.dropdown-menu li {
  text-align: left;
  margin: 0 0 10px 0;
  color: #484848;
}

a.dropdown-item.active {
  background: transparent;
}

.team_component .team_img {
  height: auto !important;
}

.team_component .dropdown-menu .dropdown-item img {
  width: 16px;
  height: 16px;
  object-fit: contain !important;
  border-radius: inherit !important;
}

/* 29/12/2023 */

.dashboard_megamenu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}


.dashboard_megamenu .dashboard_main_menus {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 30px; */
  width: 100%;
}

.dashboard_megamenu .dashboard_main_menus a svg {
  width: 19px;
  height: 19px;
  object-fit: contain;
}

/* Top Nav - 09/01/2024 */
.dashboard_main_menus a {
  margin-bottom: 20px;
}

.dashboard_main_menus h6 {
  font-size: 14px;
  color: #8D8D8D;
  margin-bottom: 10px;
}

.new_megamenu_ui {
  width: 100%;
}

.new_megamenu_ui ul {
  width: 100%;
  padding: 0;
  margin: 0;
}

.new_megamenu_ui ul li {
  width: 100%;
  padding: 0;
  margin: 0;
  transition: all 0.4s ease-in-out;
}

.new_megamenu_ui h6 {
  padding-left: 20px;
  padding-top: 20px;
}

.new_megamenu_ui ul li a {
  width: 100%;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
  font-size: 15px;
  position: relative;
}

.new_megamenu_ui ul li a .svg_topnav {
  position: absolute;
  right: 20px;
  top: -3px;
}

.new_megamenu_ui ul li:hover {
  background: #F05829;
  transition: all 0.4s ease-in-out;
}

.new_megamenu_ui ul li:hover a {
  color: #fff;
  transition: all 0.4s ease-in-out;
}

.new_megamenu_ui ul li:hover .svg_topnav {
  fill: #fff;
  transition: all 0.4s ease-in-out;
}

.new_megamenu_ui a {
  padding-left: 15px;
  transition: all 0.4s ease-in-out;
}

.new_megamenu_ui .mega_menu_content {
  width: 100%;
  max-width: 373px;
  background: #F0EAE8;
  position: absolute;
  left: 228px;
  min-width: 373px;
  top: 0;
  border: 1px solid #E6E6E6;
  text-align: left;
  display: none;
  transition: all 0.4s ease-in-out;
  min-height: 555px;
  overflow-y: auto;
  max-height: 555px;
  padding-bottom: 20px;
}

.new_megamenu_ui .mega_menu_content::-webkit-scrollbar {
  display: none;
}

.menu-item:hover .mega_menu_content {
  display: block;
  transition: all 0.4s ease-in-out;
}

.menu-item2:hover .mega_menu_content {
  display: block;
  transition: all 0.4s ease-in-out;
}

.mega_menu_content .heading_tournament {
  color: #8D8D8D;
  font-size: 14px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 20px;
}

.mega_menu_content .heading_tournament span a {
  color: #F05829 !important;
  font-size: 14px;
  font-weight: 600;
}

.mega_menu_content .heading_tournament span a:hover {
  color: #F05829;
}

.mega_menu_content ul li a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  -webkit-gap: 10px;
  -moz-gap: 10px;
  -o-gap: 10px;
}

.mega_menu_content .listing_teams_navbar a h4 {
  font-size: 15px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0;
}

.mega_menu_content .listing_teams_navbar {
  position: relative;
}

.mega_menu_content .listing_teams_navbar a .svg_topnav_main_content svg {
  position: absolute;
  right: 10px;
  top: 11px;
}

.active_status {
  width: 18px;
  height: 18px;
  background: #b3b3b3;
  border-radius: 50%;
}

.active_status.green {
  background: #27ae60;
}


.mega_menu_content span.mega_menus_listing_content_img_team_name {
  display: flex;
  align-items: center;
  gap: 10px;
  -webkit-gap: 10px;
  -moz-gap: 10px;
  -o-gap: 10px;
}

.mega_menu_content ul .season:hover {
  background: transparent !important;
  cursor: default;
}

li.listing_teams_navbar:hover a .mega_menus_listing_content_img_team_name h4 {
  color: #000;
}

li.listing_teams_navbar:hover a .mega_menus_listing_content_img_team_name svg {
  fill: #000 !important;
}

li.listing_teams_navbar:hover a .mega_menus_listing_content_img_team_name svg {
  fill: #fff;
}

.new_megamenu_ui .mega_menu_content ul li:hover {
  background: #fff;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
  -webkit-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
  -o-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
  -moz-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
}

span.vg_team_megamenu2 {
  color: #F05829;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.new_megamenu_ui .menu-item.active {
  background: #F05829;
}

.new_megamenu_ui .menu-item.active a {
  color: #fff;
}

.new_megamenu_ui .menu-item.active a:hover svg {
  fill: #fff;
}

.new_megamenu_ui .menu-item.active a svg.active_svg {
  fill: #484848;
}

.mega_menu_content .menu-item.listing_teams_navbar.active a .mega_menus_listing_content_img_team_name h4 {
  color: #fff;
}

.new_megamenu_ui .menu-item.listing_teams_navbar.active {
  background: #fff;
}

.new_megamenu_ui .menu-item.listing_teams_navbar.active .mega_menus_listing_content_img_team_name h4 {
  color: #000 !important;
}

.new_megamenu_ui .menu-item.listing_teams_navbar.active .mega_menus_listing_content_img_team_name svg {
  fill: #000 !important;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
  -webkit-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
  -moz-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
  -o-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
}

.new_megamenu_ui .menu-item.listing_teams_navbar.active:hover .mega_menus_listing_content_img_team_name svg {
  fill: #000 !important;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
  -webkit-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
  -moz-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
  -o-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.06));
}


.new_megamenu_ui .menu-item.active .mega_menu_content {
  display: block;
  transition: all 0.4s ease-in-out;
}

.position-relative {
  position: relative;
}

/* .position-relative .mega_menu_content2 {
  width: 100%;
  max-width: 535px;
  background: #E6E6E6;
  position: absolute;
  left:548px;
  min-width: 535px;
  top: -128px;
  border: 1px solid #E6E6E6;
  text-align: left;
  transition: all 0.4s ease-in-out;
  min-height: 555px;
  overflow-y: auto;
  max-height: 555px;
  overflow-x: hidden;
} */

/* .megamenu2_match_ul_li li span a h4 {
  font-size: 16px !important;
} */

.dashboard-inner ol.breadcrumb {
  margin: 0 0 0;
}

.create-league .wrap select.form-select {
  min-height: 40px;
  max-height: 40px;
  box-shadow: none;
}

.tournament_card_redesign .card-content h6 {
  font-size: 16px;
  font-weight: 600;
}

p.border_checked {
  width: 20px;
  height: 20px;
  background: green;
  border-radius: 50%;
  border: 1px solid #000;
}

.l-title .trophy_img {
  width: 100%;
  height: 100%;
  max-width: 80px;
  max-height: 80px;
  min-width: 80px;
  min-height: 80px;
}

.tournament_card_redesign .card-content {
  display: flex;
  margin-top: 7px;
  margin-left: 13px;
}

.l-card.season-mange.team-card-details .l-title>img {
  width: 70px;
  height: 70%;
}

/* .new_tournament_design {
  display: flex;
  justify-content: space-between;
} */

/* .new_tournament_design .tournament_card_redesign .card-content h6 {
  width: 180px;
} */

.date-card p strong {
  color: #F05829;
  font-size: 15px;
  font-weight: 600;
}

.new_tournament_design .wrap .l-title {
  align-items: flex-start;
}

.new_tournament_design .wrap {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.members_new_css .mem-filter {
  justify-content: flex-start;
}

.new_tournament_design .wrap .dropdown.acts img.dots {
  width: 100%;
  max-width: 5px;
  min-width: 4px;
}

/* .members_new_css h5{
  display: block;
  font-size: 20px;
  font-weight: 600;
} */

.members_new_css h5 {
  display: block;
  font-size: 20px;
  display: flex;
  font-weight: 600;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.members_new_css {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.nav_tabs_memmebr_page {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.nav_tabs_memmebr_page a {
  color: #000;
}

.grade_wrap {
  width: 100%;
  max-width: 96px;
  height: 24px;
  background: #DDFFEB;
  border-radius: 4px;
  margin: 10px 0;
}

.edit-team button {
  width: 100%;
  /* min-width: 200px; */
  min-height: 38px;
  background: #27AE60;
  border: 1px solid #27AE60;
  color: #fff;
}

.aboutgrp .edit-team button:hover {
  background: #27AE60;
  border: 1px solid #27AE60;
  color: #fff;
}

.grpdocuments h5 {
  font-size: 16px;
}

.grade_wrap span {
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #27AE60;
  line-height: 24px;
}

.selected_team_tabbing {
  border: none;
}

.selected_team_tabbing li {
  border: none;
}

.selected_team_tabbing li .nav-link {
  border: none;
  background: #fff;
  min-width: 123px;
  min-height: 28px;
  border-radius: 6px;
  padding: 0;
}

.selected_team_tabbing li .nav-link.active {
  border: none;
  background: #298CEA;
  color: #fff;
  font-weight: 500;
}

.selected_team_tabbing li .nav-link.active:hover {
  color: #fff;
}

.selected_team_tabbing li {
  border: none;
  margin: 11px 15px 0 0;
}

.selected_team_tabbing .nav-link.active label {
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: 500;
}

.selected_team_tabbing .nav-link label {
  margin: 0;
  padding: 0;
  color: #484848;
  font-weight: 500;
  cursor: pointer;
}

/* .selected_team_add {
  margin-right: 20px;
} */

.selected_team_add .butn-edit-profile {
  width: 100%;
  min-width: 185px;
  background: transparent;
  border: 1px dashed #9C9C9C;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 311px;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
  gap: 20px;
}


.selected_team_view_profile {
  min-width: 100px;
  max-height: 28px;
  font-size: 14px;
  padding: 0 10px;
  margin: 0;
  width: 100%;
}

.selected_team_add .butn-edit-profile:hover {
  background: transparent;
  border: 1px dashed #9C9C9C;
}

.selected_team_add .butn-edit-profile:active {
  background: transparent !important;
  border: 1px dashed #9C9C9C !important;
  margin-top: 0;
}

.selected_team_add .butn-edit-profile a {
  width: 100%;
  min-width: 100px;
  height: 100%;
  min-height: 28px;
  background: #ddd;
  color: #767676;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

p.dob span:first-child {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #484848;
}

.players-wrap {
  width: 100%;
  max-height: 652px;
  overflow-y: auto;
}

.players-wrap:hover::-webkit-scrollbar {
  display: block;
  transition: all 0.4s ease-in-out;
}

.players-wrap::-webkit-scrollbar {
  display: none;
  transition: all 0.4s ease-in-out;
}

.players-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: transparent;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.players-wrap::-webkit-scrollbar {
  width: 6px;
  height: 80px;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.players-wrap::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  transition: all 0.4s ease-in-out;
}

.height.ps-5 {
  display: flex;
  align-items: center;
}

.nav_tabs_memmebr_page .active.nav-link {
  background: #F1582A;
  color: #fff;
}

.form_submit_basketball .input-right-icon .error-message {
  position: absolute;
}

.member_card_section .c-details {
  text-align: left;
}

.member_card_section span.mem-no .form-control {
  text-align: left;
  background: transparent !important;
}

.type-mem {
  background: #D3E4FF;
  border-radius: 4px;
  margin: 10px 0;
  text-align: center;
  color: #298CEA;
  font-size: 14px;
}

.date-card .member_card_section p strong {
  color: #484848;
  font-size: 14px;
  font-weight: 600;
}

.date .member_card_section span.mem-no .form-control {
  margin: 0 7px !important;
}

.member_card_section .mem-no span strong {
  color: #484848;
  font-size: 14px;
}

/* Team List */
.top_buttons_dropdown {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.create_game .create_buttn_teams {
  width: 100%;
  min-width: 168px;
  min-height: 38px;
  padding: 0;
  margin: 0;
  background: #27AE60 !important;
}

/* .top_buttons_dropdown .tab_game_list {
  display: flex;
  width: 100%;
  max-width: 228px;
} */

.top_buttons_dropdown .tab_game_list {
  /* width: 100%;
  max-width: 218px; */
  max-height: 40px;
  /* display: flex;
  background: #fff;
  border: 1px solid #D4D4D4;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center; */
}

.top_buttons_dropdown .tab_game_list a {
  font-family: 'Roboto', sans-serif, sans-serif;
  color: #fff;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.top_buttons_dropdown .tab_game_list a span {
  margin-right: 10px;
}

.top_buttons_dropdown .tab_game_list a.active span svg {
  fill: #27AE60;
}

.top_buttons_dropdown .tab_game_list a:first-child {
  width: 100%;
  padding-left: 10px;
  background: transparent;
  border: 1px solid #d4d4d4;
  border-radius: 10px 0 0 10px;
}

.top_buttons_dropdown .tab_game_list a.active {
  background: #DDFFEB;
  border: 1px solid #d4d4d4;
  transition: all 0.4s ease-in-out;
  color: #27AE60;
}

.top_buttons_dropdown .tab_game_list a:nth-child(2) {
  width: 65%;
  padding-left: 10px;
  border-radius: 0 10px 10px 0;
}




.background_body_table {
  width: 100%;
  background: #fff;
  margin: 20px 0;
  padding: 20px;
}

.table_create_list {
  width: 100%;
  margin: 0 0 20px 0;
}

.team_vs_team.vs_text .team_clevland {
  position: relative;
  left: 40px;
}

.team_clevland {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  -webkit-gap: 10px;
  -moz-gap: 10px;
  -o-gap: 10px;
}

.team_clevland h4 {
  font-size: 16px;
  margin-bottom: 0;
}

.public_food_parking {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 11px;
}

.public_food_parking p {
  font-size: 12px;
}

.public_food_parking .secondaryColor {
  color: #27AE60;
  font-size: 12px;
}

.public_food_parking .baseColor {
  color: #F1372B;
  font-size: 12px;
}

.both_team_detail {
  display: flex;
  width: 100%;
  min-width: 330px;
}

/* .team_vs_team {
  width: 35%;
} */
.team_clevland img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 50%;
}

.vs_text {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.edt_delete {
  display: flex;
  gap: 10px;
  -webkit-gap: 10px;
  -moz-gap: 10px;
  -o-gap: 10px;
}

.edt_delete a {
  line-height: normal;
  padding: 0;
}

.table_create_list table tr td {
  vertical-align: top !important;
}

.table_create_list .heading h4 {
  font-weight: 600;
}

.sidenav_offcanvas {
  width: 100%;
  min-width: 520px !important;
  height: calc(100vh - 30px);
  margin-top: 56px;
}

.offcanvas_create_game .season-popup .form-group .form-label {
  font-size: 16px;
  font-weight: 400;
  color: #484848;
  margin-bottom: 5px;
  display: inline-block;
}

.offcanvas_create_game .create-match .form-group a {
  font-size: 14px;
  color: #27AE60;
}

.additional_button a {
  background: #F05829;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  padding: 5px;
}

.offcanvas_create_game .check-box-modal .custom-checkbox label {
  font-size: 16px;
  color: #484848;
  position: relative;
  display: flex;
  align-items: center;
  gap: 9px;
}

.additional_setting_box {
  border-radius: 10px;
  width: 100%;
  background: #F5F9FE;
  margin: 15px 0;
  border: 1px solid #F5F9FE;
  display: none;
  transition: all 0.4s ease-in-out;
}

.additional_setting_box .schedule-1.w-100.create-match-btn {
  width: 100%;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.additional_setting_box .create-next.submission.create-match-btn.w-100 {
  margin: 0 !important;
}

.create_game.offcanvas_create_game {
  width: 100%;
  height: 100%;
  position: relative;
}

.table_match_date_detail {
  width: 100%;
  height: calc(100vh - 390px);
  overflow-y: auto;
}


.search-teams.venue-top {
  /* top: 54px; */
  z-index: 6;
}

/* .table_match_date_detail:hover::-webkit-scrollbar {
  display: block;
  transition: all 0.4s ease-in-out;
} */

.table_match_date_detail::-webkit-scrollbar {
  display: block;
  transition: all 0.4s ease-in-out;
}

.table_match_date_detail::-webkit-scrollbar-track {
  -webkit-box-shadow: transparent;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.table_match_date_detail::-webkit-scrollbar {
  width: 6px;
  height: 80px;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.table_match_date_detail::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  transition: all 0.4s ease-in-out;
}

.team_bracket_section .sc-aXZVg.kjLZia {
  background: transparent !important;
}

.team_bracket_section .sc-gEvEer.gULjcf {
  background: transparent;
}

.team_bracket_section .gkXCXi {
  color: #000;
  padding: 0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif, sans-serif;
  border: 1px solid #d4d4d4;
}

.background_body_table .dWYBbN {
  box-shadow: none;
}

span.numbering_under_bracket {
  background: #fff;
  padding: 12px 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.matches_between_team img {
  width: 100%;
  max-width: 60px;
  height: 60px;
  object-fit: cover;
}

.matches_between_team.second {
  background: #dedede;
}

.matches_between_team {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F5F9FE;
  padding: 10px;
}

span.imge_with_team_name {
  display: flex;
  align-items: center;
  gap: 12px;
}

.imge_with_team_name h6 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 22px;
  width: 250px;
  text-align: justify;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sidenav_offcanvas .offcanvas-body {
  padding: 0 0 56px 0;
}

.offcanvas_create_game .modal-dialog {
  position: absolute;
  z-index: 2;
  background: #000;
}

.offcanvas_create_game .modal-dialog {
  position: absolute;
  z-index: 3;
  background: rgba(0, 0, 0, 0.66);
  margin: 0 !important;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.offcanvas_create_game .create-match-wrap.season-popup {
  padding: 0 20px 0 20px;
}

.sidenav_offcanvas .offcanvas-header {
  padding: 30px 0 0;
}

.offcanvas_create_game .modal-dialog .modal-content .btn-close {
  background: unset !important;
  width: auto;
  height: auto;
  color: #000;
  top: 40px;
  position: absolute;
  display: none;
}

.offcanvas_create_game .modal-dialog .modal-body .modal-title {
  font-size: 20px;
  color: #F05829;
  font-weight: 600;
  padding-top: 0;
}

.offcanvas_create_game .modal-dialog .modal-body .modal-title span {
  font-size: 16px;
  display: block;
  color: #000;
  font-weight: 400;
}

.offcanvas_create_game_body .item {
  display: flex;
  margin: 20px 0;
  align-items: center;
  gap: 10px;
  -webkit-gap: 10px;
  -moz-gap: 10px;
  -o-gap: 10px;
}

.offcanvas_create_game_body .item p {
  margin-bottom: 0;
  font-size: 18px;
}

.offcanvas_create_game_body .item p a {
  color: #484848;
}

.offcanvas_create_game_body .item.active p a {
  color: #27AE60;
}

.offcanvas_create_game_body .item.active svg {
  fill: #27AE60;
}


.offcanvas_create_game .modal-dialog .modal-body {
  padding: 20px;
}

.offcanvas_create_game .let_schedule_game {
  width: 100%;
  padding: 10px 40px 10px 40px;
  color: #484848 !important;
  background: #CFCECE !important;
  font-weight: 600;
  font-size: 18px;
  max-width: 264px;
  display: flex;
  justify-content: center;
  margin: 20px auto 3px;
}

.sidenav_offcanvas .offcanvas-header .offcanvas-title h4.text-left {
  font-weight: 600;
  color: #000;
  font-size: 20px;
}

.teams_nav_buttons_Active button {
  width: 100%;
  min-width: 168px;
  min-height: 38px;
  background: #27AE60 !important;
  font-size: 18px;
}

.round_section {
  width: 100%;
}

.team_list_navs .create_round a {
  width: 100%;
  max-width: 166px;
  min-height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #27AE60;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
}

.create_round_modal_teams .modal-title.h4 {
  margin: 0;
  padding: 0;
}

.create_round_modal_teams {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.create_round_modal_teams .modal-header {
  border: none;
}

.create_round_modal_teams .create_round_modal_teams_body {
  width: 100%;
  margin: 20px 0;
}

.create_round_modal_teams .create_round_modal_teams_body .form-control {
  width: 100%;
  max-height: 40px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  font-size: 16px;
  box-shadow: none;
}

.create_round_modal_teams .create_round_modal_teams_body .form-select {
  width: 100%;
  max-height: 40px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  font-size: 16px;
  box-shadow: none;
}

.create_round_modal_teams_body button {
  background: #27AE60;
  border: 1px solid #27AE60;
  width: 100%;
  max-width: 111px;
  max-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.create_round_modal_teams_body button:hover {
  background: #27AE60;
  border: 1px solid #27AE60;
}

.round_section table {
  border: none;
}

.round_section table .border-none {
  border: none;
}

.team-list-side .round_section table thead th {
  color: #484848 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.team-list-side .round_section table tbody td {
  color: #484848 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Roboto', sans-serif, sans-serif !important;
}

.team-list-side .round_section table tbody td a {
  color: #298CEA !important;
  padding: 2px 8px;
}

.table_background_round_team {
  width: 100%;
  background: #fff;
  padding: 20px !important;
  border-radius: 10px;
}

.need_help {
  position: absolute;
  bottom: -90px;
  left: 35%;
  text-align: center;
}

.need_help h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.need_help a {
  color: #F65E2E;
  font-weight: 400;
  font-size: 18px;
}

.matches_calendar_main_menu .dropdown ul li a input.form-control {
  color: #000 !important;
  font-family: 'Roboto', sans-serif, sans-serif;
  text-align: center;
}

.matches_calendar_main_menu .dropdown ul li a input.form-control::placeholder {
  color: #000 !important;
  font-family: 'Roboto', sans-serif, sans-serif;
}

.main-menu.matches_calendar_main_menu .dropdown:hover>.dropdown-menu {
  display: block;
  top: 30px;
  border-radius: 0;
  height: 100%;
  min-height: 380px;
  min-width: 300px;
  left: -270px;
  padding: 10px;
  overflow-y: auto;
  transition: all 0.4s ease-in-out;
}

.main-menu.matches_calendar_main_menu .dropdown:hover>.dropdown-menu:hover::-webkit-scrollbar {
  display: block;
  transition: all 0.4s ease-in-out;
}

.main-menu.matches_calendar_main_menu .dropdown:hover>.dropdown-menu::-webkit-scrollbar {
  display: none;
  transition: all 0.4s ease-in-out;
}

.main-menu.matches_calendar_main_menu .dropdown:hover>.dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: transparent;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.main-menu.matches_calendar_main_menu .dropdown:hover>.dropdown-menu::-webkit-scrollbar {
  width: 6px;
  height: 80px;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.main-menu.matches_calendar_main_menu .dropdown:hover>.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  transition: all 0.4s ease-in-out;
}

.matches_calendar_main_menu .dropdown ul li a input[type=date]::after {
  background-image: none;
  text-transform: uppercase;
}

.dates_listing_create_game a.nav-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #484848;
  font-family: 'Roboto', sans-serif, sans-serif;
  font-size: 14px;
  transition: all 0.4s ease-in-out;
}

.dates_listing_create_game a.nav-link:hover {
  color: #000;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}

a.modal_cancel.nav-link {
  position: sticky;
  bottom: -10px;
  background: #ffff;
  width: 100%;
  left: 0;
  color: #298CEA;
  font-family: 'Roboto', sans-serif, sans-serif;
  font-size: 14px;
}

.nav_tabs_memmebr_page .active {
  background: #F05829;
  color: #fff;
}

.nav_tabs_memmebr_page a.active {
  background: #fff;
  color: #484848;
}

.nav_tabs_memmebr_page a.isActive {
  background: #F05829;
  color: #fff;
}

.table_background_round_team table tbody tr {
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.add_time_slot_modal .input-right-icon .text-danger {
  position: absolute;
}

.add_time_slot_modal .input-right-icon {
  position: relative;
  margin-bottom: 30px !important;
}

/* Enrollment Table Css Starts Here */
#add-user-form .enrollment_table_page thead tr th {
  font-size: 16px;
  color: #484848;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 33px !important;
  padding-right: 25px !important;
  padding-left: 25px !important;
  padding-bottom: 25px !important;
}

.enrollment_table_page tbody .coach_image_detail img {
  width: 100%;
  max-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  margin-right: 15px !important;
}

.enrollment_table_page tbody .coach_detail p {
  margin-bottom: 0;
  font-size: 14px;
  font-family: 'Roboto', sans-serif, sans-serif;
  font-weight: 400;
  color: #484848;
}

.enrollment_table_page tbody .coach_detail .player_font {
  margin-bottom: 0;
  font-size: 14px;
  font-family: 'Roboto', sans-serif, sans-serif;
  font-weight: 400;
  color: #298CEA;
}

.enrollment_table_page tbody .coach_detail .player_font span.bold {
  margin-bottom: 0;
  font-size: 14px;
  font-family: 'Roboto', sans-serif, sans-serif;
  font-weight: 400;
  color: #484848;
}

#add-user-form .enrollment_table_page tbody tr {
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

#add-user-form .enrollment_table_page tbody tr:nth-of-type(odd) {
  background: #fafafa;
}

.enrollment_table_page td button:first-child {
  min-width: 125px;
  max-height: 30px;
  background: #27AE60;
  /* display: inline-block; */
  border: 1px solid #27AE60;
  font-family: 'Roboto', sans-serif, sans-serif;
  font-size: 18px;
  margin-right: 15px;
  padding-top: 2px;
}

.enrollment_table_page td button:first-child:hover {
  background: #27ae60;
}

.approve_button_table_listing_button.blue button:first-child {
  background: #298CEA !important;
  border: 1px solid #298CEA !important;
}

.approve_button_table_listing_button.red button:first-child {
  background: #F1372B !important;
  border: 1px solid #F1372B !important;
}

.approve_button_table_listing {
  display: inline-block;
  margin: 0 8px;
  padding-top: 11px;
}

.approve_button_table_listing.blue button {
  background: #298CEA !important;
  border: 1px solid #298CEA !important;
}

.approve_button_table_listing.red button {
  background: #F1372B !important;
  border: 1px solid #F1372B !important;
}

.enrollment_list_heading_with_button .enroll-team button {
  background: #27AE60 !important;
  border: 1px solid #27AE60;
  min-width: 146px;
  min-height: 38px;
}

.enrollment_table_page td button:nth-child(2) {
  min-width: 125px;
  min-height: 28px;
  background: #298CEA;
  display: inline-block;
  border: 1px solid #298CEA;
  font-family: 'Roboto', sans-serif, sans-serif;
  font-size: 16px;
  margin-right: 15px !important;
}

.enrollment_table_page td button:nth-child(3) {
  min-width: 125px;
  min-height: 28px;
  background: #F1372B;
  display: inline-block;
  border: 1px solid #F1372B;
  font-family: 'Roboto', sans-serif, sans-serif;
  font-size: 16px;
  margin-left: 0 !important;
}

#add-user-form .enrollment_table_page thead tr {
  border-top: 0;
  border-bottom: 1px solid #f4f4f4;
}

.enroll_team_sidnav_content .profile-details {
  width: 100%;
  min-height: 148px;
}

.approve_button_table_listing_content .offcanvas-title p {
  font-size: 16px;
  color: #484848;
  padding: 1px 26px 3px 3px;
}

.approve_button_table_listing_button button:first-child {
  min-width: 119px;
  min-height: 38px;
  background: #27AE60;
  border: 1px solid #27AE60;
  border-radius: 6px;
}

.approve_button_table_listing_button button:first-child:hover {
  background: #27AE60;
  border: 1px solid #27AE60;
}

.approve_button_table_listing_button button:last-child {
  min-width: 119px;
  min-height: 38px;
  background: #484848;
  border: 1px solid #484848;
  border-radius: 6px;
}

.approve_button_table_listing_button button:last-child:hover {
  background: #484848;
  border: 1px solid #484848;
}

.approve_button_table_listing_button {
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
}

.payment_list_heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.payment_list_heading_search_button {
  display: flex;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
}

.payment_list_heading_search_button h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.payment_list_heading_search_button .team-search {
  margin-bottom: 0;
}

.payment_list_heading .input-right-icon {
  display: flex;
  align-items: center;
}

.payment_history_page .all_games_date_range .input-right-icon input {
  max-height: 40px;
}

.payment_listing_background {
  width: 100%;
  background: #fff;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
}

.svg_payment_detail_listing {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.svg_payment_detail_listing svg {
  width: 50px;
  height: 20px;
}

.payment_listing_background table thead th {
  font-size: 16px !important;
  color: #484848 !important;
  font-weight: 600 !important;
}

.payment_listing_background table tbody td {
  font-size: 14px !important;
  color: #484848 !important;
  font-family: 'Roboto', sans-serif, sans-serif !important;
  font-weight: 400 !important;
}

.payment_listing_background table thead tr {
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-bottom: 1px solid #fafafa;
}

.payment_listing_background table tbody tr {
  border: 1px solid #fAfAfA;
  border-top: 1px solid #fAfAfA;
}

.pending_color_column {
  color: #F1372B;
  display: flex;
  align-items: center;
  gap: 5px;
}

.payment_description {
  width: 100%;
  background: #fff;
  padding: 15px 30px;
  border-radius: 10px;
}

.payment_description table thead th {
  color: #484848;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif, sans-serif;
  background: #FAFAFA;
}

.payment_description table thead tr {
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;
  border-bottom: 1px solid #F4F4F4;
}

.payment_recepit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment_recepit_heading h4 {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 15px;
  -webkit-gap: 15px;
  -moz-gap: 15px;
  -o-gap: 15px;
  font-size: 20px;
  font-weight: 600;
}

.payment_recepit_heading .paid_button button {
  min-height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #484848;
  border-radius: 6px;
}

.payment_recepit .download_receipt {
  max-width: 201px;
  min-height: 38px;
  background-color: #4285F4;
  border-radius: 6px;
}

.game_type_payment_transaction {
  display: flex;
  gap: 15px;
  -webkit-gap: 15px;
  -moz-gap: 15px;
  -o-gap: 15px;
}

.game_type_payment_transaction .game_team_name_season h4 {
  font-size: 14px;
  font-family: 'Roboto', sans-serif, sans-serif;
}

.game_type_payment_transaction .game_team_name_season p {
  font-size: 14px;
  font-family: 'Roboto', sans-serif, sans-serif;
  color: #484848;
}

.dahboard_inner_white_background .payment_description tbody tr td {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif, sans-serif;
  color: #484848;
}

.dahboard_inner_white_background .payment_description tbody tr {
  border-bottom: 1px solid #e9e9e9;
}

.dahboard_inner_white_background .payment_description tbody tr:nth-of-type(odd) {
  background: #FAFAFA !important;
}

.payment_summary h4 {
  font-size: 16px;
  font-weight: 600;
}

.payment_summary p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
}

.payment_summary p:nth-child(3) {
  width: 100%;
  border-bottom: 1px solid #E9E9E9;
  padding: 0 0 20px 0;
}

.payment_summary p:nth-child(4) {
  color: #000;
}

.payment_summary {
  width: 100%;
  padding-right: 80px;
}

.payment_description_paidto {
  width: 100%;
  background: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  min-height: calc(100% - 54px);
}

.payment_description_paidto .heading {
  font-size: 20px;
  font-weight: 600;
}

.payment_description_paidto .giu_connect_img_label .giu_img_team {
  display: flex;
  align-items: center;
  gap: 15px;
  -webkit-gap: 15px;
  -moz-gap: 15px;
  -o-gap: 15px;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0;
}

.payment_description_paidto .giu_connect_img_label .giu_img_team p {
  margin-bottom: 0;
}

.giu_query .giu_email_phone h4 {
  font-size: 16px;
  font-weight: 600;
}

.giu_connect_img_label .giu_query .giu_email_phone {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e9e9e9;
}

.giu_query .giu_email_phone span {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.giu_address {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.giu_address p {
  font-size: 14px;
  margin-bottom: 10px;
}

.giu_address h4 {
  font-size: 16px;
  font-weight: 600;
}

.giu_thankyou_message {
  width: 100%;
  text-align: center;
  margin: 15px 0;
}

.giu_thankyou_message p {
  color: rgba(72, 72, 72, 0.40);
  font-size: 16px;
  font-weight: 400;
}

.newcss_matches .dropdown ul li a input.form-control {
  color: #000 !important;
  font-family: 'Roboto', sans-serif, sans-serif;
  text-align: center;
}

.newcss_matches .dropdown ul li a input.form-control::placeholder {
  color: #000 !important;
  font-family: 'Roboto', sans-serif, sans-serif;
}

.newcss_matches .dropdown:hover>.dropdown-menu {
  display: block;
  top: 30px;
  border-radius: 0;
  height: 100%;
  min-height: 380px;
  min-width: 300px;
  left: -270px;
  padding: 10px;
  overflow-y: auto;
  transition: all 0.4s ease-in-out;
}

.newcss_matches .dropdown:hover>.dropdown-menu:hover::-webkit-scrollbar {
  display: block;
  transition: all 0.4s ease-in-out;
}

.newcss_matches .dropdown:hover>.dropdown-menu::-webkit-scrollbar {
  display: none;
  transition: all 0.4s ease-in-out;
}

.newcss_matches .dropdown:hover>.dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: transparent;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.newcss_matches .dropdown:hover>.dropdown-menu::-webkit-scrollbar {
  width: 6px;
  height: 80px;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.enrollment_table_team_player table tbody td p.mail {
  font-size: 15px;
  font-weight: 400;

}

.newcss_matches .dropdown:hover>.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  transition: all 0.4s ease-in-out;
}

.newcss_matches .dropdown ul li a input[type=date]::after {
  background-image: none;
  text-transform: uppercase;
}

.enroll_team_button_search {
  width: 100%;
  display: flex;
  /* margin-left: 20px; */
  margin-bottom: 20px;
  margin-top: 28px;
  align-items: center;
  justify-content: space-between;
}

.enroll_team_button_search .search {
  position: relative;
}

.enroll_team_button_search.enrollment-button .search span.input-icon-alt {
  color: #f44336;
  float: right;
  font-size: inherit;
  position: absolute;
  right: 5px;
}

.enroll_team_button_search button.btn.btn-dark.green.white-orange-color.find-btn {
  width: 80px;
  height: 40px;
  border-radius: 7px;
}

.enroll_team_sidnav_content .profile-details .details img {
  min-width: 108px;
  min-height: 108px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
}

.enroll_team_sidnav_content .profile-details {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  -webkit-gap: 15px;
  -moz-gap: 15px;
  -o-gap: 15px;
  padding: 20px;
}

.enroll_team_sidnav_content span.coach-mail {
  margin-left: 0;
}

.enroll_user_detail_enrollment .coach_enrollment {
  color: #F05829;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 6px;
}

.enroll_user_detail_enrollment .coach-info {
  margin-bottom: 3px;
  font-size: 14px;
}

.enroll_user_detail_enrollment h6 {
  margin-bottom: 5px;
}

.enrollment_table_team_player table thead tr {
  border-top: transparent;
  border-bottom: 1px solid #f4f4f4;
}

.enrollment_table_team_player table tbody td {
  vertical-align: middle;
}

.enrollment_table_team_player table .custom-checkbox input[type="checkbox"] {
  opacity: 1;
  position: absolute;
  z-index: 9;
  width: 25px;
  height: 25px;
  top: -14px;
  margin: 0 -6px;
}

.enrollment_table_team_player table .coach-profle {
  margin-left: -10px;
}


.enrollment_table_team_player table tbody td {
  vertical-align: middle !important;
  padding: 6px 9px !important;
}

.enrollment_table_team_player table .coach-profle h5 {
  font-family: 'Roboto', sans-serif, sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.enrollment_table_team_player table tbody td p.mail {
  font-family: 'Roboto', sans-serif, sans-serif;
  font-size: 15px;
  /* font-weight: 600; */
}

.enrollment_table_team_player table tbody td p.mail .blue_text {
  color: #298CEA;
}

.enrollment_table_team_player table tbody td p.mail .verified_player {
  color: #27AE60;
}

.player_verified_player {
  display: flex;
  gap: 20px;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -o-gap: 20px;
}

.enrollSlideNav .offcanvas-body {
  overflow: initial;
}

.ellorment_green_button {
  max-width: 146px;
  max-height: 38px;
  background: #27AE60 !important;
  border: 1px solid #27AE60 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.video_content .accordion-item {
  border: 0;
}

/* 23-01-2023 preeti */

.team_vs_team {
  width: 41%;
}

/* .checkbox.enroll-team-check span.checkmark {
  background-color: transparent;
  border: 1px solid #0000004f;
}

.checkbox.enroll-team-check::after {
  border: solid #29a4f1 !important;
} */

.season-date p {
  font-size: 14px;
}


.video_content .accordion-button::after {
  background-image: url("../images/video-dropdown.svg");
}

.green_color_schedule {
  color: #27AE60 !important;
}

.green_color_schedule:hover {
  color: #27AE60 !important;
}

.table-scroll {
  position: relative !important;
  overflow: hidden !important;
  width: 100%;
  height: 47vh !important;
}



@media (min-width: 992px) and (max-width: 1700px) {
  .table-scroll {

    height: 40vh !important;
  }

  .wrap-create-button {
    left: 73% !important;
  }

}

@media (min-width: 1200px) and (max-width: 1400px) {

  .fade.help_popup.modal.show {
    width: 100%;
    position: absolute;
    top: 99px;
    height: 89vh;
    overflow: auto;
  }
}











@media (min-width: 1200px) and (max-width: 1599px) {
  .l-title .trophy_img {
    max-width: 50px !important;
    min-width: 60px !important;
  }

  /* .l-card {
    max-width: 33%;
  } */

  .season_active h6 {
    width: 100%;
    min-width: 78px;
    max-width: 78px;
  }

  .groups-count .group {
    gap: 2px;
  }

  .dashboard_organizer .breadcrumb-item a {
    font-size: 13px;
    font-weight: 400;
  }

  .main-menu>ul li a {
    font-size: 12px;
  }

  .main-menu>ul li {
    margin: 0 15px 0px 0px;
  }
}


@media (min-width: 1900px) {
  .card-content h6 {
    font-size: 16px;
    font-weight: 600;
    width: auto;
    min-width: 198px;
    max-width: 198px;
  }


}

@media (max-width: 1400px) {
  /* .search-teams {
    top: 153px !important;
} */


}

@media (min-width: 1400px) and (max-width: 1500px) {
  .edit-team button {
    min-width: 105px;
    max-width: 106px;
  }

  .aboutgrp p {
    font-size: 17px;
  }

  .seasonDashboard_Season_heading img.img-fluid {
    width: 100%;
    max-width: 13%;
    min-width: 2px;
    /* margin-left: -9rem; */
  }

  .seasonDashboard_Season_heading .dropdown.acts {
    margin-left: -6rem !important;
  }

}

@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard_organizer .tabbing_ui_fixing .nav-link {
    max-width: 145px;
    min-width: 107px;
  }

  .message-notificaion {
    margin-left: 18px;

  }

  .dashboard_organizer .tabbing_ui_fixing .nav-link.active {
    width: 100%;
    max-width: 141px;
    min-width: 135px;
  }

}

@media (min-width: 1200px) and (max-width: 1557px) {
  .team_vs_team {
    width: 52%;
  }


  .l-card.venue .date p,
  .group.total-groups p {
    font-size: 12px !important;
    margin-bottom: 0;
  }

  .public_food_parking {
    gap: 1px;
  }

  p.mb-0.sports {
    width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .grpdocuments .height {
    white-space: nowrap;
  }
}



@media (min-width: 1500px) and (max-width: 1699px) {
  .live_game .slick-prev {
    top: -40px;
    left: 220px;
  }
}







/* 23-1-2023 */

@media (min-width: 1600px) and (max-width: 1730px) {

  .card-content h6 {
    width: auto;
    min-width: 150px;
    max-width: 154px;
  }

  .dashboard_organizer .tabbing_ui_fixing .nav-link {
    max-width: 154px;
    min-width: 141px;
  }

  .dashboard_organizer .tabbing_ui_fixing .nav-link.active {
    max-width: 136px;
    min-width: 141px;
    margin: 0px 0px;
  }

  .season_active h6 {
    width: 100%;
    min-width: 147px;
    max-width: 147px;
    font-weight: 600;
  }


  .c-details.text-center span {
    font-size: 14px;
  }

  .c-details span {
    display: block;
  }

  p.dob span:first-child {
    font-size: 15px;
  }

  .cfirst {
    max-width: 207px;
  }

  .main-menu>ul li a {
    font-size: 16px;
  }

  .main-menu>ul li {
    margin: 0 18px 10px 10px;
  }

  .coaches .cfirst {
    max-width: 213px;
  }

  .position-relative .mega_menu_content2 {

    left: 365px;
    min-width: 430px;

  }
}

@media (min-width: 1730px) and (max-width: 1899px) {
  .card-content h6 {
    width: auto;
    min-width: 164px;
    max-width: 164px;
  }

}


th.approved-btns {
  text-align: center;
}

td.approved-btns {
  text-align: center;
}

@media (min-width: 1501px) and (max-width: 1650px) {
  a.tournament_card_redesign {
    width: 100%;
    min-width: 180px !important;
  }

  .card-content h6 {
    width: auto;
    min-width: 130px !important;
  }
}


@media (max-width: 1356px) {


  .seasonDashboard_groups_teams_player {

    align-items: start !important;
  }

  .seasonDashboard_date p span {
    font-size: 13px !important;
  }




  .seasonlogin-btn-disable {
    margin: 15px 4px;
  }


  .season-trophy-box .time_schedule {
    max-width: 90% !important;

    width: 90% !important;
  }

  .SeasonDashboard_box-detailS {

    max-width: 90% !important;
  }



  .seasonDashboard_navLink a {
    width: 100% !important;
    font-size: 12px !important;
  }

  .l-card.venue .date p,
  .group.total-groups p {
    font-size: 14px !important;

  }

  .season-date p {
    font-size: 13px;
  }

  .current-season-schedule {
    display: flex;
    align-items: start !important;
    justify-content: space-between !;
    flex-direction: column !important;
  }





}


.roster-sidescroll {
  font-size: 15px;
  /* width: 93%; */
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .roster-sidescroll {
  
    width: 83%;
  
   
  }

}


@media (min-width: 1024px) and (max-width: 1400px) {

  .search-teams {
    /* top: 113px !important; */
    margin-left: -3rem;
  }

  .roster-table-history {
    height:150px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 12px;
}

  .search-teams.venue-top {
    top: 1px !important;
  }

  a.tournament_card_redesign {
    min-width: 170px !important;
  }

  .roster-sidescroll {
    font-size: 14px;
    width: 93%;
  
    /* text-align: justify; */
  }

  .roster-sidescroll .form-control {
    font-size: 14px;
  }

  /* .roster-sidescroll {
  height: 675px !important;
  overflow-y: auto;
  overflow-x: hidden;
} */

}

@media (min-width: 1320px) and (max-width: 1500px) {
  .card-content h6 {
    min-width: 115px;
    max-width: 175px;
  }

  .seasonDashboardElements {
    flex-wrap: wrap !important;
  }





  .seasonDashboardElements .item {
    width: 48% !important;
    min-width: 48%;
  }

  .l-title .trophy_img {
    max-width: 55px;
    min-width: 55px;
  }

  .seasonDashboard_navLink a {
    font-size: 12px !important;
  }

  .live_game .slick-prev:before,
  .live_game .slick-next:before {
    width: 30px;
    height: 30px;
  }

  .seasonDashboard_updateScore .live_game .slick-prev {
    top: -30px !important;
    left: 149px !important;
  }

  .seasonDashboard_updateScore .live_game .slick-next {
    top: -30px !important;
    left: 188px !important;
  }

  .seasonDashboard_card {
    min-height: 556px !important;
    max-height: 556px !important;
  }

  .card-border {
    min-height: 317px !important;
  }

  .seasonDashboard_date p span {
    display: block !important;
  }

  .slick-slide img {
    width: 37px !important;
  }

  .live_game_team1 h4 {
    font-size: 18px;
  }

  a.tournament_card_redesign {
    width: 100%;
    min-width: 230px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {
  .enrollment_table_page td button:first-child {
    min-width: 95px;
  }

  .team_bracket_section [dir="ltr"] .sc-imWYAI::after {
    right: -71px !important;
  }

  .team_bracket_section [dir="ltr"] .sc-imWYAI:nth-child(2n)::before {
    right: -6.5em;
  }

}

@media (min-width: 1500px) and (max-width: 1600px) {
  /* .l-card {
    max-width: 27%;
  } */

  .team_bracket_section [dir="ltr"] .sc-imWYAI::after {
    right: -48px !important;
  }

  .team_bracket_section [dir="ltr"] .sc-imWYAI:nth-child(2n)::before {
    right: -4.7em;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {

  .enrollment_table_page td button:first-child {
    min-width: 75px;
    margin-right: 0px !important;
    max-width: 93px;
  }

}

@media (min-width: 1600px) and (max-width: 1700px) {
  .team_bracket_section [dir="ltr"] .sc-imWYAI:nth-child(2n)::before {
    right: -5em;
  }

  .team_bracket_section [dir="ltr"] .sc-imWYAI::after {
    right: -48px !important;
  }

}

@media (min-width: 1700px) and (max-width: 1800px) {
  .team_bracket_section [dir="ltr"] .sc-imWYAI:nth-child(2n)::before {
    right: -4.5em;
  }

  .team_bracket_section [dir="ltr"] .sc-imWYAI::after {
    right: -38px !important;
  }

}

@media (min-width: 1800px) and (max-width: 1900px) {
  .team_bracket_section [dir="ltr"] .sc-imWYAI:nth-child(2n)::before {
    right: -3.7em;
  }

  .team_bracket_section [dir="ltr"] .sc-imWYAI::after {
    right: -30px !important;
  }

}

@media (min-width: 1400px) and (max-width: 1600px) {

  .tabbing_all_games .team_vs_team {
    gap: 10px;
  }
}

.tabbing_all_games .team_vs_team .team_1 h4 {
  font-size: 15px;
}

@media (min-width: 1501px) and (max-width: 1661px) {

  .seasonDashboard_updateScore .live_game .slick-prev {
    top: -39px;
    left: 144px;
  }

  .seasonDashboard_updateScore .live_game .slick-next {
    top: -38px;
    left: 199px;
  }

}

@media (min-width: 1600px) and (max-width: 1740px) {


  .live_game .heading_live_game .form-check .form_input_radio a {
    padding: 3px 8px;
  }

  .heading_live_game button.slick-arrow.slick-prev {
    top: -41px;
    left: 205px;
  }

  .heading_live_game button.slick-arrow.slick-next {
    top: -42px;
    left: 245px;
  }

  .live_game .heading_live_game .form-check .form_input_radio h4 {
    font-size: 17px;
  }

}

@media (min-width: 1400px) and (max-width: 1599px) {


  .live_game .heading_live_game .form-check .form_input_radio a {
    padding: 3px 8px;
  }

  .heading_live_game button.slick-arrow.slick-prev {
    top: -41px;
    left: 187px;
  }

  .heading_live_game button.slick-arrow.slick-next {
    top: -41px;
    left: 215px;
  }

  .live_game .heading_live_game .form-check .form_input_radio h4 {
    font-size: 17px;
  }

  .live_game .heading_live_game .slick-prev:before,
  .live_game .slick-next:before {
    width: 30px;
    height: 30px;
  }

}



.l-card.new_tournament_design {
  /* display: flex; */
  border: 1px solid #fff;
}

.issue_emrollment span {
  font-size: 14px;
}

.button_with_search_input {
  display: flex;
}

.button_with_search_input .btn {
  display: inline-block !important;
}

.team-top-fil.button_with_search_input .team-search {
  margin-bottom: 0;
}

.team-top-fil.button_with_search_input {
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}


table.table th {
  font-size: 17px;
  position: sticky;
  top: -1px;
  z-index: 61;
  font-family: 'Roboto';
}

.table-wrapper.enrollment_table_team_player table.table.new-table tr th {
  font-size: 16px;
  font-weight: 600;
}

.sidenav_offcanvas.approve_button-details.offcanvas.offcanvas-end.show .offcanvas-title.h5 {
  /* margin-top: 1rem; */
  font-size: 22px;

}

.create-match-wrap.season-popup.approve_button_table_listing_content button.btn-close {
  font-size: 24px;
  opacity: 0.3;
}

span.games-created {
  color: #27ae60;
  font-size: 20px;
}

.no-data p {
  color: #00000029;
  font-size: 17px;
  margin-bottom: 0;
}

.form-group.summary textarea.form-control {
  height: auto !important;
}

.both_team_detail h4 {
  color: #1D90E5;
}

.table_match_date_detail table.table th {
  font-size: 16px;
  color: #484848 !important;
}

td.table-color {
  color: #000 !important;
  font-size: 15px;
}

.create-match-wrap.season-popup.approve_button_table_listing_content textarea.form-control {
  height: auto;
  min-height: 233px;
}

.divison_group table.table.table-striped.table-bordered ul.dropdown-menu {
  text-transform: capitalize;
}

.text-group strong {
  color: #f05829;
}

.text-group p {
  margin-bottom: 0;
  font-size: 14px;
}

.text-group {
  margin-left: 10px;

}

.groups-count.team-list.team-views-bottom {
  padding-top: 45px;
}

/* .l-card.season-mange.team-card-details img.dots.team_img {
  margin-top: -4rem;
} */
.l-card.season-mange.team-card-details .dropdown.acts.team_component {
  margin-top: -4rem;
}

.card-border {
  border: 1px solid #F2F2F2;
  /* width: 348px; */
  margin-left: -8px;
  padding: 10px;
  border-radius: 10px;
  min-height: 187px;
}

/* .seasonDashboard_Season_heading .dropdown.acts {
  position: absolute;
  right: 0;
} */
span.btn-back-to-view {
  font-size: 18px;
  color: #f05829;
}

.back-btn-dashboard {
  margin-bottom: 22px;
  margin-left: 14px;
}

.back-btn-dashboard.season-backbtn {
  margin-bottom: 1px;
  margin-left: 14px;
}

.back-btn-dashboard a {
  background: transparent;
}

.create-form-inner.member-create input.form-control {
  width: 100%;
  max-width: 226px !important;
  min-width: 225px !important;
}

.create-form-inner.member-create .react-tel-input.form-control {
  padding: 0 !important;
  width: 100%;
  max-width: 226px;
  min-width: 225px;
  height: 41px !important;
}

tr {
  border-top: 1px solid #FAFAFA !important;
  border-bottom: 1px solid #FAFAFA !important;
}

.back-btn-dashboard a {
  max-width: 80px !important;
  min-width: 80px !important;
}

.team-top-fil.button_with_search_input .back-btn-dashboard {
  margin-bottom: 4px;
  margin-left: 14px;
}

.grade-content.team-list-side .table>thead {
  background-color: transparent !important;
}

/* button.border.border-0 {
  background: transparent;
} */

.react-tel-input .country-list .country {
  padding: 7px 9px;
  text-align: start;
}

.react-tel-input .country-list {
  width: 276px !important;
}

h4.dashboard-link {
  color: #000;
}

.payment_description tbody tr {
  border-bottom: 2px solid #E9E9E9 !important;
  border-top: 2px solid #E9E9E9 !important;
}

.dashboard-auto-wrapper.profile-dash {
  width: 964px;
  border-radius: 20px;
  margin: auto;
  margin-top: 3rem;
}

.cperson-img img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.dashboard-auto-wrapper.profile-dash .react-tel-input {
  min-height: 41px;
  max-height: 46px;
}

.dashboard-auto-wrapper.profile-dash .react-tel-input .form-control {
  height: 46px !important;
}

.dashboard-auto-wrapper.profile-dash .react-tel-input .selected-flag {
  padding: 42px 0 0 8px;

}

.updatepassword {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.panel_sports.create-form-inner.mt-4 {
  display: flex;
  gap: 27px;
  padding: 0px 0px;
  position: relative;
  flex-direction: column;
}

.game-seletcted {
  display: flex;
  gap: 7px;
  flex-direction: row;
  flex-flow: wrap;
}

button.btn.btn-dark.green.payment-btn {
  background: #27ae60;
  border: #27ae60;
  width: 100%;
  max-width: 162px;
}

.also-contact-with-sports {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
}

.updatepassword button.btn.btn-dark.savesubmit {
  background: #27ae60;
  border-color: #26ab5e;
}

.create-from-btn.submission.update-sports {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-from-btn.submission.update-sports button.btn.btn-dark.savesubmit {
  background: #27ae60;
  border-color: #27ae60;
  width: 100%;
  max-width: 300px;
  margin-top: 3rem;
}

.game-seletcted label.custom-check {
  display: inline;
  width: auto;
  font-size: 19px;
  font-weight: 400;
  margin-left: 1rem;
}

.panel_sports.create-form-inner h5 {
  color: #484848;
  font-weight: 300;
  font-size: 20px;
}

.text-group-color p {
  margin-bottom: 0;
}

.venue-card h6 {
  color: #f44336;
  font-size: 17px;
  margin-left: 0;

}

.l-card.season-mange.team-card-details.venue-card button.btn.btn-outline-primary.pay-btn {
  background-color: #27ae60;
  border: 0;
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  font-family: 'Roboto';
  font-weight: 500;
  margin-top: -6px;
}

.top_buttons_dropdown .time_schedule {
  margin-top: 0px;
  margin-right: 0;
}

.create-from.form_submit_basketball.createmember-team .cperson-img img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.venue-card .card-content p {
  margin-bottom: 10px;
  margin-top: -6px;
}

button.border.border-0 {
  background: #ddffeb;
  padding: 6px 13px;
  border-radius: 0px 11px 7px 0px;
}

.new_tournament_design .wrap .dropdown.acts {
  position: absolute;
  right: 0;
}

.form-group {
  margin-bottom: 1px !important;
}

.dashboard_organizer .accordion-item a.active span {
  color: #f05829;
}

.dashboard-auto-wrapper .cperson-img {
  width: 16%;
}

.dashboard-auto-wrapper.season-popup .cperson-img img {
  width: 84px;
  height: 84px;
  object-fit: cover;
  border-radius: 50%;
}

/* .l-card.new_tournament_design:hover {
  border: 1px solid #f44336 !important;
  transition: all 1s linear;
}

.l-card.new_tournament_design:focus-visible {
  border: 1px solid #fff !important;
  outline: 0;
} */

.l-card.new_tournament_design:hover {
  border: 1px solid;
  border-color: #f44336;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 13%);
}

.team_component .team_img {
  min-height: 24px !important;
  min-width: 4px;
  max-width: 6px;
}

.dropdown.acts.team_component {
  position: absolute;
  top: 79px;
  right: 12px;
}

.l-card.season-mange.team-card-details.teams-card {
  position: relative;
}

.l-card.season-mange.team-card-details.teams-card .team_component .dropdown-menu .dropdown-item img {
  width: 100%;
  object-fit: contain !important;
  border-radius: inherit !important;
  min-width: 18px;
  max-width: 19px;
}

.top-backgrounds-bnanner {
  background: #484848;
  padding: 17px 46px;
  border-radius: 10px 10px 0px 0px;
}

.background_body_table.games-table-body {
  margin: 0px 0;
}

button.btn.btn-primary.py-2 {
  background: #27ae60;
  /* padding: 21px 37px; */
  /* 
  min-width: 168px;
  min-height: 38px; */
}

button.btn.btn-light.py-2 {
  background: transparent;
  border: transparent;
  color: #fff;
}




.nav-link-1.nav-item a.nav-link {
  border: 1px solid #fff;
  padding: 0px 14px;
  border-radius: 8px;
  color: #fff;
}

.nav-link-2.nav-item a.nav-link {
  border-color: #fff;
  padding: 0px 11px 0px 14px;
  color: #fff;
  border-left: 0px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-radius: 1px 10px 11px 0px;
}

.games-table a.nav-link.active {
  background: #ffffff59;
}

.games-table span {
  margin-right: 10px;
}

.nav-link-2.nav-item {
  margin-left: -3px;
}

/* .top-backgrounds-bnanner select.form-select {
  background: transparent;
  color: #fff;
  border: 1px solid #fff0 !important;
} */

.l-card.season-mange.team-card-details.venue-card .l-title.img_create_team .card-content {
  margin-right: 31px;
}

.lines {
  min-height: 20px;
  border: 1px solid #ffffff75;
}

.generate-game {
  padding: 0px 41px;
  text-align: center;
}

button.btn.btn-primary.update-matches.my-2 {
  background: #27ae60;
  border-color: #27ae60;
}

td.text-center.vicsion strong {
  color: #F05829;
  font-size: 18px;
}

button.btn.btn-primary.update-matches:hover {
  background: #5cbd60;
  box-shadow: 0 0 11px rgba(33, 33, 33, .2) !important;
  transition: all 0.2s ease-in-out;
}

ul.selected_team_tabbing.nav.nav-tabs {
  margin-bottom: 18px;
  margin-top: 13px;
}

.l-card.season-mange.team-card-details.venue-card {
  position: relative;
}

.l-card.season-mange.team-card-details.venue-card .dropdown.acts.team_component img.dots.team_img {
  min-height: 18px !important;
  min-width: 6px;
}

.l-card.season-mange.team-card-details.venue-card .team_component .team_img {
  min-height: 24px !important;
  min-width: 17px;
  max-width: 6px;
}

.dropdown.acts.team_component.dropdown_team.player-card-dropdown {
  position: absolute;
  top: 14px;
  right: 7px;
}

img.rounded-circle.upadateimg {
  width: 72px;
  height: 72px;
  object-fit: cover;
}

img.rounded-circle.veneu-profile-user {
  width: 70px;
  height: 70px;
}

a.nav-link {
  font-family: 'Roboto';
}

/* .search-teams {
  display: flex;
  justify-content: space-between;
} */
.serach-properties .back-btn-dashboard {
  display: flex;
  margin-left: 14px;
  margin-top: 4px;
}

.serach-properties {
  display: flex;
}

.team-heading button.btn.btn-dark.green.orange-color {
  margin-right: 0rem;
  padding: 7px 41px;
}

.create-cards {
  margin-top: 6rem;
}

/* .search-teams {
  position: fixed;
  background: #eeeeee;
  z-index: 5;
  padding: 13px 0rem 0rem 0rem;
  width: 100%;
  max-width: 84%;
} */

.search-teams {
  overflow: hidden;
  background-color: #eee;
  position: fixed;
  top: 98px;
  padding: 1rem 5rem 0rem 2rem;
  width: 100%;
  /* max-width: 86%; */
  z-index: 5;
  border-radius: 0px 0px 8px 8px;
  margin-left: -2rem;
}


.wrap-create-button {
  position: fixed;
  left: 83%;
}

.import-doctument-offcanvacs {
  /* height: 99vh; */
  margin-top: 5rem !important;
}

.wrap-create-button.venuw-wrap-create-button {
  left: 91%;
}

.create_teams-modal button.btn.btn-dark.green.orange-color {
  margin-right: 4rem;
  padding: 7px 38px;
}

.l-card.season-mange.team-card-details.teams-card:hover {
  border: 1px solid #ef5829;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 13%);
  transition: all 0.3s ease;
}

.l-card.season-mange.team-card-details.teams-card {
  border: 1px solid #fff;


}

.teams-fixed-properties {
  display: flex;
  justify-content: space-between;
}

.l-card.season-mange.team-card-details.teams-card .l-title.img_create_team {
  margin-right: 2rem;
}

img.rounded-circle.adim-profile-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.panel_sports.create-form-inner .checkmark {
  height: 28px;
  width: 28px;
}

.panel_sports.create-form-inner .custom-check .checkmark:after {
  left: 9px;
  top: 3px;
  width: 8px;
  height: 15px;
}

.game-seletcted span.checkmark {
  margin-left: -6px;
}

.l-card.season-mange.team-card-details.teams-card .card-content .text-group strong {
  color: #f05829;
  font-weight: 500;
  font-size: 16px;
}

.l-card.season-mange.team-card-details.teams-card .card-content h6 {
  font-weight: 500;
  font-size: 16px;
}

.parties .groups-count {
  padding-top: 2rem;
}

.new_tournament_design .wrap .dropdown.acts .dropdown-toggle {
  margin-right: 5px;
}

input#firstname {
  outline: 0;
}

span.mem-no {
  display: flex;
  white-space: nowrap;
}

.c-details-fix {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-details-fix p {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.table_background_round_team.p-0.round-roins-hieght {
  height: 71vh;
  overflow-y: auto;
}

.table_background_round_team.p-0.round-roins-hieght table.table.table-striped thead {
  position: sticky;
  z-index: 5;
}

td.email-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 174px;
}

td.reason-fix {
  white-space: nowrap;
}

button.btn.btn-danger.savesubmit.payment-btn.mt-1 {
  border: 0;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seasonDashboard_Season_heading .dropdown.acts {
  position: absolute;
  right: 0;
}

.duepaymet .payment-deals {
  display: flex;
  align-items: center;
  justify-content: center;
}

img.venue-profile {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}

td.text-with.padding-remove {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 157px;
}

a.dropdown-item.active {
  background: transparent;
}

.seasonDashboard_updateScore .divison_group th {
  text-transform: uppercase;
  /* white-space: normal; */
  position: relative;
  padding: 9px 7px !important;
  font-family: 'Roboto';
  font-size: 15px;
  background: transparent;
  border-color: transparent !important;
  width: 33.3%;
}

.current-season-schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .time_schedule select.form-select {
  max-width: 253px;
  min-width: 270px;
} */

.current-season-schedule a.btn.btn-primary.common-button.nav-link {
  width: 100%;
  min-height: 42px;
  background-color: #27AE60;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 86px;
}

/* 
.time_schedule {
  margin-top: -18px;
  margin-right: 14px;
  margin-left: 18px;
} */

input#exampleFormControlInput1 {
  border: 0;
}

span.paid_button button.btn.btn-primary {
  width: 100%;
  min-width: 165px;
  max-width: 171px;
  background: #27AE60 !important;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_description {
  width: 100%;
  background: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  height: 489px;
  overflow: auto;
}

.email a {
  color: #484848;
}

.email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 159px;
}

.pendind-paments-details {
  width: 220px;
  margin-right: 17px;
}

td.text-left-amount {
  text-align: right !important;
}

span.view-payment-details {
  cursor: pointer;
}




.lds-spinner {
  color: green;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  text-align: center;

}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  width: 4px;
  height: 26px;
  border-radius: 20%;
  background: red;
  right: 0;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}



.tabbing_all_games.main-dashboard {
  border: 1px solid #E6E6E6;
  border-radius: 11px;
  width: 100%;
  margin-bottom: 10px;
  transition: all 0.4s ease-in-out;
  background: #fff;
  min-height: 348px;
}

.live_game .empty-state__help {
  text-align: center;
}


.payment_description::-webkit-scrollbar {
  background-color: initial;
  height: 80px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  width: 6px;
}

.payment_description::-webkit-scrollbar {
  visibility: hidden;
}

.payment_description::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.payment_description::-webkit-scrollbar-track {
  background-color: initial;
  -webkit-box-shadow: transparent;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.d-flex.justify-content-center.dashboard-loader {
  position: absolute;
  left: 44%;
  top: 50%;
}


/* 
aside.ps-sidebar-root.css-wljo3u {
  border: 0;
  background: #fff;
  width: 286px;
  min-width: 286px;
} */


/* styles.css */


.sidebar:hover .sub-menu {
  display: block;
  /* Show the submenu on hover */
}

a.ps-menu-button {
  text-align: start;
}

a.ps-menu-button {
  border-top: 1px solid #cccccccf;
}


/* .css-1t8x7v1 >.ps-menu-button:active {
  background: #f44336 !important;
  color: #fff !important;
}

.css-x3jz15 >.ps-menu-button:focus {
  background: #f44336;
  color: #fff;
}

.css-1t8x7v1 >.ps-menu-button:focus {
  background: #f44336;
  color: #fff;
}


.ps-submenu-content.css-wlra1l {
  margin-top: 3rem !important;
}

 */
.season-trophy-box .time_schedule {
  max-width: 253px;
  /* min-width: 270px; */
}

.side-nav a {
  color: #000;
}


/* .css-1t8x7v1 >.ps-menu-button:hover {
  background-color: #f44336 !important;
  color: #ffff;
}


.css-16jesut >.ps-menu-button:hover {
  background-color: #f44336 !important;
  color: #ffff;
}

.css-1ffbfs >.ps-menu-button:hover {
  background-color: #f44336 !important;
  color: #ffff;
} */

.fade.welcome-admin.modal.show .modal-dialog.modal-lg {
  max-width: 900px;
}

.fade.welcome-admin.modal.show .modal-header {
  border-bottom: 0;
}

.fade.welcome-admin.modal.show .modal-footer {
  border-top: 0;
}

li.ps-menuitem-root.ps-submenu-root.highligh-sports.css-16jesut {
  background: #f0eae8;
}

li.ps-menuitem-root.ps-submenu-root.highligh-sports.css-16jesut ul.css-ewdv3l {
  background: #f0eae8;
}

.fade.welcome-admin.modal.show .modal-header {
  justify-content: center;
}

.fade.welcome-admin.modal.show .modal-title.h4 {
  font-size: 30px;
  font-weight: 700;
}

.fade.welcome-admin.modal.show .modal-content {
  border: 0;
}



nav.ps-menu-root.css-vj11vy {
  height: 84vh !important;
  overflow: auto;
}

nav.ps-menu-root.css-vj11vy::-webkit-scrollbar {
  /* display: block; */
  transition: all 0.4s ease-in-out;
  opacity: 0;
}

nav.ps-menu-root.css-vj11vy::-webkit-scrollbar-track {
  -webkit-box-shadow: transparent;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

nav.ps-menu-root.css-vj11vy::-webkit-scrollbar {
  width: 6px;
  height: 80px;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

nav.ps-menu-root.css-vj11vy::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  transition: all 0.4s ease-in-out;
}

.position-relative .mega_menu_content2 {
  width: 266px;
  /* max-width: 535px; */
  background: #E6E6E6;
  position: absolute;
  left: 548px;
  /* min-width: 535px; */
  top: -356px;
  border: 1px solid #E6E6E6;
  text-align: left;
  transition: all 0.4s ease-in-out;
  min-height: 555px;
  overflow-y: auto;
  max-height: 555px;
  overflow-x: auto;
}

span.view-grop-page {
  white-space: nowrap;
}

.mega_menu_content2 h4.heading_tournament {
  color: #8D8D8D;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 20px;
}


hr.verticle-season {
  color: #00000036;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mega_menu_content2 a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
}

.mega_menu_content2 ul li a:hover {
  background: #fff !important;
}


.mega_menu_content2 h4 {
  font-size: 15px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0;
}


.mega_menu_content2 .heading_tournament span a {
  color: #f05829 !important;
  font-size: 14px;
  font-weight: 600;
}


.video_content button.accordion-button.collapsed {
  width: auto;
}

button.accordion-button {
  width: auto;
}

.mega_menu_content2 ul {
  padding-left: 0px;
}

.top-payment p {
  font-size: 14px;
  margin-bottom: 0px;
}

/* tr.text-formate-center .empty-state {
  margin-left: 5rem;
  text-align: center;
} */

.password-input-wrapper span.icon.Password-hide {
  color: #757982;
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 12px;
  width: 20px;
}

.password-input-wrapper {
  position: relative;
}

.payment-spinner {
  height: 205px;
}


table.table-match.table-responsive.table-striped select.form-select {
  font-size: 14px;
}


/* .custom-pop-box.table-pop-up.enroll-wrap {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 25px;
  padding-left: 25px;
} */



.color-wrapper {
  position: relative;
  width: 250px;
  /* margin: 20px auto; */
  display: flex;
  align-items: center;
}

.color-wrapper p {
  margin-bottom: 5px;
}

input.call-picker {
  border: 1px solid #AAA;
  color: #666;
  text-transform: uppercase;
  float: left;
  outline: none;
  padding: 10px;
  text-transform: uppercase;
  width: 85px;
}

.color-picker {
  width: 153px;
  background: #F3F3F3;
  height: 105px;
  padding: 5px;
  border: 5px solid #fff;
  box-shadow: 0px 0px 3px 1px #DDD;
  position: absolute;
  top: 52px;
  left: 102px;
}

.color-holder {
  background: #fff;
  cursor: pointer;
  border: 1px solid #AAA;
  width: 100%;
  height: 36px;
  float: left;
  margin-left: 5px;
}

.color-picker .color-item {
  cursor: pointer;
  width: 15px;
  height: 15px;
  list-style-type: none;
  float: left;
  margin: 2px;
  border: 1px solid #DDD;
}

.color-picker .color-item:hover {
  border: 1px solid #666;
  opacity: 0.8;
  -moz-opacity: 0.8;
  filter: alpha(opacity=8);
}

.view-brackets-btn {
  display: flex;
  justify-content: end;
  align-items: center;
}





.games-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 8px 0px; */
}

img.sports-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.games-section-sports .savesubmit {
  width: 104px !important;
  height: 33px !important;
  margin-bottom: 12px;
  margin-top: 12px;
}

ul.dropdown-menu.Sports-dropdown-menu li.dropdown {
  font-size: 20px;
  border-bottom: 1px solid #ccc;
  margin: 0px;
  padding: 5px 9px;
}

.table-scroll-2 {
  height: 680px;
  overflow: auto;
}

.create-form-inner.create-match .form-group {
  padding: 6px 0px;
}

.input-right-icon span.text-danger {
  font-size: 13px;
  position: absolute;
}

.input-right-icon {
  position: relative;
  margin-bottom: 0.5rem;
}

.create-match-wrap.season-popup .offcanvas-header {
  padding: 10px 0 0;
}

.link-menu:hover {
  color: #f1372b !important;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px !important;
}

.seasonDashboard_divison_group .divison_group.start-dividon .table_list table thead tr th {
  width: 20%;
}

.seasonDashboard_divison_group .divison_group.start-dividon .table_list table thead tr th.disk {
  width: 38%;
}

.seasonDashboard_divison_group .divison_group.start-dividon .table_list table tbody tr td {
  width: 12%;
}

.seasonDashboard_divison_group .divison_group.start-dividon .table_list table tbody tr td.disk {
  width: 38%;
}

th.coach-table {
  padding-left: 12px !important;
}

.divison_group.start-dividon td.padding-remove-1 {
  padding: 13px 0px !important;
}

label.label-setting {
  word-break: break-all;
}

td.padding-remove-1.coach-name {
  padding: 13px 0px !important;
}

.serach-properties.fixselect-box select.form-select {
  height: 40px;
}

.reports-table select:focus-visible {
  outline: 0;
}

.reports-table-view table.table th {
  z-index: 1 !important;
}

.rmdp-container {
  width: 100%;
}

li.option {
  text-align: justify;
}

h5.text-orange {
  color: #f1582a;
}

.card.calender-cards-box {
  border: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* min-width: 180px; */
  /* max-width: 240px; */
  width: 100%;
  font-family: 'Roboto';
}

.border-secondary {
  border-color: #d6d7d9 !important;
}

.date-calenter-contentent::before {
  left: 0;


}

.date-calenter-contentent::after {
  left: 0;
}


.calender-formicon {
  position: absolute;
  content: '';
  width: 25px;
  height: 28px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAdCAYAAAC0T3x2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJPSURBVHgBxVbLceMwDKVo+a4SuBVE/s74tN4KElewdgXZVOCkgqwrsNPBpgPtyTP+TLwVrNKBzv7mPQ2YYRhlkoNlY4ZD4pEACEAAFSiPptOpqVarYyy7QRBMtNY3tVot495yubw/HA59LNPNZtPrdDop8cVicY3pFiPb7/e9dru98vVqH4CRX5gMRg9Kr7bbbV+UXZHHcuCcyy+G6bfgj7jYvSogXYBF8CRpNpt/sObNIoIwEtMT4lg/Yn1BPAxDw1nOJ5A1RYZCx/U+Riz4xB6A4BD7Q2ETR7YL/OAqwwUYYiP4CmEcIYy5Lo2498X1WB2XYoRxPJ/P8xBr3OJalUgwlutnjt55AuNmNpsRN95WTBzhvPBliEPpZZEuzqEqpi6Engrw6AOcN3+CUnV2CvAxjFXJ1Gg0BqFUetk00OpE9KkheHyHqg840N++5UJaG4uBBhip5TnU28L+mqFj0ckMBX6/KoMYTt+jBI2whrz8kHywe4+IWZyH7D4H1jeAMss7cm/oXWdwHy14m7EjW0weRVWpVP63Wq1nYqhDduzMPoJWztd7thzxJq7bsWCp8JFgiXPGCO7LRZZhjvzQZaiJB8vIq7oCxhdVIU8G6xj4X9RSSmy327EBX3pyxjVE8g2l6EsTy8DbnxD6hxvlmORoiByN6/W6zRGn776c8p6Y8+UIHoxeN4OAN0sZKuEZDr6YI/k/UPIIdgvkjOXzVnXKgs3UCYiGHlSJxL/d3BC+oFtV0DKORMl6vb7LDVoE/1+sB6OORKi5FG0qsfwLM2MzpPkU3FcAAAAASUVORK5CYII=);
  left: 0;
  top: 6px;
}

.date-arrew {
  box-shadow: rgb(153 153 161 / 20%) 0px 7px 29px 0px;
  width: 42px;
  display: flex;
  justify-content: center;
  height: 42px;
  align-items: center;
  border-radius: 9px;
  cursor: pointer;
  border-radius: 50%;
}

.calender-content input[type=date] {
  font-size: 12px;
  border: 0;
}

/* .date-content::before{
display: none;
}

.date-content::after{
  display: none;
} */

.date-content-1::before {
  display: none !important;
}

.date-content-1::after {
  display: none !important;


}

/* .date-content-calender input[type=date]::after {
  position: absolute;
  content: '';
  width: 25px;
  height: 28px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAdCAYAAAC0T3x2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJPSURBVHgBxVbLceMwDKVo+a4SuBVE/s74tN4KElewdgXZVOCkgqwrsNPBpgPtyTP+TLwVrNKBzv7mPQ2YYRhlkoNlY4ZD4pEACEAAFSiPptOpqVarYyy7QRBMtNY3tVot495yubw/HA59LNPNZtPrdDop8cVicY3pFiPb7/e9dru98vVqH4CRX5gMRg9Kr7bbbV+UXZHHcuCcyy+G6bfgj7jYvSogXYBF8CRpNpt/sObNIoIwEtMT4lg/Yn1BPAxDw1nOJ5A1RYZCx/U+Riz4xB6A4BD7Q2ETR7YL/OAqwwUYYiP4CmEcIYy5Lo2498X1WB2XYoRxPJ/P8xBr3OJalUgwlutnjt55AuNmNpsRN95WTBzhvPBliEPpZZEuzqEqpi6Engrw6AOcN3+CUnV2CvAxjFXJ1Gg0BqFUetk00OpE9KkheHyHqg840N++5UJaG4uBBhip5TnU28L+mqFj0ckMBX6/KoMYTt+jBI2whrz8kHywe4+IWZyH7D4H1jeAMss7cm/oXWdwHy14m7EjW0weRVWpVP63Wq1nYqhDduzMPoJWztd7thzxJq7bsWCp8JFgiXPGCO7LRZZhjvzQZaiJB8vIq7oCxhdVIU8G6xj4X9RSSmy327EBX3pyxjVE8g2l6EsTy8DbnxD6hxvlmORoiByN6/W6zRGn776c8p6Y8+UIHoxeN4OAN0sZKuEZDr6YI/k/UPIIdgvkjOXzVnXKgs3UCYiGHlSJxL/d3BC+oFtV0DKORMl6vb7LDVoE/1+sB6OORKi5FG0qsfwLM2MzpPkU3FcAAAAASUVORK5CYII=);
  right: 9px;
  top: 6px;
  display: none !important;
} */




/* .date-content-calender input[type=date]::before {
  position: absolute;
  content: '';
  width: 25px;
  height: 28px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAdCAYAAAC0T3x2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJPSURBVHgBxVbLceMwDKVo+a4SuBVE/s74tN4KElewdgXZVOCkgqwrsNPBpgPtyTP+TLwVrNKBzv7mPQ2YYRhlkoNlY4ZD4pEACEAAFSiPptOpqVarYyy7QRBMtNY3tVot495yubw/HA59LNPNZtPrdDop8cVicY3pFiPb7/e9dru98vVqH4CRX5gMRg9Kr7bbbV+UXZHHcuCcyy+G6bfgj7jYvSogXYBF8CRpNpt/sObNIoIwEtMT4lg/Yn1BPAxDw1nOJ5A1RYZCx/U+Riz4xB6A4BD7Q2ETR7YL/OAqwwUYYiP4CmEcIYy5Lo2498X1WB2XYoRxPJ/P8xBr3OJalUgwlutnjt55AuNmNpsRN95WTBzhvPBliEPpZZEuzqEqpi6Engrw6AOcN3+CUnV2CvAxjFXJ1Gg0BqFUetk00OpE9KkheHyHqg840N++5UJaG4uBBhip5TnU28L+mqFj0ckMBX6/KoMYTt+jBI2whrz8kHywe4+IWZyH7D4H1jeAMss7cm/oXWdwHy14m7EjW0weRVWpVP63Wq1nYqhDduzMPoJWztd7thzxJq7bsWCp8JFgiXPGCO7LRZZhjvzQZaiJB8vIq7oCxhdVIU8G6xj4X9RSSmy327EBX3pyxjVE8g2l6EsTy8DbnxD6hxvlmORoiByN6/W6zRGn776c8p6Y8+UIHoxeN4OAN0sZKuEZDr6YI/k/UPIIdgvkjOXzVnXKgs3UCYiGHlSJxL/d3BC+oFtV0DKORMl6vb7LDVoE/1+sB6OORKi5FG0qsfwLM2MzpPkU3FcAAAAASUVORK5CYII=);
  right: 9px;
  top: 6px;
  display: none !important;
} */

/* .date-content-calender::after {
  background: bisque !important;
  background-image: none !important;
  display: none;
} */

.date-content-calender::before {
  background: bisque !important;
  background-image: none !important;
  display: none;
}

.serach-properties.search-calender-box input {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.card.calender-cards-box p {
  margin: 0;
}


/* table.table-match.venues-table-scrore tr.team-head {
  color: #484848;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  z-index: 1;
}
table.table-match.venues-table-scrore tbody {
  overflow-y: auto;
  height: 705px;
  display: block;
  overflow-x: hidden;
} */

.venu-nodata {
  height: 675px;
}

/* .division-check {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
} */
.division-check .form-check-input {
  margin-left: 0;
  width: 22px;
  height: 22px;
  margin-top: 0px;
}

span.txt-orange {
  font-size: 16px !important;
  color: #f05829;
  margin-left: 15px;
}

.division-check .form-check {
  margin: 0;
  /* padding: 0; */
}

.division-check label {
  font-weight: 500 !important;
}


/* Make the background of the checkbox black */
.custom-checkbox {
  width: 20px;
  height: 20px;
  /* background-color: black; */
  border-radius: 4px;
  /* Optional: Add rounded corners */
  border: 2px solid #ccc;
  /* Border color */
  appearance: none;
  /* Remove default checkbox styling */
  -webkit-appearance: none;
  /* For Safari */
  cursor: pointer;
  position: relative;
}

/* Checkmark style when the checkbox is checked */
.custom-checkbox:checked {
  background-color: black;
  border: 1px solid #ccc;
}

.custom-checkbox:checked::after {
  /* content: ""; */
  position: absolute;
  top: 2px;
  left: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.division-flow button.btn {
  padding: 5px 54px;
  border-radius: 5px;
}

.card-header svg {
  fill: #27ae60;
}

.venue-schedule-table.venue-schedule-table {
  border: 1px solid #ccc;
  border-radius: 7px;
}




/* kartik add styling css */

/* 
.d-flex.justify-content-center.teams-heading {
  margin-top: 10rem;
} */

/* 
.gap-2 {
  gap: 18.5rem !important;
} */


th.border.border-secondary {
  background: #787171 !important;
  color: rgb(238, 238, 238) !important;

}


.giunavigation img {
  width: 73%
    /* margin-left: 85px !important; */
    /* margin-top: 3rem; */
}

.navmain {
  gap: 20px;
}

/* .d-flex.justify-content-center.teams-heading {
  margin-top: 3rem;
} */

/* list code */

li.list-group-item.border.border-primary.teamname {
  align-items: center;
  display: flex;
  justify-content: start;
  padding-left: .5rem;
  border: 1px solid grey !important;
  margin-top: 1rem;
  border-radius: 10px;
}

.gap-2 {
  gap: 11.5rem !important;
}


.d-flex.gap-2.align-items-center.navmain {
  gap: 60px !important;
}

.giunav-logo {
  width: 144px;
}

.games-table-venues li.nav-item {
  border: 0 !important;
  --bs-nav-tabs-border-width: none;
}

.file-uploaded-content {
  border: 1px solid;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 3px;
  width: 72%;
}

textarea#exampleForm\.ControlTextarea1 {
  height: auto;
}


.green-checkbox input[type="checkbox"] {
  accent-color: #27AE60;
  /* For modern browsers supporting accent-color */
  width: 24px;
  height: 24px;
}

.green-checkbox input[type="checkbox"]:checked {
  background-color: #27AE60;
  /* Fallback for older browsers */
  border-color: #27AE60;
}

.green-checkbox.form-check {
  margin: 20px 0px 0px;
}

label.form-check-label {
  margin-left: 9px;
}

.barchat_verified {
  position: absolute;
  right: 16px;
  top: 10px;
}

.seasonDashboard_divison_group .divison_group.roster-table th {
  width: 20% !important;
}

.seasonDashboard_divison_group .divison_group.roster-table td {
  width: 20% !important;
}

.max-weight h5 {

  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.seasonDashboard_divison_group .divison_group.roster-table .table_list table tbody {
  overflow-y: auto;
  height: 229px;
  display: block;
}

.RosterSheet_background {
  width: 90%;
  background: #fff;
  /* padding-right: 15px; */
  /* padding-left: 15px; */
  padding-bottom: 15px;
  border-radius: 10px;
  /* height: 73vh;
  overflow-y: auto;
  overflow-x: hidden; */
  margin: auto;
}

.RosterSheet {
  padding: 1rem 3rem;
}

.Team-details label {
  text-align: justify;
}

.tracked_table input.form-control {
  border-color: transparent;
  background: transparent;
  height: 20px;
}

.tracked_table tr {
  border-top: 1px solid #938787 !important;
  border-bottom: 1px solid #534b4b !important;
}

/* .RosterSheet td, th{
  border-width: 1px !important;
} */

.RosterSheet label {
  margin: 0;
}

.tracked_table .table-striped>tbody>tr:nth-child(even) {
  --bs-table-bg-type: #969696 !important;
}

.tracked_table th td {
  color: #000 !important;
  /* border-color: #000 !important; */
  vertical-align: middle;
  text-align: center;
  border-top: 1px solid;
}

.feedbackform textarea#comments {
  height: auto;
  border: 0;
}

.feedbackform .form-check.form-check-inline {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000 !important;
}

.feedbackform .form-check-input {
  width: 20px;
  height: 21px;
  border: 1px solid;
}

.form-team label {
  border-right: 1px solid #000;
}

.form-team input {
  border: 0 !important;
}

.form-team .form-check label.form-check-label {
  border: 0;
}

.file-uploaded-content a.text-dark {
  word-break: break-all;
}

.RosterSheet_background td {
  color: #000 !important;
}



.Upload_Logo_Roster {
  width: 90%;
  margin: auto;
}

.important-th {
  border-width: 1px !important;
  border-color: #000 !important;
  font-size: 17px !important;

}

.divison_group.roster-table input[type="checkbox"] {
  width: 35px;
  height: 19px;
}

textarea.form-control {
  height: auto;
}

.roaster-top-nav {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
}

.payment-details-checkbox input.form-check-input {
  margin: 0;
}

button.btn.btn-orange.btn-padding.text-white:hover {
  background: #cd3202cc;
}
button.btn.btn-orange.btn-padding.text-white:focus {
  background: #cd3202cc;
}
button.btn.btn-orange.btn-padding.text-white:active {
  background: #cd3202cc;
}
button.btn.btn-orange.btn-padding.text-white:focus-visible {
  background: #cd3202cc;
}


button.close-payment-box {
  width: 41px !important;
  height: 52px;
}
.file-uploaded-content svg {
  cursor: pointer;
}


.game_type_payment_transaction img.trophy_img {
  object-fit: cover;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}