table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #ffffff;
  }

  tr  {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
tr th, tr td{
    font-size: 14px;
    padding: 10px 9px !important;
  }