body {
    font-family: 'Roboto', sans-serif!important;
}
a{
	text-decoration:none!important;
}
.blue-link {
    color: #00acc0;
}
.blue-link:hover {
    color: #1f9ead;
}
.btn.btn-primary {
    background: #f1582a;
    border-color: #f1582a;
    border: 0;
}
.btn-lg{
    padding: 13px 20px!important;	
}
.full-btn {
    width: 100%;
}
.btn.btn-primary:hover {
    background: #f1582a;
}
.btn {
    box-shadow: unset!important;
}
/* The custom-check */
.custom-check {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #828282;
}

/* Hide the browser's default checkbox */
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    background-color: transparent;
    border: 2px solid #D7D7D7;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.custom-check:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-check input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-check .checkmark:after {
    left: 5px;
    top: 0px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.custom-check input:checked ~ .checkmark {
    background-color: #000;
    border: 2px solid #000;
}
label.custom-check.check-without-input {
    padding-left: 0;
}
#login-page {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    display: flex;
    background: #fff;
}
.login-form {
    display: table;
    width: 60%;
    height: 100%;
    float: left;
}
.login-img {
    display: table;
    width: 40%;
    float: left;
    height: 100%;

    background: url(../images/login-bg.png);
    background-size: cover;
}
.login-form-inner {
    display: table-cell;
    vertical-align: middle;
    padding: 50px 15px;
    position: relative;
}
.login-logo {
    width: 600px;
    text-align: center;
    margin: 0 auto 25px;
    max-width: 95%;
}
.login-logo img {
    width: 315px;
}
.forgot-password label.custom-check a {
    margin-left: 5px;
    color: #000;
}
.login-logo p {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin-top: 15px;
}
.login-form-box {
    width: 600px;
    margin: 0 auto;
    max-width: 95%;
}
.login-logo h2 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
}
.login-form-box label {
    margin-bottom: 8px;
    color: #757575;
    font-weight: 500;
}
label.custom-check {
    font-size: 15px;
    font-weight: normal;
    display: inline-block;
}
.forgot-password a {
    float: right;
    text-decoration: none;
    font-size: 15px;
    color: #757575;
}
.forgot-password a:hover {
    color: #000;
}
.form-bottom-link {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    color: #828282;
    left: 0;
}
.form-bottom-link a {
    color: #000;
}
.login-img-inner {
    display: table-cell;
    vertical-align: middle;
    padding: 50px 0;
	position: relative;
}
.dashboard-title {
    color: #fff;
    width: auto;
    margin: 0 auto 0 40px;
    padding-left: 40px;
    padding-right: 40px;
}
.dashboard-title h3 {
    font-size: 24px;
    font-weight: 400;
}
.dashboard-title h1:after {
    content: "";
    width: 150px;
    height: 2px;
    background: #dfdfdf;
    display: block;
    margin: 20px 0;
}
.dashboard-title p {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
}
.login-form-box .form-control {
    height: 60px;
    border-color: #ddd;
    border-radius: 0;
	outline: unset;
    box-shadow: unset;
}
.form-control:focus{
	border-color:#00acc0;
}
.error {
    color: red;
    font-size: 14px;
	display: none;
}
.back-btn {
    text-align: left;
    margin-bottom: 4px;
}
.app-icons {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
}
.app-icons a {
    display: inline-block;
    width: 54px;
    height: 54px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    padding-top: 13px;
    margin: 0 10px;
}
.common-message-pop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    display: none;
    left: 0;
    top: 0;
}
.common-message-pop.show{
	display: table;
}
.common-pop-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.common-pop-box {
    width: 500px;
    background: #fff;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
    margin: 0 auto;
}
.error-icon {
    color: #ff8585;
    font-size: 100px;
}
.success-icon{
    color: #35b32d;
    font-size: 100px;
}
.message-pop-button {
    margin-top: 30px;
}
.message-pop-button button {
    padding: 10px 20px;
}
.form-sec label {
    margin-bottom: 5px;
    width: 100%;
}
#register-page {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    height: 100vh;
}
#register-page .login-form {
    flex: 0 0 60%;
}
#register-page .login-img {
    background: url(../images/login-bg.png);
    background-size: cover;
    flex: 0 0 40%;
    display: flex;
    height: auto;
    align-items: center;
}
.restaurant-photo-input {
    width: 250px;
    height: 150px;
    border: 2px dashed #ddd;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #ddd;
}
.restaurant-photo-box {
    position: relative;
    width: 250px;
}
input.photo-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 9;
}
.input-icon {
    position: relative;
}
.input-icon input {
    padding-left: 40px;
}
.input-icon img {
    position: absolute;
    right: 10px;
    top: 23px;
}
.input-icon i {
    position: absolute;
    left: 10px;
    top: 20px;
    font-size: 20px;
}
#verification-page {
    padding: 50px 0px;
    text-align: center;
    min-height: 100vh;
    position: relative;
}
.verification-logo img {
    width: 230px;
}
p.wait-txt {
    font-size: 18px;
    font-weight: 500;
}
.verification-ing img {
    width: 500px;
    max-width: 100%;
}
/* #verification-page {
    background-color: #fff;
} */
.also-contact {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    left: 0;
}
.also-contact a {
    text-decoration: underline!important;
}
.continue-btn button {
    width: 600px;
    max-width: 100%;
    height: 55px;
    margin-top: 50px;
}
.continue-btn .btn-dark.disabled, .continue-btn .btn-dark:disabled {
    background-color: rgba(0,0,0,0.3);
    border-color: rgba(0,0,0,0.3);
    opacity: 1;
}
.verification-logo p {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    margin-top: 15px;
}
.btn.gray-transparent-btn:hover {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0,0,0,1);
}
.btn.gray-transparent-btn {
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0,0,0,0.6);
}
#payment-success-pop .common-pop-box {
    width: 400px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.popup-logo img {
    width: 200px;
}
.popup-logo {
    margin-bottom: 50px;
}
.message-icon img {
    width: 130px;
    margin-bottom: 30px;
}
p.message-txt {
    font-weight: 500;
    font-size: 18px;
    color: #000;
}
/*4-4-2023 Preeti */
.custom-check input:checked ~ .checkmark {
    border: 2px solid #282041;
}

/*********************************************************************RESPONSIVE*********************************************************************************** */
@media screen and (max-width: 1200px) {
    .login-form {
        width: 55%;
    }
    .login-img {
        width: 45%;
    }
    .dashboard-title {
        margin: 0 auto 0 0px;
    }

    #register-page {
        display: inherit;
        flex-wrap: wrap;
    }
    #register-page .login-form {
        width: 55%;
    }
    #register-page .login-img {
        width: 45%;
    }
    #register-page .dashboard-title {
        margin: 0 auto 0 0px;
    }
}
@media screen and (max-width: 1024px) {
    .login-form {
        width: 100%;
    }
    .login-img {
        display: none;
    }
    .login-form-inner {
        padding: 50px 0;
    }
    .login-form-box {
        max-width: 100%;
        padding: 0 20px;
    }

    #register-page .login-form {
        width: 100%;
    }
    #register-page  .login-img {
        display: none;
    }
    #register-page  .login-form-inner {
        padding: 50px 0;
    }
    #register-page .login-form-box {
        max-width: 100%;
        padding: 0 20px;
    }
}



@media screen and (max-width: 767px) {
.login-logo {
    width: 100%;
}
.login-form-box {
    width: 100%;
}
#register-page .login-logo {
    width: 100%;
}
#register-page .login-form-box {
    width: 100%;
}
}
@media screen and (max-width: 574px) {
.forgot-password a {
    float: none;
    display: block;
    text-align: center;
    margin-top: 10px;
}
}
/*4-4-2023 Preeti */
.custom-check input:checked ~ .checkmark {
    border: 2px solid #282041;
}


.panel_sports .d-grid{
    justify-content: center;
    justify-items: start;
}

.Password-hide{
    cursor: pointer;
    position: absolute;
    width: 20px;
    right: 14px;
    top: 12px;
    color: #757982;
}

.form-group{
    position: relative;
}

.signin-btn svg path {
    fill: #fff !important;
    color: #fff !important;
}

.otp_form div {
    display: block !important;
}
.mb-4.otp_form {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.otp_form div {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;
    gap: 12px;
}

.payment-btn svg path {
    fill: #fff !important;
    color: #fff !important;
}

.get_email svg path {
    fill: #fff !important;
    color: #fff !important;
}
.enrol-team-btn{
    width: 200px !important;
}

.enrol-team-btn svg path {
    fill: #fff !important;
    color: #fff !important;
}

.get_email{
    width: 150px;
}
.btn.btn-primary:active{
    background-color: #f1582a;
    border-color: #f1582a;
}

/* preeti  */

.mb-4.otp_form.send-otp {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.otp_form.send-otp label.form-label {
    margin-bottom: 18px;
    color: #757575;
    font-weight: 600;
    font-size: 22px;
}

.otp_form.send-otp div {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    gap: 12px;
}

.mb-4.otp_form.send-otp input.otp-input {
    max-height: 46px;
}

.form-group.passord-form-group input#password {
    height: 48px;
    border-radius: 8px !important;
    max-height: 48px;
}

.form-group.email-form-group input#email {
    height: 48px;
    border-radius: 8px !important;
    max-height: 48px;
}

.form-group.passord-form-group input#contact {
    height: 48px;
    border-radius: 8px !important;
    max-height: 48px;
}