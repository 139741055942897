.dropbtn {
  border: none;
  width: 100%;
  min-width: 156px;
  max-width: 152px;
  /* background: #27AE60 !important; */
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
  color: #000;
  background: transparent;
  border: 1px solid #ccc;

  }
  
  .dropbtn:hover, .dropbtn:focus {
    background-color: transparent;
  }
  
  #myInput {
    box-sizing: border-box;
    /* background-image: url('searchicon.png'); */
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 16px;
    /* padding: 14px 20px 12px 45px; */
    border: none;
    border-bottom: 1px solid #ddd;
  }
  
  #myInput:focus {
    outline: 3px solid #ddd;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 252px;
    overflow: auto;
    border: 1px solid #ddd;
    z-index: 1;
    padding: 19px 11px;
    margin-top: 7px;
    left: -26px;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* .dropdown a:hover {
    background-color: #ddd;
  } */
  
  .show {
    display: block;
  }
  .game-check{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul.list-menu .dropdown ul.dropdown-menu.Sports-dropdown-menu {
    width: 220px;
}
  
  